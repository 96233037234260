.blogimgtpluselement {
  position: absolute;
  left: 40%;
  top: 50%;
  z-index: 10;
}
.bnbpluselement {
  position: absolute;
  left: 35.8%;
  top: 27%;
  z-index: 1;
}
.bnpluselement {
  position: absolute;
  left: 4%;
  top: 210%;
  z-index: 1;
}
.bnarelement {
  position: absolute;
  left: 13%;
  bottom: 33%;
  z-index: 2;
}
.bgbnarelement {
  position: absolute;
  left: 4%;
  bottom: 77.3%;
  z-index: 2;
}
.blogimgarelement {
  position: absolute;
  left: 70%;
  bottom: 20%;
  z-index: 2;
}
.newsimgarelement {
  position: absolute;
  left: 58%;
  bottom: 16%;
  z-index: 2;
}
.bntext {
  font-size: 1.2rem;
  color: #c4c4c4;
  line-height: 2.3rem;
  margin-top: 35px;
  letter-spacing: 0.1rem;
  margin-left: 3rem;
  margin-top: 11rem;
  width: 81%;
}
.bntext h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #c9c9c9;
  padding: 0px;
  padding-bottom: 0rem;
  margin-left: 2rem;
}
.bntext h3 {
  font-size: 4rem;
  font-weight: 600;
  color: #fff;
  margin-left: 2rem;
  padding: 0px;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px #c4c4c4;
  letter-spacing: 0.1rem;
  width: 93%;
}
.bntext p {
  font-size: 1.1rem;
  color: #fff;
  line-height: 2.3rem;
  margin-top: 20px;
  letter-spacing: 0.1rem;
  margin-left: 2rem;
}
.bnbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: 100%;
  width: 100%;
}
.bnbiglogo {
  position: absolute;
  left: -1%;
  top: 9.3%;
  z-index: 2;
}
.smallxbn {
  position: absolute;
  margin-left: 27%;
  margin-top: 3rem;
  z-index: 2;
  opacity: 1;
}
.playicon {
  left: -23rem;
  top: 3rem;
  position: relative;
}
.bncontentpart {
  font-size: 1.2rem;
  color: #fff;
  line-height: 2.3rem;
  z-index: 5;
}
.bncontentpart h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  padding-left: 20px;
  padding-top: 10px;
  letter-spacing: 0.1rem;
  position: relative;
}
.bncontentpart h5 {
  position: absolute;
  left: 35%;
  top: 20px;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bncontentpart h6 {
  position: absolute;
  left: 35%;
  top: 100px;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.blogbigimg {
  background: url(../img/blogbigimg.png);
  height: 1050px;
  margin-top: 14rem;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blogbigimg p {
  font-size: 1rem;
  opacity: 1;
  color: #fff;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  z-index: 15;
  text-align: justify;
  padding: 30px 80px 30px 30px;
}
.blogbigimg h4 {
  opacity: 1;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  color: #fff;
  z-index: 15;
}

.blogpart {
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 1rem;
  opacity: 1;
  color: #fff;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  z-index: 15;
}
.blogimgrbbtn {
  position: absolute;
  top: 80%;
  left: 68%;
  z-index: 5;
}
.bl1 .blogpartpluselement {
  position: absolute;
  margin-top: 0rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  z-index: 1;
}
.bl1 .blogelementsmallx {
  position: absolute;
  left: 78%;
  top: 0%;
  z-index: 2;
  opacity: 1;
}
.bl1 h5 {
  position: absolute;
  left: 15.5%;
  top: 6%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl1 h6 {
  position: absolute;
  left: 15.5%;
  top: 14%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl2 .blogpartpluselement {
  position: relative;
  margin-top: -2rem;
  margin-left: 0rem;
  z-index: 1;
}
.bl2 .blogelementsmallx {
  position: absolute;
  left: 93%;
  top: -11%;
  z-index: 2;
  opacity: 1;
}
.bl2 h5 {
  position: absolute;
  left: 18.5%;
  top: 8%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl2 h6 {
  position: absolute;
  left: 18.5%;
  top: 43%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bolgimgtitle {
  font-size: 1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding-left: 1rem;
  margin-top: -15px;
}
.article-item {
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.article-item-photo {
  margin-bottom: 2rem;
  margin-top: 11rem;
  margin-left: 0rem;
  height: 0;
  padding-bottom: 62.16216216%;
  position: relative;
  overflow: hidden;
}
.article-item-photo img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .news-item {
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
} */
/* .news-item-photo {
  margin-top: 4rem;
  margin-left: 0rem;
  height: 0;
  padding-bottom: 80%;
  position: relative;
  overflow: hidden;
} */
/* .news-item-photo img {
  position: absolute;
  left: 80px;
  top: 0px;
  bottom: 0;
  right: 0;
  height: 100%;
} */
/* .newsimgrbbtn {
  position: absolute;
  top: 85%;
  left: 57%;
  z-index: 5;
} */
/* .newsbg {
  background: #17355d;
  height: 430vh;
  width: 100%;
  border-radius: 0px 250px 0px 0px;
  margin-top: 100px;
}
.newsbiglogo {
  position: absolute;
  right: 5rem;
  top: 3rem;
} */
/* .newscontentpart {
  font-size: 1.2rem;
  color: #fff;
  line-height: 2.3rem;
  position: absolute;
  z-index: 5;
}
.newscontentpart h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  left: -11.5rem;
  top: 7.4rem;
  letter-spacing: 0.1rem;
  position: relative;
}
.newscontentpart h5 {
  position: absolute;
  left: 10.5%;
  top: 235%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.newscontentpart h6 {
  position: absolute;
  left: 10.5%;
  top: 277%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.newspart {
  margin-top: -20rem;
} */
.smallxnews {
  position: absolute;
  margin-left: 30rem;
  margin-top: 1rem;
  z-index: 2;
  opacity: 1;
}
/* .playiconnews {
  left: -20.5rem;
  top: 3rem;
  position: relative;
} */
/* .newsdotsbgleft {
  background: url(../img/newsdotsbg.png);
  background-repeat: no-repeat;
  background-position: left;
}
.newsdotsbgright {
  background: url(../img/newsdotsbg.png);
  background-repeat: no-repeat;
  background-position: right;
}
.newssectionpart {
  margin-top: 20rem;
  color: #fff;
}
.bl1 .newsspartpluselement {
  margin-top: 0rem;
  margin-left: -6rem;
  margin-bottom: 1rem;
  z-index: 1;
} */
.bl1 h5 {
  position: absolute;
  left: 10.5%;
  top: 6%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl1 h6 {
  position: absolute;
  left: 10.5%;
  top: 13%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
/* .newstext p {
  width: 90%;
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-top: 12rem;
  margin-bottom: 2rem;
}
.newstextbg {
  width: 100%;
  background: #3fa6f2;
  height: 11vh;
}
.newstextbg p {
  color: #fff;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 5px 10px 0px 30px;
  line-height: 2rem;
}
.newstextbg .icon {
  font-size: 15px;
  margin-right: 5px;
}
.nw1 .newsspartpluselement {
  position: absolute;
  margin-top: 0rem;
  margin-left: -6rem;
  margin-bottom: 1rem;
  z-index: 1;
} */
/* .nw1 h5 {
  position: absolute;
  left: 0.5%;
  top: 4%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.nw1 h6 {
  position: absolute;
  left: 0.5%;
  top: 12%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
} */
/* .mrsection .newstext p {
  width: 80%;
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
} */
/* .nw2 .newsspartpluselement {
  margin-top: 0rem;
  margin-left: 30rem;
  margin-bottom: 1rem;
  z-index: 1;
} */
/* .nw2 h5 {
  position: absolute;
  right: 23.5%;
  top: 4%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
} */
/* .nw2 h6 {
  position: absolute;
  right: 23.5%;
  top: 12%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
} */
/* .newstextrightbg {
  width: 90%;
  background: #3fa6f2;
  height: 11vh;
} */
/* .newstextrightbg .icon {
  font-size: 15px;
  margin-right: 5px;
}
.newstextrightbg p {
  color: #fff;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 5px 70px 0px 0px;
  line-height: 2rem;
  text-align: right;
} */
.mt40 {
  margin-top: 4rem;
}
.mb30 {
  margin-bottom: 1.875rem;
}
.mb50 {
  margin-bottom: 5rem;
}
.ml15 {
  margin-left: -15px;
}
.zooming {
  overflow: hidden;
}
.zooming img {
  transform: scale(1);
  transition: all 1s linear;
}
.zooming:hover img,
a:hover .zooming img {
  transform: scale(1.08);
}
.zoomingnews {
  overflow: hidden;
}
.zoomingnews:hover img,
a:hover .zoomingnews img {
  transform: scale(1.04);
}
.zoomingnews img {
  transform: scale(1);
  transition: all 1s linear;
}

.section3 .mt-25 {
  margin-top: 200px;
}
.section3 .blogwithplayicon {
  /* position: absolute;
  bottom: 27%;
  left: 13%; */
  position: relative;
  bottom: 23%;
  left: 0%;
  top: -7rem;
}
.section3 .blogposition {
  left: -100%;
  top: 150px;
}

/* for mobile small  */
@media (min-width: 320px) and (max-width: 374px) {
  /* .visithailand .abouttext {
    font-size: 18px;
  } */
  .section3 .bl1 h6 {
    top: 12%;
  }
  .section3 .bl1 h5 {
    top: 4%;
  }
  /* .section3 .news-item-photo img {
    left: 37px;
    top: 4px;
  } */
  .section3 .blogimgtpluselement {
    left: 24% !important;
    top: 41% !important;
  }
  /* .section3 .nw1 h5 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section3 .nw1 h6 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section3 .nw2 h5 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section3 .nw2 h6 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  } */
  .section3 .bnbpluselement {
    visibility: hidden !important;
  }
  .section3 .bntext h3 {
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
  .section3 .bntext {
    font-size: 1.1rem;
    margin-left: 1rem;
    margin-top: 7rem;
    width: 81%;
  }
  .section3 .bnbg {
    height: 171rem;
    width: 100%;
  }
  /* .section3 .newsimgrbbtn {
    position: absolute;
    top: 81%;
    left: 36%;
  }
  .section3 .newsimgarelement {
    position: absolute;
    left: 43%;
    bottom: 19%;
  }
  .section3 .newstext p {
    width: 100% !important;
    text-transform: none;
    padding-inline: 4%;
  }
  .section3 .nw1 .newsspartpluselement {
    left: 81% !important;
  }
  .section3 .nw2 .newsspartpluselement {
    margin-top: 0rem;
    margin-left: 15rem;
  } */
  .section3 .mdmob {
    margin-bottom: -50px !important;
    margin-top: 71px !important;
  }
  .section3 .bntext p {
    font-size: 1.1rem;
    color: #fff;
    line-height: 1.5rem;
    margin-top: 12px;
    letter-spacing: 1px;
    margin-left: 2rem;
  }
  .section3 .article-item-photo img {
    left: 0px;
  }
  .section3 .blogimgarelement {
    bottom: 36% !important;
    left: 56% !important;
  }
  .section3 .blogimgrbbtn {
    top: 76% !important;
    left: 50% !important;
  }
  .section3 .bl1 h5 {
    left: 34.5% !important;
  }
  .section3 .bl1 h6 {
    left: 34.5% !important;
  }
  .section3 .blogimgtpluselement {
    left: 16% !important;
  }
  .section3 .bolgimgtitle {
    padding-inline: 1.1rem;
  }
  .section3 .explorelap {
    position: relative;
    margin-top: 17%;
    text-align: center;
  }
}
/* for mobile medium */
@media (min-width: 375px) and (max-width: 425px) {
  .section3 .bnbpluselement {
    visibility: hidden !important;
  }
  /* .section3 .nw1 .newsspartpluselement {
    left: 75% !important;
    margin-top: -1rem;
  }
  .section3 .nw2 h6 {
    right: 4.5%;
    top: 12%;
  }
  .section3 .nw2 h5 {
    position: absolute;
    right: 4.5%;
  }
  .section3 .nw2 .newsspartpluselement {
    margin-top: -1rem;
    margin-left: 15rem;
  }
  .section3 .news-item {
    padding-left: 19px !important;
    padding-right: 19px !important;
  } */
  .section3 .bnbg {
    height: 179rem;
  }
  .section3 .smallxnews {
    margin-left: 35% !important;
  }
  /* .section3 .newscontentpart h3 {
    left: 103% !important;
  }
  .section3 .news-item-photo img {
    left: 41px;
    top: 0px;
  } */
  .section3 .mdmob {
    margin-bottom: -50px !important;
    margin-top: 71px !important;
  }
  .section3 .blogimgrbbtn {
    top: 81% !important;
    left: 56% !important;
  }
  .section3 .bl1 h5 {
    left: 31.5% !important;
    top: 2%;
  }
  .section3 .bl1 h6 {
    left: 31.5% !important;
  }
  .section3 .bolgimgtitle {
    padding-inline: 4%;
  }
}
/* for mobile large  */
@media (max-width: 426px) {
  .section3 .techserviceelement1bot {
    display: none !important;
  }
  .section3 .techpulusbot {
    display: none !important;
  }
  .section3 .bnarelement {
    display: none !important;
  }
  .section3 .blogbigimg {
    height: 300px !important;
    /* background: none !important; */
  }
  .section3 .smallxbn {
    margin-left: 4% !important;
    margin-top: 2rem !important;
  }
  .section3 .bnbiglogo img {
    display: none !important;
  }
  .section3 .mt-25 {
    margin-top: 89px !important;
  }
  .section3 .blogbigimg p {
    margin-top: 16px;
    padding: 27px !important;
    font-size: 9px;
    line-height: 1.6rem;
    letter-spacing: 0rem;
  }
  .section3 .mobmt150 {
    /* margin-top: 150px !important; */
  }
  .section3 .bl1 h5 {
    left: 26.5%;
    top: 2%;
  }
  .section3 .bl1 h6 {
    left: 26.5%;
    top: 18% !important;
  }
  .section3 .bl1 .blogelementsmallx {
    display: none !important;
  }
  .section3 .article-item-photo {
    margin-bottom: 0px !important;
    margin-top: 13rem !important;
    margin-left: 12px !important;
  }
  .section3 .bgbnarelement {
    display: none !important;
  }
  /* .section3 .newssectionpart {
    margin-top: 25rem !important;
  } */
  .section3 .blogimgrbbtn {
    top: 81%;
    left: 61%;
  }
  .section3 .blogimgtpluselement {
    left: 28%;
    top: 41% !important;
  }
  .section3 .mobnone {
    display: none !important;
  }
  .section3 .newsbg {
    border-radius: 0px 180px 0px 0px !important;
    margin-top: 100px !important;
  }
  .section3 .bolgimgtitle {
    padding-inline: 1.2rem;
    margin-top: 0px !important;
  }
  .section3 .smallxnews {
    margin-left: 10%;
  }
  .section3 .newsbiglogo {
    display: none !important;
  }
  .section3 .newscontentpart h3 {
    left: 100% !important;
  }
  .section3 .news-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .section3 .mob-pad-20 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .section3 .nw1 .newsspartpluselement {
    left: 75% !important;
  }
  .section3 .nw2 .newsspartpluselement {
    /* margin-left: 20rem !important; */
    visibility: hidden;
  }
  /* .section3 .newstext p {
    width: 100% !important;
    text-transform: none;
    padding-inline: 4%;
  }
  .section3 .mrsection .newstext p {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .section3 .newstextrightbg {
    width: 100% !important;
  }
  .section3 .newsbg {
    height: 600vh !important;
  } */
  .section3 .bntext h3 {
    font-size: 28px;
    margin-bottom: 28px;
  }
  .section3 .bnbpluselement {
    left: 63% !important;
    top: 14% !important;
  }
  .section3 .smlogo {
    visibility: hidden;
  }
  .section3 .blogbigimg h4 {
    opacity: 1;
    font-size: 10px;
    letter-spacing: 0.1rem;
    font-weight: 600;
    color: #fff;
    z-index: 15;
  }
  .section3 .bl1 .blogpartpluselement {
    position: absolute;
    margin-top: -12px;
    margin-left: 1rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .section3 .blogimgarelement {
    position: absolute;
    left: 63%;
    bottom: 31%;
    z-index: 2;
  }
  .section3 .newsimgrbbtn {
    position: absolute;
    top: 86%;
    left: 43%;
    z-index: 5;
  }
  .section3 .newsimgarelement {
    position: absolute;
    left: 48%;
    bottom: 15%;
    z-index: 2;
  }
  /* .blogandnews.contentpartpartner h3 {
    position: relative;
    margin-top: 141px;
    font-size: 2.5rem;
    text-align: right;
    letter-spacing: 0.035em;
    font-weight: 600;
    font-family: "Saira";
    color: #fff;
    z-index: 2;
  } */
  .section3 .mdmob {
    margin-bottom: -50px !important;
    margin-top: 71px !important;
  }
  .section3 .nw1 h5 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section3 .nw1 h6 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section3 .nw2 h5 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section3 .nw2 h6 {
    left: 3.5%;
    width: 100%;
    padding-inline: 4%;
  }
}
/* @media (min-width: 427px) and (max-width: 769px) { */
@media (min-width: 427px) and (max-width: 659px) {
  .section3 .bnbpluselement {
    left: 54.5% !important;
    top: 14% !important;
  }
  .section3 .bnbiglogo {
    left: -1% !important;
    top: 12% !important;
  }
  .section3 .blogbigimg {
    height: 700px !important;
  }
  .section3 .blogposition {
    left: -67% !important;
    top: 77px !important;
  }
  .section3 .tabnone {
    display: none !important;
  }
  .section3 .smallxnews {
    margin-left: 11rem !important;
  }
  .section3 .newsbg {
    height: 285vh !important;
  }
  .section3 .tab-pad-20 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .section3 .nw2 .newsspartpluselement {
    margin-top: -1rem;
    margin-left: 40rem;
  }
  .section3 .blogimgtpluselement {
    top: 40%;
    left: 27%;
  }
  .section3 .bl1 .blogpartpluselement {
    margin-top: -3rem;
    margin-left: 3rem;
  }
  .section3 .bl1 h5 {
    top: -3%;
    left: 33.5%;
  }
  .section3 .bl1 h6 {
    top: 15%;
    left: 33.5%;
  }
  .section3 .blogimgarelement {
    position: absolute;
    left: 70%;
    bottom: 30%;
    z-index: 2;
  }
  .section3 .screen427 {
    width: 100%;
    padding-right: 11px;
    padding-left: 11px;
    margin-right: auto;
    margin-left: auto;
  }
  .section3 .blogimgrbbtn {
    position: absolute;
    top: 82%;
    left: 63%;
    z-index: 5;
  }
  .section3 .bl1 .blogelementsmallx img {
    visibility: hidden !important;
  }
}
@media (min-width: 660px) and (max-width: 767px) {
  .section3 .bnbpluselement {
    left: 54.5% !important;
    top: 14% !important;
  }
  .section3 .bnbiglogo {
    left: -1% !important;
    top: 12% !important;
  }
  .section3 .blogbigimg {
    height: 700px !important;
  }
  .section3 .blogposition {
    left: -67% !important;
    top: 77px !important;
  }
  .section3 .tabnone {
    display: none !important;
  }
  .section3 .smallxnews {
    margin-left: 11rem !important;
  }
  .section3 .newsbg {
    height: 285vh !important;
  }
  .section3 .tab-pad-20 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .section3 .nw2 .newsspartpluselement {
    margin-top: -1rem;
    margin-left: 40rem;
  }
  .section3 .blogimgtpluselement {
    top: 40%;
    left: 27%;
  }
  .section3 .bl1 .blogpartpluselement {
    margin-top: -3rem;
    margin-left: 3rem;
  }
  .section3 .bl1 h5 {
    top: -4%;
    left: 24.5%;
  }
  .section3 .bl1 h6 {
    top: 12%;
    left: 24.5%;
  }
  .section3 .blogimgarelement {
    position: absolute;
    left: 74%;
    bottom: 23%;
    z-index: 2;
  }
  .section3 .screen427 {
    width: 100%;
    padding-right: 11px;
    padding-left: 11px;
    margin-right: auto;
    margin-left: auto;
  }
  .section3 .blogimgrbbtn {
    position: absolute;
    top: 79%;
    left: 71%;
    z-index: 5;
  }
  .section3 .bl1 .blogelementsmallx img {
    visibility: hidden !important;
  }
  .section3 .bolgimgtitle {
    padding-inline: 1%;
    padding-left: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .section3 .article-item-photo img {
    padding-inline: 2%;
  }
  .section3 .bl1 h6 {
    left: 15.5%;
    top: 11%;
  }
  .section3 .bl1 h5 {
    left: 15.5%;
    top: -1%;
  }
  .section3 .bl1 .blogpartpluselement {
    margin-top: -2rem;
    margin-left: 2rem;
  }
  .section3 .bl1 .blogelementsmallx {
    left: 78%;
    top: -4%;
  }
  .section3 .bolgimgtitle {
    padding-inline: 2%;
  }
  .section3 .blogimgrbbtn {
    position: absolute;
    top: 89%;
    left: 77%;
    z-index: 5;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .section3 .bntext h3 {
    font-size: 3rem;
  }
  .section3 .bnbpluselement {
    position: absolute;
    left: 59.8%;
    top: 3%;
  }
  .section3 .bntext {
    margin-left: 6rem;
    margin-top: 20rem;
  }
  .section3 .blogbigimg h4 {
    font-size: 22px;
    padding-right: 37px;
  }
  .section3 .bnarelement {
    left: 14%;
    bottom: 1%;
  }
  .section3 .smlogo {
    left: 1%;
    top: 27%;
  }
  .section3 .bl1 h5 {
    left: 17.5%;
    top: 5%;
  }
  .section3 .bl1 h6 {
    left: 17.5%;
    top: 17%;
  }
  .section3 .blogimgrbbtn {
    top: 81%;
    left: 65%;
    z-index: 5;
  }
  .section3 .smallxnews {
    margin-left: 20rem;
  }
  .section3 .container-fluid {
    padding-left: 0px;
  }
  .section3 .techserviceelement1bot {
    position: absolute;
    right: 13%;
    bottom: 4%;
    z-index: 2;
    opacity: 1;
  }
  .section3 .bgbnarelement {
    position: absolute;
    left: 4%;
    bottom: 79%;
  }
  .section3 .blogimgarelement {
    position: absolute;
    left: 70%;
    bottom: 31%;
  }
  .section3 .bl1 .blogelementsmallx {
    position: absolute;
    left: 76%;
    top: 0%;
    z-index: 2;
    opacity: 1;
  }
  .section3 .bolgimgtitle {
    padding-left: 0rem;
  }
}

@media (min-width: 1440px) {
  .section3 .bnbpluselement {
    position: absolute;
    left: 56.8%;
    top: 4%;
    z-index: 1;
  }
  .section3 .smlogo {
    position: absolute;
    left: 62%;
    top: 22%;
  }
  .section3 .bntext {
    margin-left: 3rem;
    margin-top: 16rem;
  }
  .section3 .bnbiglogo {
    position: absolute;
    left: 2%;
    top: 10.3%;
  }
  .section3 .bl1 h5 {
    position: absolute;
    left: 15.5%;
    top: 4%;
  }
  .section3 .bl1 h6 {
    position: absolute;
    left: 15.5%;
    top: 14%;
  }
  .section3 .nw2 .newsspartpluselement {
    margin-top: 0rem;
    margin-left: 32rem;
  }
  .section3 .container-fluid {
    width: 100%;
    padding-right: 0px;
  }
  .section3 .news-item-photo {
    margin-top: 8rem;
    margin-left: -4rem;
  }
  .section3 .newsimgrbbtn {
    position: absolute;
    left: 53%;
  }
  .section3 .newsimgarelement {
    left: 52%;
  }
  .section3 .blogimgtpluselement {
    left: 31%;
    top: 58%;
  }
  .section3 .blogbigimg h4 {
    font-size: 22px;
  }
  .section3 .bnarelement {
    left: 15%;
    bottom: 22%;
  }
  .section3 .explorelap {
    text-align: center !important;
    top: 40%;
    left: 44%;
  }
  .section3 .bgbnarelement {
    position: absolute;
    left: 4%;
    bottom: 79%;
  }
}
