/* .termsandcondition .wwabpluselement {
  position: absolute;
  left: 15% !important;
  top: 39% !important;
  z-index: 1;
} */
.termsandcondition .techbannertext {
  left: 18%;
  top: 43%;
}

.termsandcondition .visthailandbannerfont {
  font-size: 3rem !important;
  text-align: initial;
  line-height: 3rem !important;
}
.termsandcondition .visthailandnormalfont {
  letter-spacing: 0.5rem;
  font-size: 2.5rem !important;
  text-align: initial;
  line-height: 0rem !important;
}
.termsandcondition .termbanner {
  background: linear-gradient(rgba(23, 48, 108, 0.5), rgba(14, 28, 85, 0.753)),
    url("../img/termcond.jpg") top;
  background-size: cover;
}
.termsandcondition .bgsquarex {
  background-image: url("../img/elementsmallx.png");
  background-size: contain;
  object-fit: contain;
}
.termsandcondition .vtpackbtn {
  position: absolute;
  top: 88%;
  left: 15%;
}
.termsandcondition .wwabpluselement {
  position: absolute;
  left: 7% !important;
  top: 41% !important;
  z-index: 1;
}

.termsandcondition .wwabpluselement img {
  height: 210px;
  width: 210px;
}
.termsandcondition .bgthailandblue {
  background-color: #3891da;
}

.termsandcondition .social-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}
.termsandcondition .footersocial-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}

.termsandcondition .serviceelementjwwab {
  position: absolute;
  left: 20%;
  bottom: 12%;
  z-index: 2;
  width: 50%;
  top: 78%;
}

.termsandcondition .linearthailandbgm {
  background: rgb(1, 112, 184);
  background: linear-gradient(
    90deg,
    rgba(1, 112, 184, 1) 0%,
    rgba(59, 92, 155, 1) 50%,
    rgba(94, 90, 147, 1) 100%
  );
}

.termsandcondition .op8 {
  background-color: #caa323;
  opacity: 0.38;
  width: 350px;
  height: 200px;
}
.termsandcondition .editcardiv {
  top: 35%;
  left: 20%;
}
.termsandcondition .editcardiv {
  letter-spacing: 2rem;
  height: fit-content;
}
.termsandcondition .editcardiv .loose {
  display: flex;
  letter-spacing: 0.1em;
  font-size: 15px;
  color: white;
  top: 3px;
  overflow: hidden;
}
.termsandcondition .editcarplus {
  top: -18%;
  left: -32%;
  height: 100px !important;
  width: 100px !important;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}
.termsandcondition .MesgPicthailand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.termsandcondition .MesgPicthailand img {
  height: 90%;
  width: 90%;
}
.termsandcondition .editplusnew {
  left: -1%;
  top: 10%;
}
.termsandcondition .editplusnewright {
  right: -1%;
  top: 10%;
}
.termsandcondition .h-150 {
  height: 150px;
}
/* ////////TERMS & CONDITION ////////// */

.termsandcondition .termeffectiveDate {
  font-size: 2.2rem;
  position: relative;
  left: 0.5rem;
}
.termsandcondition .termhead {
  position: relative;
  /* margin-top: 3rem; */
  top: 30px;
  font-size: 1.1rem;
  word-spacing: 6px;
  /* background-color: #caa323; */
}
.termsandcondition .termpara {
  position: relative;
  top: 30px;
  line-height: 2rem;
  font-size: 1.1rem;
  word-spacing: 3px;
  letter-spacing: 1.5px;
}
.termsandcondition .termpart {
  /* font-size: 2rem; */
  letter-spacing: 3.1px;
  font-style: bold;
}
.termsandcondition .termupdate {
  margin-top: 20px;
  font-size: 1.5rem;
}
.termsandcondition .wwasmlogo {
  position: absolute;
  left: 0%;
  top: 24%;
}
.termsandcondition .head2 {
  font-size: 15.5px;
}
.termsandcondition .abouttext {
  font-size: 15.5px;
}
@media (max-width: 426px) {
  .termeffectiveDate {
    background-color: green;
  }
  .termsandcondition .techelementsmallx {
    display: none;
  }
  .termsandcondition .techbannertext {
    width: 75%;
  }

  .termsandcondition .techbannertext {
    top: 50%;
  }
  .termsandcondition .wwabpluselement img {
    height: 150px;
    width: 150px;
  }
  .termsandcondition .wwasmlogo {
    display: none;
  }
  .termsandcondition .techpulusbot {
    display: none;
  }
  .termsandcondition .serviceelementjwwab {
    top: 83%;
  }
  .termsandcondition .wwabpluselement img {
    visibility: hidden;
  }
  .termsandcondition .btn {
    padding: -0.25rem 2rem 0.75rem;
  }

  .termsandcondition .vtpackbtn {
    top: 87%;
    left: 3%;
  }
  .termsandcondition .padmob {
    /* padding: 20px; */
  }
  .termsandcondition .mobnone {
    display: none;
  }
  .termsandcondition .mobcenter {
    text-align: center;
    padding-bottom: 10px;
  }
  .termsandcondition .mobborderbottom {
    border-bottom: 1px solid white;
  }
  .termsandcondition .editplusnew {
    left: -3%;
    top: 2%;
  }
  .termsandcondition .editplusnewright {
    right: -3%;
    top: 2%;
  }
  .termsandcondition .mobpad {
    padding-bottom: 15px;
  }
  .termsandcondition .blogelementsmallx {
    display: none;
  }
  .termsandcondition.bl1 .blogpartpluselement {
    margin-top: -18px;
    margin-left: -1rem;
  }
  .termsandcondition .blogimgrbbtn {
    left: 52%;
  }
  .termsandcondition .blogimgarelement {
    left: 57%;
  }
  .termsandcondition .blogimgtpluselement {
    left: 30%;
    top: 37%;
  }
  .termsandcondition .blogimgrbbtn .editbottom a {
    bottom: 13px;
    position: relative;
  }
  .termsandcondition .blogimgarelement {
    bottom: 24%;
  }
  .article-item-photo {
    margin-right: 1rem;
  }
  .termsandcondition .editcarplus {
    top: -28%;
  }
  .termsandcondition .techbannertext {
    left: 15%;
  }
  .termsandcondition .bl1 h5 {
    position: absolute;
    left: 26.5%;
    top: 1%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 26.5%;
    top: 19%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition .share {
    font-size: 14px;
  }
  .termsandcondition .termsandcondition.op8 {
    height: 30vh;
  }
  .termsandcondition .visthailandnormalfont {
    letter-spacing: 0.5rem;
    font-size: 1.6rem !important;
    text-align: initial;
    line-height: 0rem !important;
  }
  .termsandcondition .termupdate {
    margin-left: -29%;
    font-size: 1.5rem;
  }
  .termsandcondition .termpart {
    font-size: 1.5rem;
    letter-spacing: 0px;
    font-style: bold;
  }
  .termsandcondition .head2 {
    font-size: 12.5px;
  }
  .termsandcondition .abouttext {
    font-size: 12.5px;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .termsandcondition .visthailandnormalfont {
    letter-spacing: 0.1rem;
    font-size: 1.5rem !important;
    text-align: initial;
  }
  .termsandcondition .head2 {
    font-size: 12.5px;
  }
  .termsandcondition .abouttext {
    font-size: 12.5px;
  }
  .termsandcondition .termupdate {
    margin-left: -2%;
    font-size: 1.5rem;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .termsandcondition .visthailandnormalfont {
    letter-spacing: 0.1rem;
    font-size: 1.5rem !important;
    text-align: initial;
  }
  .termsandcondition .head2 {
    font-size: 12px;
  }
  .termsandcondition .abouttext {
    font-size: 12px;
  }
  .termsandcondition .termupdate {
    margin-left: -18%;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .termsandcondition.serviceelementjwwab {
    position: absolute;
    left: 20%;
    bottom: 12%;
    z-index: 2;
    width: 50%;
    top: 83%;
  }
  .termsandcondition.vtpackbtn {
    position: absolute;
    top: 88%;
    left: 14%;
  }
  .termsandcondition.wwasmlogo {
    visibility: hidden;
  }
  .termsandcondition.bl1 h5 {
    position: absolute;
    left: 13.5%;
    top: 1%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition.bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 13.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
  }
  .termsandcondition.bl1 .blogpartpluselement {
    position: absolute;
    margin-top: -1rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .termsandcondition.blogimgrbbtn {
    position: absolute;
    top: 82%;
    left: 68%;
    z-index: 5;
  }
  .termsandcondition.share {
    font-size: 14px;
  }
  .termsandcondition.termsandcondition.op8 {
    height: 30vh;
  }
}
@media screen and (min-width: 1024px) {
  .termsandcondition.wwabpluselement {
    position: absolute;
    left: 6% !important;
    top: 47% !important;
    z-index: 1;
  }
  .termsandcondition .editplusnew {
    left: -1%;
    top: 12%;
  }
  .termsandcondition .editplusnewright {
    right: -1%;
    top: 11%;
  }
  .termsandcondition .bl1 h5 {
    position: absolute;
    left: 19.5%;
    top: 3%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 19.5%;
    top: 14%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition .blogimgrbbtn {
    position: absolute;
    top: 79%;
    left: 67%;
    z-index: 5;
  }
  .termsandcondition .blogimgarelement {
    position: absolute;
    left: 70%;
    bottom: 22%;
    z-index: 2;
  }
  .termsandcondition .share {
    font-size: 14px;
  }
  .termsandcondition .termsandcondition .op8 {
    height: 30vh;
  }
  .termsandcondition .editcarplus {
    top: -18%;
    left: -22%;
  }
  .termsandcondition .abouttext {
    line-height: 25px;
    font-size: 16px;
    word-spacing: 4px;
  }
  .termsandcondition .MesgPicthailand img {
    height: 119%;
    width: 90%;
  }
  .termsandcondition .roundedtop {
    border-top-left-radius: 121px;
    border-top-right-radius: 121px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
  .termsandcondition .author {
    font-size: 14px;
  }
  .termsandcondition .manasi {
    font-size: 14px;
  }
  .termsandcondition .roundedtop {
    height: 300px !important;
    width: 242px !important;
    object-fit: fill;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
    /* padding-left: 14px; */
  }
  .termsandcondition .phiphi1 {
    padding-left: 14px;
  }
  .termsandcondition .phiphi2 {
    margin-left: 14px;
  }
  .termsandcondition .phiphi3 {
    padding-right: 14px;
  }
  .termsandcondition .phiphi4 {
    padding-right: 14px;
  }
}
@media screen and (min-width: 1440px) {
  .termsandcondition .editplusnew {
    left: -1%;
    top: 20%;
  }
  .termsandcondition .editplusnewright {
    right: -1%;
    top: 13%;
  }
  .termsandcondition .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 16.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition .bl1 h5 {
    position: absolute;
    left: 16.5%;
    top: 0%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .termsandcondition .share {
    font-size: 14px;
  }
  .termsandcondition .termsandcondition .op8 {
    height: 30vh;
  }
  .termsandcondition .editcarplus {
    top: -18%;
    left: -22%;
  }
  .termsandcondition .abouttext {
    line-height: 25px;
    font-size: 16px;
    word-spacing: 4px;
  }
  /* .termsandcondition.MesgPicthailand img {
    height: 133%;
    width: 90%;
  } */
  .termsandcondition .roundedtop {
    height: 357px !important;
    width: 319px !important;
    object-fit: fill;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
}
@media (min-width: 427px) and (max-width: 821px) {
  .termsandcondition .wwabpluselement {
    left: 2% !important;
    top: 48% !important;
  }
  .termsandcondition .tabnone {
    display: none;
  }
  .termsandcondition .editplusnew {
    top: 6%;
  }
  .termsandcondition .editplusnewright {
    top: 6%;
  }
  .termsandcondition .padmob {
    padding: 20px;
  }
  .termsandcondition .termsandcondition .op8 {
    height: 30vh;
  }
  .termsandcondition .wwasmlogo {
    visibility: hidden;
  }
  .termsandcondition .visthailandnormalfont {
    letter-spacing: 0.5rem;
    font-size: 2.5rem !important;
    text-align: initial;
    line-height: 8rem !important;
  }
}
