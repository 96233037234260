.whowearebannerfont {
  /* font-size: 4rem; */
  font-size: 20px;
}

.wwabotline {
  position: absolute;
  left: 10%;
  top: 60%;
}

.wwabotline img {
  width: 100%;
}

.wwabpluselement {
  position: absolute;
  left: 40%;
  top: 30%;
  z-index: 1;
}

.wwasmlogo {
  position: absolute;
  left: 0%;
  top: 30%;
}

.serviceelementjwwab {
  position: absolute;
  left: 10%;
  bottom: 24%;
  z-index: 2;
}

.historyimgpartwwa {
  position: absolute;
  right: 0%;
  top: 12%;
  text-align: right;
  z-index: 5;
}

.historyimage {
  position: absolute;
  right: 0%;
  top: 16%;
  text-align: right;
  z-index: 5;
}

.historyimage img {
  animation: move 12s ease-in-out infinite;
}
.ourhistory .pluselementohwwa {
  position: absolute;
  left: 23%;
  top: 11%;
  z-index: 1;
}

.contentpartwwahistory {
  text-align: justify;
  z-index: 5;
}

.contentpartwwahistory h3 {
  font-size: 2.5rem;
  margin-bottom: 6rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #17355d;
}

.contentpartwwahistory .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #17355d;
}

.journeywwa {
  z-index: 5;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #17355d;
  font-size: 3rem;
  font-weight: bold;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  white-space: nowrap;
}

.contentpartjpwwa {
  z-index: 5;
}

.main-timeline10 .timeline {
  padding: 0;
  display: -webkit-inline-box;
}

.main-timeline10 .timeline-inner {
  text-align: center;
  margin: 20px 20px 35px 35px;
  position: relative;
}

.main-timeline10:after,
.main-timeline10:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline10 .timeline {
  padding: 0;
  display: -webkit-inline-box;
}

.main-timeline10 .timeline-inner {
  text-align: center;
  margin: 20px 20px 35px 35px;
  position: relative;
}

.main-timeline10 .timeline:last-child .timeline-inner:after {
  width: 0;
}

.main-timeline10 .year {
  background: #58b25e;
  padding: 5px 0;
  border-radius: 30px 0px 0;
  font-size: 26px;
  font-weight: 700;
  color: #fff;
  z-index: 1;
  position: relative;
}

.main-timeline10 .year:after,
.main-timeline10 .year:before {
  position: absolute;
  top: -19px;
  content: "";
}

.main-timeline10 .year:before {
  right: 0;
  border: 10px solid transparent;
  border-bottom: 10px solid #58b25e;
  border-right: 10px solid #58b25e;
}

.main-timeline10 .timeline-content {
  padding: 15px 10px 5px;
  border-left: 3px solid #58b25e;
  position: relative;
  min-height: 17rem;
  z-index: 1;
  background: #fff;
  border-radius: 0 0px 20px 0px;
}

.main-timeline10 .timeline-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  border: 10px solid transparent;
  border-top: 10px solid #58b25e;
  border-left: 10px solid #58b25e;
}

.main-timeline10 .timeline-content:after {
  content: "";
  width: 25px;
  height: 19px;
  border-radius: 20px 0 0;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.main-timeline10 .post {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  padding-left: 7px;
  text-align: left;
}

.main-timeline10 .description {
  font-size: 0.95rem;
  color: #333;
  line-height: normal;
  text-align: left;
  padding-left: 9px;
}

.main-timeline10 .timeline-icon {
  width: 70px;
  height: 70px;
  line-height: 65px;
  border-radius: 50%;
  border: 5px solid #58b25e;
  background: #fff;
  font-size: 30px;
  color: #555;
  z-index: 1;
  position: absolute;
  bottom: -35px;
  left: -35px;
}

.main-timeline10 .timeline:nth-child(2n) .year {
  background: #9f84c4;
}

.main-timeline10 .timeline:nth-child(2n) .year:before {
  border-bottom-color: #9f84c4;
  border-right-color: #9f84c4;
}

.main-timeline10 .timeline:nth-child(2n) .timeline-content {
  border-left-color: #9f84c4;
}

.main-timeline10 .timeline:nth-child(2n) .timeline-content:before {
  border-top-color: #9f84c4;
  border-left-color: #9f84c4;
}

.main-timeline10 .timeline:nth-child(2n) .timeline-icon {
  border-color: #9f84c4;
}

.main-timeline10 .timeline:nth-child(3n) .year {
  background: #f35958;
}

.main-timeline10 .timeline:nth-child(3n) .year:before {
  border-bottom-color: #f35958;
  border-right-color: #f35958;
}

.main-timeline10 .timeline:nth-child(3n) .timeline-content {
  border-left-color: #f35958;
}

.main-timeline10 .timeline:nth-child(3n) .timeline-content:before {
  border-top-color: #f35958;
  border-left-color: #f35958;
}

.main-timeline10 .timeline:nth-child(3n) .timeline-icon {
  border-color: #f35958;
}

.main-timeline10 .timeline:nth-child(4n) .year {
  background: #e67e49;
}

.main-timeline10 .timeline:nth-child(4n) .year:before {
  border-bottom-color: #e67e49;
  border-right-color: #e67e49;
}

.main-timeline10 .timeline:nth-child(4n) .timeline-content {
  border-left-color: #e67e49;
}

.main-timeline10 .timeline:nth-child(4n) .timeline-content:before {
  border-top-color: #e67e49;
  border-left-color: #e67e49;
}

.main-timeline10 .timeline:nth-child(4n) .timeline-icon {
  border-color: #e67e49;
}

.wwabiglogo {
  position: absolute;
  left: 45%;
  top: 34%;
  opacity: 0.6;
  z-index: 1;
}

.aletter {
  position: absolute;
  left: 22%;
  top: 8%;
  z-index: 40;
  opacity: 0.8;
  font-size: 8rem;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}

.wletter {
  position: absolute;
  left: 62%;
  top: 35%;
  z-index: 40;
  opacity: 0.8;
  font-size: 8rem;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}

.aaletter {
  position: absolute;
  left: 14%;
  top: 62%;
  z-index: 40;
  opacity: 0.8;
  font-size: 8rem;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}

.rletter {
  position: absolute;
  left: 65%;
  top: -7%;
  z-index: 40;
  opacity: 0.8;
  font-size: 8rem;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}

.dletter {
  position: absolute;
  left: 12%;
  top: 22%;
  z-index: 40;
  opacity: 0.8;
  font-size: 8rem;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}

.sletter {
  position: absolute;
  right: 21%;
  bottom: 13%;
  z-index: 40;
  opacity: 0.8;
  font-size: 8rem;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}

.team .contentpartteam {
  position: relative;
  margin-top: 0px;
  z-index: 5;
}

.team .contentpartteam h3 {
  font-size: 3rem;
  text-align: left;
  /* padding-left: 18rem; */
  /* padding-top: 6rem; */
  /* letter-spacing: 0.035em; */
  font-weight: 600;
  font-family: "Brandon";
  color: #17355d;
}

.contentpartteam h4 {
  position: relative;
  font-size: 3rem;
  margin-bottom: 2.5rem;
  letter-spacing: 0.035em;
  text-align: left;
  font-weight: 600;
  font-family: "Brandon";
  color: #17355d;
}

.contentpartteam .normaltext {
  font-size: 1.05rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  text-align: left;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}

.contentpartteam .gmdeskimg {
  position: relative;
  z-index: 5;
}

.team .contentpartteam h3 {
  font-size: 4rem;
  text-align: left;
  /* padding-left: 13rem; */
  /* padding-top: 7rem; */
  /* letter-spacing: 0.035em; */
  font-weight: 600;
  font-family: "Brandon";
  color: #17355d;
}

.teambiglogo {
  position: absolute;
  left: 7%;
  top: 3%;
  opacity: 0.6;
  z-index: 1;
}

.pluselementteamleadership {
  position: absolute;
  left: 69%;
  top: 5%;
  z-index: 1;
}

.teambg {
  background-image: url(../img/teamdotsbg.png);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 250vh;
}

.teammembers h5 {
  position: relative;
  text-align: center;
  font-size: 4rem;
  letter-spacing: 0.035em;
  z-index: 5;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}

.teamselement1bot {
  position: absolute;
  left: 29%;
  top: 0%;
  z-index: 40;
  opacity: 1;
}

.pluselementteams {
  position: absolute;
  left: 52%;
  bottom: 89%;
  z-index: 20;
  opacity: 1;
}

.partnerpluselement {
  position: absolute;
  left: 5.5%;
  top: 2%;
  z-index: 1;
}

.uparrowelementpartner {
  position: absolute;
  right: 6%;
  bottom: 54%;
  z-index: 1;
}
.wcuheading h3 {
  position: relative;
  /* font-size: 2.5rem; */
  /* margin-bottom: 4rem; */
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
  z-index: 2;
}

.contentpartpartner .normaltext {
  position: relative;
  font-size: 1.25rem;
  letter-spacing: 0.035em;
  text-align: right;
  line-height: 2rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
  z-index: 2;
}

.mt-16 {
  margin-top: 16rem;
}

.hoarr {
  position: absolute;
  left: 12%;
  bottom: 6%;
  z-index: 1;
}

.histext {
  width: 100%;
  background: #475e7c;
  position: relative;
}

.histextpart .slide-container {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  height: 30vh;
}

.histextpart .section {
  padding-top: 0;
  padding-bottom: 0;
}

.wwa .slide-bg,
.wwa .slide-bg > .wwa .inside {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-position: 70% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.wwa .slide-bg {
  overflow: hidden;
}

.wcu .slide-bg,
.wcu .slide-bg > .wcu .inside {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.wcu .slide-bg {
  overflow: hidden;
}

.wcu .slide {
  background: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 130vh;
}
.wcu .slide-container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.wcu .slide-bg > .inside {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.wcu .serviceelement2 {
  position: absolute;
  left: 8%;
  bottom: 4%;
  z-index: 2;
}
.wcu .semicircledownelement {
  position: absolute;
  left: 69%;
  bottom: 20%;
  z-index: 2;
}

.wcu .smallcircleelement {
  position: absolute;
  left: 59%;
  top: 20%;
  z-index: 1;
  animation: none;
  /* animation: animName 2s linear infinite; */
}

.wcubiglogo {
  position: absolute;
  left: 0px;
  top: 20px;
  z-index: 1;
  width: 200px;
}

.wcu .water1element {
  position: absolute;
  right: 0%;
  top: 3%;
  z-index: 1;
}

.wcu .wwasemicircleelement {
  position: absolute;
  /* right: 20%;
  top: 2%; */
  margin-left: 25px;
  z-index: 1;
  animation: animName 20s linear infinite;
}
.whoweare .wcuheading h3 {
  top: 60px;
}
.contentpartwcubg {
  position: relative;
  z-index: 1;
  background: #4eaeff;
  border-radius: 20px;
  opacity: 0.8;
  min-height: auto;
  top: 80px;
}

.uparrowelementWCU {
  position: absolute;
  right: 4%;
  bottom: 10%;
  z-index: 1;
}

.contentpartwcubg .normaltext {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  letter-spacing: 0.035em;
  line-height: 2rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
  border-right: solid 1px #fff;
}

.contentpartwcubg .listtext {
  font-size: 1.05rem;
  letter-spacing: 0.035em;
  line-height: 1.8rem;
  font-weight: 300;
  font-family: "Brandon";
  color: #fff;
  /* text-align: justify; */
}

.misvis .slide {
  background: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 130vh;
}
.misvis .slide-container {
  position: relative;
  min-height: 130vh;
  padding-top: 9rem;
  padding-bottom: 4rem;
}

.misvis .slide-bg > .inside {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.misvis {
  z-index: 1;
}

.missionpart {
}
.visionpart {
}

.contentpartmission h3 {
  /* font-size: 2.5rem; */
  font-size: 20px;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
  z-index: 2;
  /* text-align: center; */
}

.contentpartmission .normaltext {
  font-size: 1.25rem;
  letter-spacing: 0.035em;
  line-height: 2rem;
  font-weight: normal;
  padding: 0 20px;
  font-family: "Brandon";
  color: #fff;
  text-align: justify;
  z-index: 2;
  /* margin-top: 100px; */
}

.contentpartvision h3 {
  /* font-size: 2.5rem; */
  font-size: 20px;
  /* margin-bottom: 6rem; */
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
  z-index: 2;
  /* text-align: center; */
}

.contentpartvision .normaltext {
  font-size: 1.25rem;
  letter-spacing: 0.035em;
  line-height: 2rem;
  padding: 0 20px;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
  text-align: justify;
  z-index: 2;
  /* margin-top: 100px; */
}

.pluselementmis {
  position: absolute;
  left: 10%;
  top: 3%;
  z-index: 2;
}
.pluselementvis {
  position: absolute;
  right: 10%;
  top: 3%;
  z-index: 2;
}
.uparrowelementmis {
  position: absolute;
  left: 2%;
  top: 14%;
  z-index: 2;
}
.element2vis {
  position: absolute;
  right: 8%;
  top: 26%;
  z-index: 2;
}

.bb {
  border-bottom: solid 1px #fff;
  width: 100%;
  height: 2px;
  z-index: 2;
  /* position: absolute; */
  /* left: 0%; */
  /* top: 30%; */
}
.translateX263 {
  transform: translateX(-263px);
}
.journeybg .textnormal {
  color: white !important;
}
.journeybg .year {
  color: white !important;
}

.timeline {
  white-space: nowrap;
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 11rem;
}
.timeline .ship {
  position: absolute;
  top: 80px;
  left: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  z-index: 3;
}
.timeline ul {
  left: 50%;
  position: relative;
  margin-left: -120px;
  margin-bottom: 50px;
  transition: transform 0.5s linear;
}
.timeline li {
  width: 240px;
  height: 300px;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  white-space: normal;
  vertical-align: top;
  cursor: pointer;
}
.timeline li:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 30px;
  z-index: 2;
  -webkit-transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -moz-transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.timeline li .year {
  opacity: 0.1;
}
.timeline li:hover:before {
  -webkit-animation: timeline-bullet--hover 0.8s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  -moz-animation: timeline-bullet--hover 0.8s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  animation: timeline-bullet--hover 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite;
  background-color: #d7d7ff;
}
.timeline li.next-step .year,
.timeline li.next-step p,
.timeline li.prev-step .year,
.timeline li.prev-step p {
  opacity: 0.5;
}
.timeline li.active:before {
  background-color: #29a8df;
  box-shadow: 0 0 10px 12px #29a8df;
  -webkit-transform: scale(1.8);
  -moz-transform: scale(1.8);
  -ms-transform: scale(1.8);
  -o-transform: scale(1.8);
  transform: scale(1.8);
  -webkit-animation: timeline-bullet--active 0.8s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  -moz-animation: timeline-bullet--active 0.8s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  animation: timeline-bullet--active 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite;
}
.timeline li.active .year {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 1;
}
.timeline li.active p {
  opacity: 1;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.timeline li p {
  opacity: 0.1;
  -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.timeline li:after {
  content: "";
  position: absolute;
  top: 33px;
  left: 50%;
  margin-left: 3px;
  width: 266px;
  height: 2px;
  background-color: #4949e7;
  z-index: 1;
}
.timeline li:last-child:after {
  display: none;
}
.timeline__cursor {
  position: absolute;
  top: 36.6%;
  left: 47%;
  z-index: 111111115555;
  margin: 0 0 0 15px;
  transform: rotate(45deg);
}
.timeline li .year {
  display: block;
  margin-bottom: 60px;
  margin-top: -40px;
  width: 70%;
  margin-left: 30px;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.headingbold {
  font-weight: 700;
  /* font-size: 2rem; */
  font-size: 20px;
  margin-bottom: 2rem;
}
.headingbold1 {
  color: #b832b8;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold2 {
  color: #6f29a6;
  font-weight: 700;
  /* font-size: 1.5rem; */
  font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold3 {
  color: #108d4b;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold4 {
  color: #0682ff;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold5 {
  color: #e97a03;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold6 {
  color: #ff00c6;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold7 {
  color: #ff006c;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold8 {
  color: #0a6ca4;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.headingbold9 {
  color: #0d9d2b;
  font-weight: 700;
  /* font-size: 1.5rem; */
    font-size: 20px;
  margin-bottom: 2rem;
  letter-spacing: 0;
}
.bg1 {
  background: #b832b8;
  border-radius: 50px;
}
.bg2 {
  background: #6f29a6;
  border-radius: 50px;
}
.bg3 {
  background: #108d4b;
  border-radius: 50px;
}
.bg4 {
  background: #0682ff;
  border-radius: 50px;
}
.bg5 {
  background: #e97a03;
  border-radius: 50px;
}
.bg6 {
  background: #ff00c6;
  border-radius: 50px;
}
.bg7 {
  background: #ff006c;
  border-radius: 50px;
}
.bg8 {
  background: #0a6ca4;
  border-radius: 50px;
}
.bg9 {
  background: #0d9d2b;
  border-radius: 50px;
}
.build {
  position: absolute;
  top: 90%;
  left: 42%;
}
.iata {
  position: absolute;
  top: 90% !important;
  left: 0;
}

.spatr {
  position: absolute;
  top: 100%;
  left: 42%;
}
.letr {
  position: absolute;
  top: 100%;
  left: 42%;
}
.vfsg {
  position: absolute;
  top: 100%;
  left: 42%;
}
.iso {
  position: absolute;
  top: 94%;
  left: 35%;
}
.spcar {
  position: absolute;
  top: 98%;
  left: 42%;
}
.online {
  position: absolute;
  top: 100%;
  left: 42%;
}
.smtool {
  position: absolute;
  top: 100%;
  left: 42%;
}
.bubble {
  margin: 70px 0px 30px 0px;
  padding: 10px 20px 10px 20px;
  position: relative;
  border-radius: 20px;
  background: #fff;
  border-color: #fff;
}
.bubble:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 100px;
  border-top: 15px solid #fff;
  border-top-color: inherit;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.journeytimeline h4 {
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #17355d;
  font-size: 3rem;
  font-weight: bold;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.journeybg {
  background: url(../img/jurneybg.png);
  background-repeat: no-repeat;
  height: 650px;
  width: 100%;
}
.pointer {
  width: 200px;
  height: 40px;
  position: relative;
  background: #b832b8;
  background-image: linear-gradient(#b832b8, #9834b9);
}
.pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.pointer:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #b832b8;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.split-background {
  display: flex;
  /* height: 100vh; Adjust as needed */
}
.left-half {
  flex: 1;
  background-color: #0dcaf0; /* Left half background color */

  padding: 20px;
}
.right-half {
  flex: 1;
  background-color: #052c65; /* Right half background color */

  padding: 20px;
}
.whoweare .forheight {
  position: relative;
  /* top: -95px; */
  /* height: 100vh; */
}

@media (max-width: 426px) {
  .whoweare .timeline__cursor {
    top: 30.2% !important;
    left: 39% !important;
  }
  .whoweare .contentpartwcubg {
    margin-left: 1%;
    width: 98%;
  }

  .whoweare .wwabpluselement {
    display: none !important;
  }
  .whoweare .techbannertext {
    top: 42% !important;
  }
  .whoweare .techelementsmallx {
    display: none !important;
  }
  .whoweare .wwabotline {
    display: none !important;
  }
  .whoweare .pluselementohwwa {
    display: none !important;
  }
  .whoweare .wcu .smallcircleelement {
    display: none !important;
  }
  .whoweare .uparrowelementWCU {
    display: none !important;
  }
  .whoweare .smallcircleelementteams {
    display: none !important;
  }
  .whoweare .historyimagecontainer {
    display: none !important;
  }
  .whoweare .wcu .water1element {
    /* display: none !important; */
  }
  .whoweare .wcu .wwasemicircleelement {
    display: none !important;
  }
  .whoweare .histext {
    height: auto !important;
  }
  .whoweare .journeywwa {
    writing-mode: revert !important;
    transform: inherit !important;
    position: relative;
    left: 5%;
    bottom: 70px;
    font-size: 2rem !important;
  }
  .whoweare .contentpartwcubg .normaltext {
    border-right: none !important;
    border-bottom: solid 1px #fff;
    padding-bottom: 10px;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .whoweare .contentpartwcubg .listtext {
    padding-top: 20px;
    margin-left: -6%;
  }
  .whoweare .semicircledownelement {
    display: none;
  }

  .whoweare .waveselementdark {
    display: none;
  }
  .whoweare .serviceelement1bot {
    display: none;
  }
  .whoweare .serviceroundtxttechnology {
    display: none;
  }
  .whoweare .misvis .slide-container {
    min-height: 100vh !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .whoweare .misviselementsmallx {
    display: none;
  }
  .whoweare .pluselementmis {
    display: none;
  }
  .whoweare .pluselementvis {
    display: none;
  }
  .whoweare .bb {
    display: none;
  }
  .whoweare .element2vis {
    top: 93% !important;
  }
  .whoweare .wwabiglogo {
    left: 33% !important;
    top: 45% !important;
  }
  .whoweare .aletter {
    display: none;
  }
  .whoweare .wletter {
    display: none;
  }
  .whoweare .aaletter {
    display: none;
  }
  .whoweare .rletter {
    display: none;
  }
  .whoweare .dletter {
    display: none;
  }
  .whoweare .sletter {
    display: none;
  }
  .whoweare .pluselementaward {
    display: none;
  }
  .whoweare .pluselementaward1 {
    display: none;
  }
  .whoweare .semicircleelementteam {
    display: none;
  }
  .whoweare .teambiglogo {
    display: none;
  }
  .whoweare .pluselementteamleadership {
    display: none;
  }
  .whoweare .teamselementsmallx {
    display: none;
  }
  .whoweare .semicircledownelementteams {
    display: none;
  }
  .whoweare .teamselement1bot {
    display: none;
  }
  .whoweare .pluselementteams {
    display: none;
  }
  /* .whoweare .team .contentpartteam h3 {
    font-size: 2.6rem !important;
    text-align: center !important;
    padding-left: 0rem !important;
    padding-top: 19rem !important;
  } */
  .whoweare .team .contentpartteam {
    position: relative;
    /* margin-top: -124px; */
  }
  .whoweare .waveselementdarkteams {
    /* left: 3% !important;
    bottom: 30px !important; */
    visibility: hidden;
  }
  .whoweare .water1elementteam {
    /* top: 16% !important;
    right: -8%; */
    visibility: hidden;
  }
  .whoweare .uparrowelementpartner {
    bottom: 40% !important;
  }
  .whoweare .mt-16 {
    margin-top: 12rem !important;
  }
  .whoweare .uparrowelementpartner {
    left: 90%;
    bottom: 15% !important;
  }
  /* .whoweare .teammembers h5 {
    display: none !important;
  } */
  .whoweare .contentpartteam h4 {
    padding-left: 10px;
    margin-bottom: 0.5rem;
  }
  .whoweare .gmsmsg p {
    padding-left: 10px;
  }
  .whoweare .mobpad15 {
    padding: 15px;
  }
  .whoweare .partnerpluselement {
    left: -59.5% !important;
  }
  .whoweare .teambg {
    min-height: 160vh !important;
  }
  .whoweare .wwasmlogo {
    display: none !important;
  }
  .whoweare .serviceelementjwwab {
    display: none !important;
  }
  .whoweare .techpulusbot {
    bottom: 60% !important;
  }
  .whoweare .contentpartpartner h3 {
    text-align: left !important;
    position: relative;
    margin-top: 20px;
    /* font-size: 2.3rem; */
    font-size: 20px;
    text-align: right;
    letter-spacing: 0.035em;
    font-weight: 600;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .contentpartpartner .normaltext {
    text-align: left !important;
    position: relative;
    /* font-size: 1rem; */
    font-size: 16px;
    letter-spacing: 0.1rem;
    text-align: right;
    line-height: 17px;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .whowearebannerfont {
    margin-top: 5%;
    width: 100%;
    /* font-size: 1.3rem !important; */
    /* left: -9%; */
  }
  .whoweare .techbannertext .normaltext {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-top: 11px;
  }
  /* .whoweare .historybiglogo {
    visibility: hidden;
  } */
  .whoweare .contentpartwwahistory h3 {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    margin-top: 5%;
  }
  .whoweare .slide-container {
    /* padding-top: 0rem; */
    /* padding-inline: 6%; */
    height: auto;
  }
  .whoweare .vfsg {
    position: absolute;
    top: 87%;
    left: 32%;
  }
  .whoweare .journey {
    padding-top: 3rem !important;
    margin-bottom: -1.25rem;
    font-size: 1.3rem;
  }
  .whoweare .wcubiglogo {
    /* visibility: hidden; */
  }
  .whoweare .contentpartmission .normaltext {
    font-size: 1rem;
    margin-top: 11px;
    letter-spacing: 0em;
    line-height: 1.8rem;
  }
  .whoweare .gmsmsg {
    padding-inline: 4rem;
    padding-right: 4.5rem !important;
  }
  .whoweare .contentpartteam h4 {
    /* font-size: 1.3rem; */
  }
  .whoweare .wwabiglogo {
    visibility: hidden;
  }
  .whoweare .contentpartvision .normaltext {
    margin-top: 19px;
    letter-spacing: 0em;
    line-height: 1.8rem;
    font-size: 1rem;
  }
  .whoweare .iata {
    position: absolute;
    top: 90%;
    left: 0%;
  }
  .whoweare .journeybg {
    background-repeat: no-repeat;
    height: 578px;
    width: 100%;
  }
  .whoweare .contentpartvision h3 {
    /* font-size: 1.3rem; */
    /* padding-top: 5%;
    margin-bottom: 1.8rem; */
  }
  /* .whoweare .teammembers h5 {
    font-size: 1.3rem;
  } */
  .whoweare .contentpartwwahistory .normaltext {
    line-height: 1.5rem;
    letter-spacing: 0.035em;
  }

  .whoweare .rightpartpartner {
    margin-left: -11%;
    padding-inline: 4%;
  }
  .whoweare .rightpartpartner .normaltext {
    /* font-size: 2rem; */
    font-size: 20px;
    font-weight: medium;
    line-height: 1rem !important;
    text-decoration: none;
  }
  .whoweare .rightpartpartner h3 {
    left: 10%;
    position: relative;
    /* font-size: 2.3rem; */
    font-size: 16px;
    text-align: left;
    /* line-height: 1.2rem; */
    margin-bottom: 0rem !important;
    letter-spacing: 0.035em;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .partner-list {
    margin-top: 1rem;
    text-align: center;
    padding-inline: 4%;
  }
  .whoweare .left-half {
    flex-basis: 100%;
  }
  .whoweare .right-half {
    flex-basis: 100%;
  }
  /* .whoweare .build {
    visibility: hidden;
  } */
  .whoweare .wcuheading h3 {
    margin-bottom: 2rem;
    /* margin-left: 4%; */
  }
  .whoweare .contentpartmission h3 {
    /* font-size: 1.3rem; */
  }
  /* .whoweare .whychooseus {
    height: 108vh;
  } */
  .whoweare .ourhistoryone {
  }
  .whoweare .timeline li {
    margin-right: 18px;
  }
  .whoweare .forheight {
    position: relative;
    /* top: 0px; */
    /* height: 100vh; */
  }
  .whoweare .contentpartwwahistory {
    padding-inline: 4%;
  }
  .whoweare .contentpartpartner {
    padding-inline: 4%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .whoweare .timeline__cursor {
    top: 33% !important;
    left: 48% !important;
    transform: rotate(45deg) translate(0, 16%);
  }
}

@media (min-width: 823px) and (max-width: 991px) {
  .whoweare .timeline__cursor {
    top: 31% !important;
    left: 47% !important;
    transform: rotate(45deg) translate(0, 9%);
  }
}

@media (min-width: 768px) and (max-width: 822px) {
  .whoweare .timeline__cursor {
    top: 31% !important;
    left: 42% !important;
    transform: rotate(45deg) translate(0, 5%);
  }
}


@media (min-width: 581px) and (max-width: 767px) {

  .whoweare .timeline__cursor {
    top: 29% !important;
    left: 41% !important;
    transform: rotate(45deg) translate(0, 2%);
  }
}

@media (max-width: 767px) {
  .main-timeline10 .timeline-inner:after,
  .main-timeline10 .timeline:nth-child(2n) .timeline-inner:after {
    width: 100%;
    height: 3px;
    left: 0;
  }
  .whoweare .historyimage {
    top: 12% !important;
    right: -29%;
  }

}

@media (min-width: 427px) and (max-width: 580px) {
  .whoweare .timeline__cursor {
    top: 29% !important;
    left: 38% !important;
  }
}

@media only screen and (max-width: 990px) {
  .main-timeline10 .timeline-inner:after {
    width: 110%;
  }

  .main-timeline10 .timeline:nth-child(2n) .timeline-inner:after {
    width: 0;
  }
}

/* @media (min-width: 427px) and (max-width: 769px) { */
@media (min-width: 427px) and (max-width: 821px) {
  .whoweare .historyimgpartwwa {
    top: 7% !important;
    right: -27%;
  }
  .whoweare .water1elementteam {
    /* top: 5% !important;
    right: -2%; */
    visibility: hidden;
  }
  .whoweare .waveselementdarkteams {
    bottom: 35px !important;
  }
  .whoweare .contentpartpartner h3 {
    text-align: left !important;
  }
  .whoweare .contentpartpartner .normaltext {
    text-align: left !important;
    position: relative;
    font-size: 1.1rem;
    letter-spacing: 0.035em;
    text-align: right;
    line-height: 1.5rem;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  /* .whoweare .timeline__cursor {
    top: 29% !important;
    left: 38% !important;
  } */
  .whoweare .row.mt-16 {
    justify-content: space-around;
  }
  .whoweare .historyimage {
    top: 13% !important;
    right: -30%;
  }
  .whoweare .ourhistory .pluselementohwwa {
    top: 3% !important;
    left: 40% !important;
  }
  .whoweare .journeywwa {
    text-align: center;
  }
  .whoweare .contentpartwcubg .normaltext {
    border-right: none !important;
    border-bottom: solid 1px #fff;
    padding-bottom: 10px;
  }
  .whoweare .contentpartwcubg .listtext {
    padding-top: 20px;
  }
  .whoweare .serviceroundtxttechnology {
    display: none !important;
  }
  .whoweare .smallcircleelement {
    display: none !important;
  }
  .whoweare .semicircledownelement {
    display: none !important;
  }
  .whoweare .serviceelement1bot {
    display: none !important;
  }
  .whoweare .misviselementsmallx {
    display: none !important;
  }
  .whoweare .pluselementmis {
    display: none !important;
  }
  .whoweare .pluselementvis {
    display: none !important;
  }
  .whoweare .bb {
    display: none !important;
  }
  /* .whoweare .contentpartwcubg {
    top: 131px !important;
  } */
  .whoweare .misvis .slide-container {
    min-height: 90vh !important;
  }
  .whoweare .wwabiglogo {
    /* left: 41% !important;
    top: 34% !important; */
    visibility: hidden;
  }
  .whoweare .teambg {
    min-height: 100vh !important;
  }

  .whoweare .pluselementteams {
    display: none !important;
  }
  .whoweare .teamselementsmallx {
    display: none !important;
  }
  .whoweare .teamselement1bot {
    display: none !important;
  }
  .whoweare .semicircleelementteam {
    display: none !important;
  }
  .whoweare .pluselementteamleadership {
    display: none !important;
  }
  .whoweare .semicircledownelementteams {
    display: none !important;
  }
  .whoweare .smallcircleelementteams {
    display: none !important;
  }
  .whoweare .serviceelementteams {
    display: none !important;
  }
  .whoweare .contentpartpartner {
    /* visibility: hidden; */
    /* padding: 15px; */
    /* margin-top: 16%; */
  }
  .whoweare .contentpartpartner h3 {
    text-align: right !important;
    /* font-size: 2.3rem; */
    font-size: 20px;
    margin-top: 11px;
  }
  .whoweare .contentpartpartner .normaltext {
    text-align: right !important;
  }
  .whoweare .slide-container {
    /* padding-top: 0px !important; */
    /* padding-inline: 5%; */
  }
  .whoweare .partnerpluselement {
    display: none !important;
  }
  .whoweare .rightpartpartner {
    /* padding: 15px !important; */
    margin-top: 26%;
    margin-left: -9%;
  }
  .whoweare .wwabpluselement {
    left: 57% !important;
    top: 12% !important;
  }
  .whoweare .techpulusbot {
    display: none !important;
  }
  .whoweare .contentpartwwahistory {
    margin-top: 84px !important;
  }

  .whoweare .wcu .serviceelement2 {
    left: 6%;
    bottom: 3%;
  }
  /* .whoweare .historybiglogo {
    visibility: hidden;
  } */
  .whoweare .contentpartwwahistory h3 {
    margin-bottom: 1rem;
    font-size: 2rem;
    margin-top: -12%;
  }
  .whoweare .wcubiglogo {
    /* visibility: hidden; */
  }
  .whoweare .whowearebannerfont {
    /* margin-left: -1%; */
    /* font-size: 2rem; */
    /* position: absolute;
    top: 9%; */
  }
  .whoweare .wcu .wwasemicircleelement {
    visibility: hidden;
  }
  .whoweare .wwasmlogo {
    position: absolute;
    left: 2%;
    top: 16%;
  }
  .whoweare .techbannertext .normaltext {
    margin-top: 58px;
    line-height: 1.5rem;
  }
  .whoweare .teambiglogo {
    position: absolute;
    left: 2%;
  }
  .whoweare .contentpartmission .normaltext {
    margin-top: 19px;
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: 0rem;
  }
  .whoweare .contentpartmission h3 {
    /* padding-top: 6%; */
    /* font-size: 2rem; */
  }
  .whoweare .element2vis {
    top: 7%;
    right: 8%;
  }
  .whoweare .rightpartpartner .normaltext {
    line-height: 0.5rem;
    left: 10%;
    position: relative;
    /* font-size: 2rem; */
    font-size: 20px;
    letter-spacing: 0.035em;
    text-align: left;
    font-weight: medium;
    color: #fff;
    z-index: 2;
    text-decoration: none;
    font-family: "Brandon";
  }
  .whoweare .rightpartpartner h3 {
    left: 10%;
    position: relative;
    /* font-size: 2.3rem; */
    font-size: 16px !important;
    text-align: left;
    /* line-height: 2.11rem; */
    margin-bottom: 0rem !important;
    letter-spacing: 0.035em;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .partner-list {
    margin-top: 12vmin !important;
    text-align: center;
  }
  .whoweare .uparrowelementpartner {
    position: absolute;
    right: 6%;
    bottom: 18%;
    z-index: 1;
  }
  .whoweare .forheight {
    position: relative;
    /* top: 0px; */
    /* height: 100vh; */
  }
  .whoweare .timeline ul {
    margin-left: -140px;
  }
  .whoweare .techbannertext {
    top: 52%;
    left: 5%;
  }
  .whoweare .histext {
    /* padding-inline: 5%; */
  }
  .whoweare .journeybg .journey {
    /* margin-top: 5%; */
  }
  .whoweare .contentpartvision h3 {
    /* margin-bottom: -5rem; */
    /* font-size: 2rem; */
  }
  .whoweare .contentpartvision .normaltext {
    margin-top: 84px;
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: 0rem;
  }
  .whoweare .uparrowelementWCU {
    right: 1%;
    bottom: 10%;
  }
  .whoweare .pluselementaward img {
    visibility: hidden;
  }
  .whoweare .hoarr {
    position: absolute;
    left: 7%;
    bottom: 20%;
    z-index: 1;
  }
  .whoweare .uparrowelementmis {
    position: absolute;
    left: 2%;
    top: 74%;
    z-index: 2;
  }
  .whoweare .pluselementaward1 img {
    visibility: hidden;
  }
  /* .whoweare .team .contentpartteam h3 {
    font-size: 2.4rem;
    padding-left: 22rem;
    padding-top: 11rem;
  } */
  .whoweare .wwabotline {
    position: absolute;
    left: 5%;
  }
  .whoweare .serviceelementjwwab {
    position: absolute;
    left: 6%;
  }
}

@media screen and (min-width: 1024px) {
  .whoweare .partnerpluselement img {
    visibility: hidden;
  }
  .whoweare .contentpartpartner h3 {
    position: relative;
    margin-top: 0px;
    /* font-size: 2.3rem; */
    font-size: 20px;
    text-align: right;
    letter-spacing: 0.035em;
    font-weight: 600;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .contentpartpartner .normaltext {
    position: relative;
    font-size: 1rem;
    letter-spacing: 0.025rem;
    text-align: right;
    line-height: 1.5rem;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .rightpartpartner {
    margin-left: -8%;
    margin-top: 5.45rem;
  }
  .whoweare .rightpartpartner .normaltext {
    left: 10%;
    position: relative;
    /* font-size: 2rem; */
    font-size: 20px;
    letter-spacing: 0.035em;
    text-align: left;
    line-height: 0.5rem;
    font-weight: 600;
    color: #fff;
    z-index: 2;
    text-decoration: none;
    font-family: "Brandon";
  }
  .whoweare .rightpartpartner h3 {
    left: 10%;
    position: relative;
    /* font-size: 2.3rem; */
    font-size: 16px;
    text-align: left;
    /* line-height: 2.2rem; */
    margin-bottom: 0rem;
    letter-spacing: 0.035em;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
    z-index: 2;
  }
  .whoweare .contentpartpartner {
    margin-top: 15%;
    padding-inline: 5.6rem;
    margin-right: -68px;
  }
  .whoweare .partner-list {
    margin-left: 1%;
    text-align: center;
    margin-top: 1vmin;
  }
  .whoweare .uparrowelementpartner {
    position: absolute;
    right: 1%;
    bottom: 16%;
    z-index: 1;
  }
  .whoweare .wwasmlogo {
    /* position: absolute;
    left: 2%;
    top: 15%; */
    visibility: hidden;
  }
  .whoweare .techbannertext .normaltext {
    margin-left: 0%;
    margin-top: 7px;
    line-height: 1.3rem;
  }
  .whoweare .wwabotline {
    position: absolute;
    left: 4%;
    top: 47%;
  }
  .whoweare .wwabpluselement {
    position: absolute;
    left: 46%;
    top: 7%;
    z-index: 1;
  }
  .whoweare .serviceelementjwwab {
    position: absolute;
    left: 4%;
    bottom: 38%;
    z-index: 2;
  }
  /* .whoweare .historybiglogo {
    visibility: hidden;
  } */
  .whoweare .contentpartwwahistory h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    letter-spacing: 0.035em;
    font-weight: 600;
    font-family: "Brandon";
    color: #17355d;
  }
  .whoweare .contentpartwwahistory {
    width: 37%;
  }
  .whoweare .ourhistory .pluselementohwwa {
    position: absolute;
    left: 32%;
    top: 1%;
    z-index: 1;
  }
  .whoweare .historyimgpartwwa {
    position: absolute;
    right: 0%;
    top: 12%;
    text-align: right;
    z-index: 5;
  }
  .whoweare .historyimage {
    position: absolute;
    right: 3%;
    top: 16%;
    text-align: right;
    z-index: 5;
  }
  .whoweare .histext {
    width: 100%;
    background: #475e7c;
    position: relative;
    /* height: 29vh; */
  }

  .journey {
    font-size: 20px;
  }
  .whoweare .wcubiglogo {
    /* visibility: hidden; */
  }
  .whoweare .wcu .water1element {
    /* visibility: hidden; */
  }
  .whoweare .timeline__cursor {
    position: absolute;
    top: 34%;
    left: 47%;
  }
  .whoweare .wcu .wwasemicircleelement {
    position: absolute;
    /* right: 20%;
    top: 2%;  */
    z-index: 1;
    animation: animName 20s linear infinite;
  }
  /* .whoweare .wcu .semicircledownelement {
    position: absolute;
    left: 69%;
    bottom: 20%;
    z-index: 2;
  } */
  .whoweare .wcu .smallcircleelement {
    position: absolute;
    left: 59%;
    top: 20%;
    z-index: 1;
    animation: none;
    /* animation: animName 2s linear infinite; */
  }
  .whoweare .uparrowelementWCU {
    bottom: 4%;
  }
  .whoweare .serviceroundtxttechnology {
    position: absolute;
    right: 2%;
    bottom: 3%;
    z-index: 1;
  }
  .whoweare .teambiglogo {
    left: 3%;
    top: 2%;
  }
  .whoweare .techbannertext {
    top: 42%;
    left: 4%;
  }
  .whoweare .whowearebannerfont {
    /* font-size: 1.5rem !important; */
  }
  .whoweare .contentpartmission h3 {
    /* margin-right: -5%; */
  }
  .whoweare .whoweare .forheight {
    position: relative;
    /* top: -95px;
    height: 103vh; */
  }
  .whoweare .water1elementteam {
    /* visibility: hidden; */
    top: 10%;
  }

  .whoweare .pluselementteamleadership {
    position: absolute;
    left: 72%;
    top: 4%;
  }
  /* .whoweare .team .contentpartteam h3 {
    font-size: 2.5rem;
    padding-top: 9rem;
  } */
  .whoweare .teamselement1bot {
    left: 25%;
    top: -10%;
  }
  .whoweare .contentpartmission .normaltext {
    font-size: 1rem;
    line-height: 1.7rem;
    letter-spacing: 0em;
  }
  .whoweare .contentpartvision .normaltext {
    font-size: 1rem;
    line-height: 1.7rem;
    letter-spacing: 0em;
  }
  .whoweare .contentpartvision h3 {
    /* font-size: 1.5rem; */
  }
  .whoweare .contentpartmission h3 {
    /* font-size: 1.5rem; */
  }
  .whoweare .contentpartwwahistory .normaltext {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .whoweare .contentpartteam h4 {
    /* font-size: 1.5rem; */
  }
  .whoweare .contentpartteam .normaltext {
    margin-top: -5%;
  }
  .whoweare .gmsmsg p {
    font-size: 1rem;
  }
  /* .whoweare .teammembers h5 {
    font-size: 1.5rem;
  } */
  .whoweare .pluselementteams {
    position: absolute;
    left: 58%;
  }
  .whoweare .misvis .slide-container {
    min-height: 100vh;
  }
  .whoweare .bb {
    border-bottom: solid 1px #fff;
    width: 100%;
    height: 31px;
  }
  .whoweare .pluselementaward {
    position: absolute;
    left: -2%;
    top: 21%;
  }
  .whoweare .contentpartwcubg .normaltext {
    font-size: 1rem;
  }
  .whoweare .contentpartwcubg .listtext {
    font-size: 1rem;
  }
}
/* // laptop large  */

@media screen and (min-width: 1200px) {
  .whoweare .slide-container {
    /* padding-top: 0vmin;
    padding-bottom: 0vmin; */
  }
  .whoweare .whoweare .forheight {
    /* height: 116vh; */
  }
  .whoweare .whoweare .contentpartpartner {
    margin-top: 11%;
  }
  /* .whoweare .wcu .semicircledownelement {
    position: absolute;
    left: 73%;
    bottom: 0%;
  } */
  .whoweare .serviceelement1bot {
    position: absolute;
    right: 38%;
    bottom: 2%;
  }
  .whoweare .timeline__cursor {
    position: absolute;
    /* top: 37%;
    left: 46%; */
    top: 34%;
    left: 47%;
  }
  /* .whoweare .team .contentpartteam h3 {
    font-size: 2.5rem;
    padding-top: 8rem;
  } */
  .whoweare .whowearebannerfont {
    /* margin-left: 6%; */
  }
  .whoweare .techbannertext .whowearebannerfont {
    margin-left: 0%;
  }
  .whoweare .contentpartwwahistory {
    width: 57%;
    margin-left: -7%;
  }

  .whoweare .whoweare .forheight {
    /* top: -132px; */
  }
}

@media screen and (min-width: 1280px) {
  .whoweare .timeline__cursor {
    position: absolute;
    top: 37.6%;
    left: 48%;
    transform: rotate(45deg)translate(0, 7%);
    }
}

@media screen and (min-width: 1600px) {
  .whoweare .timeline__cursor {
    position: absolute;
    top: 38%;
    left: 48%;
    transform: rotate(45deg)translate(0, 7%);
    }
}

@keyframes timeline-bullet--hover {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes timeline-bullet--active {
  0% {
    box-shadow: 0 0 10px 6px #202095;
  }
  50% {
    box-shadow: 0 0 10px 12px #202095;
  }
  to {
    box-shadow: 0 0 10px 6px #202095;
  }
}

.ourhistorytitle {
  /* font-size: 4rem; */
  font-size: 20px;
  margin-right: -60px;
  color: #17355d;
  z-index: 1;
}

.wcuheading img {
  margin-left: -60px;
}

.listtext ul li {
  position: relative;
  padding-bottom: 10px;
}

.listtext ul {
  list-style: none;
}

.listtext ul li:before {
  content: "";
  position: absolute;
  border-right: 1.5px solid white;
  border-bottom: 1.5px solid white;
  width: 7px;
  height: 7px;
  top: calc(50% - 4px);
  left: -20px;
  transform: translateY(-50%) rotate(-45deg);
}
.wwasemicircleelementteam {
  position: absolute;
  margin-left: 25px;
  animation: animName 20s linear infinite;
}

.semicircledownelementteams {
  position: absolute;
  left: 20%;
  bottom: 35px;
  z-index: 10;
}
