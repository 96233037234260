/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@font-face {
  font-family: "Brandon";
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype"),
    url("../fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype"),
    url("../fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype"),
    url("../fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("../fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arizonia";
  src: url("../fonts/Arizonia-Regular.eot");
  src: url("../fonts/Arizonia-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Arizonia-Regular.woff2") format("woff2"),
    url("../fonts/Arizonia-Regular.woff") format("woff"),
    url("../fonts/Arizonia-Regular.ttf") format("truetype"),
    url("../fonts/Arizonia-Regular.svg#Arizonia-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon";
  src: url("../fonts/Saira-Light.eot");
  src: url("../fonts/Saira-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Saira-Light.woff2") format("woff2"),
    url("../fonts/Saira-Light.woff") format("woff"),
    url("../fonts/Saira-Light.ttf") format("truetype"),
    url("../fonts/Saira-Light.svg#Saira-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon";
  src: url("../fonts/Saira-Bold.eot");
  src: url("../fonts/Saira-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Saira-Bold.woff2") format("woff2"),
    url("../fonts/Saira-Bold.woff") format("woff"),
    url("../fonts/Saira-Bold.ttf") format("truetype"),
    url("../fonts/Saira-Bold.svg#Saira-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon";
  src: url("../fonts/Saira-Regular.eot");
  src: url("../fonts/Saira-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Saira-Regular.woff2") format("woff2"),
    url("../fonts/Saira-Regular.woff") format("woff"),
    url("../fonts/Saira-Regular.ttf") format("truetype"),
    url("../fonts/Saira-Regular.svg#Saira-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon";
  src: url("../fonts/Saira-Thin.eot");
  src: url("../fonts/Saira-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Saira-Thin.woff2") format("woff2"),
    url("../fonts/Saira-Thin.woff") format("woff"),
    url("../fonts/Saira-Thin.ttf") format("truetype"),
    url("../fonts/Saira-Thin.svg#Saira-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Brandon';
  src:local("Brandon"), url('../fonts/brandon-text-font-family-1734407372-0/HvDTrial_Brandon_Text_Regular-BF6514e9eb617a0.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: local("Brandon"), url('../fonts/brandon-text-font-family-1734407372-0/HvDTrial_Brandon_Text_Medium-BF6514e9eb272a3.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: local("Brandon"), url('../fonts/brandon-text-font-family-1734407372-0/HvDTrial_Brandon_Text_Bold-BF6514e9eb720dc.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 12px;
  font-family: "Brandon", sans-serif;
}
body {
  font-family: "Brandon", sans-serif;
  color: #000000;
}

html > body .underline {
  text-decoration: underline;
}
html > body .underline:hover {
  text-decoration: none;
}
html > body .text-black {
  color: #000000;
}
html > body .text-grey-primary {
  color: #cccccc;
}
html > body .text-grey {
  color: #666666;
}
html > body .text-white a,
html > body .text-black a {
  color: inherit;
}
html > body .text-grey-light {
  color: #999999;
}
html > body .lh-sm {
  line-height: 1.25;
}
html > body .lh-md {
  line-height: 1.625;
}
html > body .lh-lg {
  line-height: 1.875;
}
html > body .lh-lg2 {
  line-height: 1.8;
}
html > body .fs14 {
  font-size: 0.875rem;
}
html > body .fs18 {
  font-size: 1.125rem;
}
html > body .fs20 {
  font-size: 1.25rem;
}
html > body .homepage .fs20 {
  font-size: 3.25rem;
  padding-left: 20px;
}
html > body .fs24 {
  font-size: 1.5rem;
}
html > body .fs28 {
  font-size: 1.75rem;
}
html > body .fs30 {
  font-size: 1.875rem;
}
html > body .fs36 {
  font-size: 2.25rem;
}
html > body .fs42 {
  font-size: 2.625rem;
}
html > body .fs48 {
  font-size: 3rem;
}
html > body .fs60 {
  font-size: 2.75rem;
  line-height: 1.36666667;
}
.fs18.text-grey {
  line-height: 1.66666667;
}
.h1-lg {
  font-size: 3.5rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}
/* for play and pause button  */

@media (min-width: 576px) {
  .text-right-sm {
    text-align: right !important;
  }
  html > body .fs60 {
    font-size: 3.75rem;
    line-height: 1.36666667;
  }
}

@media (min-width: 768px) {
  .text-right-md {
    text-align: right !important;
  }
  .h1-lg {
    font-size: 4.5rem;
    line-height: 1.13888889;
  }
}
@media (max-width: 768px) {
  .appsup {
    /* margin: -105px 50px 10px 30px !important; */
  }
  .dashboardpart .btn-primary {
    width: 92%;
  }
  .dashboard2 {
    bottom: 0% !important;
    left: 6%;
  }
  .dashboard1 {
    position: relative;
    top: 30%;
  }
  .choose-future {
    display: none !important;
  }
  .water1element {
    visibility: hidden;
  }
  .waveliteelement2 {
    visibility: hidden;
  }
  .wavedark2HA {
    visibility: hidden;
  }
  .waveselementdark {
    visibility: hidden;
  }
  /* .owl-carousel {
    display: none !important;
  } */
  .elementbg {
    display: none;
  }
  .biglogo-fixed {
    display: none;
  }
  .element2 {
    left: 37% !important;
  }
}

.vvw-50 {
  width: 50vw;
}

.mannai-logo-gif {
  background-image: url("../img/Mannai-Logo.gif");
}

.owl-dots {
  display: none !important;
}

.brand-logo {
  /* min-width: fit-content; */

  /* width: 157px;
  height: 100px; */
  color: #ffffff;
}
.master-logo {
  width: 126px;
  height: 80px;
}
.fr {
  float: right;
}
.rmbtn {
  position: absolute;
  bottom: -18%;
  left: 0;
}
.submitbtn {
  float: left;
  position: relative;
}
.submitbtn a {
  background-color: #3fa9f5;
  border-radius: 25px;
}
.a-pp-prev {
  text-decoration: none !important;
}
.rmbtn:focus {
  outline: none !important;
}
.homeicon {
  color: #fff;
  z-index: 10;
  font-size: 30px;
}
.h-fit-content {
  height: fit-content;
}
.footersub .form-control {
  display: block;
  width: 104%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background: none;
  background-clip: padding-box;
  border: 1px solid #29abe2;
  border-radius: 2rem;
  margin-left: 0%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
video {
  width: 100% !important;
  height: 100vh !important;
  position: relative;
  object-fit: cover !important;
}
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.menu a {
  color: #fff !important;
}
.menu a:hover {
  color: #02a6fd !important;
  text-decoration: none;
}
.animate-right {
  position: relative;
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animsition-link {
  font-size: 0.85rem;
  color: #000000;
  font-weight: normal;
}
.copyright {
  text-align: right;
}
.menu-copyright {
  margin-top: 0px;
  font-size: 0.8rem;
  line-height: 1.71428571;
  color: #ffffff;
  text-transform: capitalize;
  margin-right: 3rem;
}
.mt50 {
  margin-top: 60px;
}
.mt20 {
  margin-top: 30px;
}
.contentpart {
  position: absolute;
  right: 15%;
  top: 20%;
  width: 30%;
}
.contentparthome {
  position: absolute;
  left: 4%;
  /* top: 31%; */
  bottom: 15%;
  width: 38%;
  z-index: 10;
}
.contentpartwwd {
  position: absolute;
  left: 4%;
  /* top: 27%; */
  bottom: 15%;
  width: 38%;
  z-index: 10;
}
.contentparthome h3 {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #b3b3b3;
}
.contentparthome .boldtext {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  line-height: -0.7em;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}
.contentparthome .normaltext {
  margin-bottom: 2.5rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentparttechnology h3 {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #b3b3b3;
}
.contentparttechnology .boldtext {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  line-height: -0.7em;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}
.contentparttechnology .normaltext {
  margin-bottom: 2.5rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentparttechnology .boldtext {
  margin-bottom: 2.5rem;
  letter-spacing: -0.035em;
  line-height: -0.7em;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}
.contentpartwwa h3 {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #b3b3b3;
}
.contentpartwwa .boldtext {
  margin-bottom: 2.5rem;
  letter-spacing: -0.035em;
  line-height: -0.7em;
  font-weight: 800;
  font-family: "Brandon";
  color: #fff;
}
.contentpartwwa .normaltext {
  margin-bottom: 2.5rem;
  letter-spacing: -0.035em;
  line-height: -0.7em;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartwwd h3 {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #b3b3b3;
}
.contentpartwwd .boldtext {
  margin-bottom: 2rem;
  letter-spacing: -0.035em;
  line-height: -0.7em;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
}
.contentpartwwd .normaltext {
  margin-bottom: 2.5rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartserviceswwd {
  position: absolute;
  left: 46%;
  top: 30%;
  width: 55%;
}
.contentparttechnology {
  position: absolute;
  left: 4%;
  /* top: 22%; */
  bottom: 15%;
  width: 38%;
  z-index: 5;
}
.contentpartwwa {
  position: absolute;
  left: 4%;
  /* top: 23%; */
  bottom: 15%;
  width: 38%;
  z-index: 5;
}
.contentpartservices .atr {
  position: absolute;
  bottom: 70%;
  left: 0;
}
.contentpartservices .hotel {
  position: absolute;
  bottom: 30%;
  right: 0%;
}
.contentpartservices .ship {
  position: absolute;
  bottom: 0;
  right: 0;
}
.contentpartservicesAT {
  position: absolute;
  left: 12%;
  top: 25%;
  width: 60%;
}
.contentpartservicesAT h3 {
  font-size: 3rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesAT .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5em;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
  text-align: justify;
}
.contentpartservicesHA {
  position: absolute;
  right: 12%;
  top: 25%;
  width: 60%;
  text-align: justify;
}
.contentpartservicesHA h3 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.035em;
  /* text-align: right; */
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesHA .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  /* margin-top: 3rem; */
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesCR {
  position: absolute;
  left: 8%;
  top: 25%;
  width: 39%;
  text-align: justify;
}
.contentpartservicesCR h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesCR .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesRAIL {
  position: absolute;
  right: 9%;
  top: 20%;
  width: 37%;
  text-align: justify;
}
.contentpartservicesRAIL h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.035em;
  text-align: right;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesRAIL .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.6rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesPV {
  position: absolute;
  left: 8%;
  top: 25%;
  width: 42%;
  text-align: justify;
}
.contentpartservicesPV h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesPV .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesMG {
  position: absolute;
  right: 9%;
  top: 20%;
  width: 39%;
  text-align: justify;
}
.contentpartservicesMG h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.035em;
  /* text-align: right; */
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesMG .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesMICE {
  position: absolute;
  left: 8%;
  top: 32%;
  width: 49%;
  text-align: justify;
}
.contentpartservicesMICE h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesMICE .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesTIDL {
  position: absolute;
  right: 9%;
  top: 20%;
  width: 50%;
  text-align: justify;
}
.contentpartservicesTIDL h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.035em;
  text-align: right;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesTIDL .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesSUP {
  position: absolute;
  left: 8%;
  top: 25%;
  width: 45%;
  text-align: justify;
}
.contentpartservicesSUP h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}
.contentpartservicesSUP .normaltext {
  font-size: 1.05rem;
  margin-bottom: 3rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}
.contentpart h2 {
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
}
.contentpart h1 {
  margin-bottom: 1.75rem;
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #02a6fd;
}
.contentpartat h3 {
  font-size: 5rem;
  margin-bottom: 0.5rem;
  letter-spacing: -0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #ffffff;
}
.contentpartat p {
  margin-bottom: normal;
  letter-spacing: normal;
  font-weight: normal;
  font-family: "Brandon";
}
.contentpart p {
  margin-bottom: normal;
  letter-spacing: normal;
  font-weight: normal;
  font-family: "Brandon";
}
.smlogo-fixed {
  position: fixed;
  left: 300px;
  padding-top: 0.5rem;
  top: 10vmin;
  z-index: 300;
}
.biglogo-fixed {
  position: fixed;
  right: 0px;
  bottom: 5px;
  z-index: 300;
}
.servciebiglogo-fixedMICE {
  position: fixed;
  left: 0px;
  top: 160px;
  z-index: 300;
}
.servciebiglogo-fixed {
  position: fixed;
  left: -2%;
  top: 15%;
  z-index: 300;
}
.servciebiglogo-fixed img {
  width: 100%;
}
.servciebiglogoha-fixed {
  position: absolute;
  right: -1%;
  top: 18%;
}
.servicefooterlogo-fixed {
  position: fixed;
  right: -2%;
  top: 12%;
  z-index: 300;
}
.leftbg {
  position: fixed;
  left: -5px;
  padding: 0px;
  top: 0px;
  z-index: 1;
}
.elementbg {
  position: absolute;
  top: 0px;
  right: -14%;
  z-index: 1;
  width: 63vw;
}
.elementbg img {
  height: 100vh;
}
.botline {
  position: absolute;
  left: 60px;
  bottom: 30px;
  z-index: 2;
}
.verline {
  position: absolute;
  left: 30px;
  bottom: 70px;
  z-index: 2;
}
.roundelement {
  position: absolute;
  left: 20px;
  bottom: 30px;
  z-index: 2;
  animation: animName 2s linear infinite;
}
.element1 {
  position: absolute;
  left: 10px;
  bottom: 0px;
  z-index: 2;
  opacity: 0.2;
}
.element2 {
  position: absolute;
  /* left: 30%;
  bottom: 70px; */
  left: 5%;
  bottom: 12.9rem;
  z-index: 2;
}
.serviceelement2 {
  position: absolute;
  left: 8%;
  bottom: 31%;
  z-index: 2;
}
.serviceelement2MICE {
  position: absolute;
  left: 8%;
  bottom: 34%;
  z-index: 2;
}
.serviceelement2PV {
  position: absolute;
  left: 8%;
  bottom: 33%;
  z-index: 2;
}
.serviceelement2support {
  position: absolute;
  left: 8%;
  bottom: 38%;
  z-index: 2;
}
.serviceelement2CR {
  position: absolute;
  left: 8%;
  bottom: 33%;
  z-index: 2;
}
.serviceelementfooter {
  position: absolute;
  left: 2%;
  top: 23%;
  z-index: 2;
}

.element1bot {
  position: absolute;
  right: 40%;
  bottom: 50px;
  z-index: 2;
  opacity: 1;
}
.element1botWWA {
  position: absolute;
  right: 24%;
  bottom: 50px;
  z-index: 2;
  opacity: 1;
}
.serviceelement1bot {
  position: absolute;
  right: 25%;
  bottom: 2%;
  z-index: 2;
  opacity: 1;
}
.serviceelement1botCR {
  position: absolute;
  right: 38%;
  bottom: 18%;
  z-index: 2;
  opacity: 1;
}
.serviceelement1botPV {
  position: absolute;
  right: 29%;
  bottom: 3%;
  z-index: 2;
  opacity: 1;
}
.elementsmallx {
  position: absolute;
  left: 20%;
  bottom: 2%;
  z-index: 2;
  opacity: 1;
}

.elementsmallxTIDL {
  position: absolute;
  left: 50%;
  bottom: 12%;
  z-index: 2;
  opacity: 1;
}
.elementleft {
  position: absolute;
  left: 17%;
  top: 38%;
  z-index: 2;
  opacity: 1;
}
.leftarrowHA {
  position: absolute;
  right: 11%;
  top: 60%;
  z-index: 2;
  opacity: 1;
}
.leftarrowRAIL {
  position: absolute;
  right: 11%;
  top: 54%;
  z-index: 2;
  opacity: 1;
}
.leftarrowMG {
  position: absolute;
  right: 11%;
  top: 63%;
  z-index: 2;
  opacity: 1;
}

.animationtext {
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: 2;
  animation: animName 2s linear infinite;
}
.animationtextwwd {
  position: absolute;
  right: 8%;
  top: -10%;
  z-index: 2;
  animation: animName 2s linear infinite;
}
.animationtexttechnology {
  position: absolute;
  right: 6%;
  top: 0%;
  z-index: 2;
  animation: animName 2s linear infinite;
}
.animationtextwwa {
  position: absolute;
  right: 6%;
  top: 0%;
  z-index: 2;
  animation: animName 2s linear infinite;
}

.roundtxttechnology img {
  width: 120%;
}
.serviceroundtxttechnology {
  position: absolute;
  right: 13%;
  bottom: 28%;
  z-index: 1;
}
.serviceroundtxttechnologyPV {
  position: absolute;
  right: 15%;
  bottom: 15%;
  z-index: 1;
}
.serviceroundtxttechnologyCR {
  position: absolute;
  right: 10%;
  bottom: 30%;
  z-index: 1;
}
.serviceroundtxttechnologyHA {
  position: absolute;
  left: 6%;
  bottom: 25%;
  z-index: 1;
}
.pluselement {
  position: absolute;
  left: 22%;
  top: 12%;
  z-index: 1;
}
.pluselementHA {
  position: absolute;
  right: 30%;
  top: 12%;
  z-index: 1;
}
.pluselementRAIL {
  position: absolute;
  left: 37%;
  top: 8%;
  z-index: 1;
}
.pluselementCR {
  position: absolute;
  left: 43%;
  top: 14%;
  z-index: 1;
}
.pluselementTIDL {
  position: absolute;
  right: 35%;
  top: 3%;
  z-index: 1;
}
.pluselementfooter {
  position: absolute;
  left: 85%;
  bottom: 5%;
  z-index: 1;
}
.pluselementfootertop {
  position: absolute;
  top: 14%;
  left: 27%;
  z-index: 1;
}
.techpluselementfootertop {
  position: absolute;
  top: 18%;
  left: 27%;
  z-index: 1;
}

.semicircledownelement {
  position: absolute;
  right: -45px;
  bottom: 20%;
  z-index: 1;
}
.semicircledownelementHA {
  position: absolute;
  left: -8%;
  bottom: 15%;
  z-index: 1;
}
.smallcircleelement {
  position: absolute;
  left: 62%;
  /* bottom: 23%; */
  top: 25%;
  z-index: 1;
  /* animation: animName 2s linear infinite; */
}
.smallcircleelementHA {
  position: absolute;
  left: 35%;
  top: 20%;
  z-index: 1;
  /* animation: animName 2s linear infinite; */
}
.semicircleelement {
  position: absolute;
  left: 25px;
  top: -25%;
  z-index: 1;
  animation: animName 20s linear infinite;
}
.semicircleelementHA {
  position: absolute;
  right: 5%;
  top: -15%;
  z-index: 1;
  animation: animName 20s linear infinite;
}
.uparrowelementfooter {
  position: absolute;
  left: 94%;
  bottom: 4%;
  z-index: 1;
}
.uparrowelement {
  position: absolute;
  right: 25%;
  bottom: 15%;
  z-index: 1;
}

.uparrowelementCR {
  position: absolute;
  right: 5%;
  bottom: 26%;
  z-index: 1;
}
.uparrowelementHA {
  position: absolute;
  left: 20%;
  bottom: 12%;
  z-index: 1;
}
.water1element {
  position: absolute;
  right: 0%;
  top: 7%;
  z-index: 1;
}
.waveselementdark {
  position: absolute;
  left: -2%;
  bottom: 0%;
  z-index: 1;
}
.waveliteelement2 {
  position: absolute;
  left: -3%;
  top: 15%;
  z-index: 1;
}
.wavedark2 {
  position: absolute;
  right: 3%;
  bottom: 6%;
  z-index: 1;
}
.wavedark2HA {
  position: absolute;
  right: 0%;
  bottom: 4%;
  z-index: 1;
}
.wavedark2HA {
  position: absolute;
  right: 0%;
  bottom: 5%;
  z-index: 1;
}

.mt-50 {
  margin-top: 50px;
}
.videobtn {
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  position: absolute;
  z-index: 300;
}
.video {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #000000;
  z-index: 999;
}
.videowrapper {
  position: absolute;
  top: 52%;
  left: 53%;
  transform: translate(-50%, -50%);
}
.vbtn {
  width: 50px;
  height: 50px;
}
.video-main {
  position: relative;
  display: inline-block;
}
.video {
  height: 70px;
  width: 70px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #ffffff;
  z-index: 999;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}
.waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}
.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
#arrow-box {
  top: 62%;
  left: 46%;
  height: 60px;
  width: 65px;
  position: absolute;
  z-index: 300;
}
.light {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
  top: 80px;
  position: absolute;
  z-index: 10;
  left: 8px;
  animation: light 800ms ease-in-out 0s infinite alternate;
}
.cloud {
  position: absolute;
  top: 0px;
  right: -200px;
  opacity: 0.8;
  animation: cloud 10s linear 0s infinite reverse;
  z-index: 99;
}

@keyframes light {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cloud {
  from {
    right: -500px;
  }
  to {
    right: 102%;
  }
}

.icon-layer .icon-3 {
  left: 130px;
  bottom: 200px;
  width: 51px;
  height: 55px;
  background-repeat: no-repeat;
}
.icon-layer .icon {
  position: absolute;
  z-index: 300;
}
.icon-layer1 .icon-1 {
  right: 90px;
  top: 170px;
  width: 44px;
  height: 43px;
  background-repeat: no-repeat;
}
.icon-layer1 .icon1 {
  position: absolute;
  z-index: 300;
}
.icon-layer2 .icon-2 {
  left: 100px;
  bottom: 100px;
  width: 51px;
  height: 55px;
  background-repeat: no-repeat;
}
.icon-layer2 .icon2 {
  position: absolute;
  z-index: 300;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  width: 44px;
  animation: rotation 2s infinite linear;
}
.anim-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.anim-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.anim-icon .icon {
  position: absolute;
}
.anim-icon .icon-1 {
  width: 130px;
  height: 130px;
  left: 60px;
  top: 50%;
  -webkit-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-2 {
  width: 110px;
  height: 110px;
  left: 80px;
  top: 40%;
  -webkit-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-3 {
  width: 60px;
  height: 60px;
  left: 200px;
  top: 30%;
  -webkit-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-4 {
  width: 130px;
  height: 130px;
  right: 60px;
  top: 50%;
  -webkit-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-5 {
  width: 110px;
  height: 110px;
  right: 90px;
  top: 40%;
  -webkit-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-6 {
  width: 60px;
  height: 60px;
  right: 200px;
  top: 30%;
  -webkit-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-7 {
  width: 130px;
  height: 107px;
  right: 80px;
  top: 10%;
  -webkit-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-8 {
  width: 70px;
  height: 58px;
  right: 220px;
  top: 0%;
  -webkit-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-9 {
  top: 20%;
  -webkit-animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-10 {
  top: 30%;
  -webkit-animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-11 {
  top: 40%;
  -webkit-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-12 {
  top: 30%;
  -webkit-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-13 {
  top: 10%;
  -webkit-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 15s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon-14 {
  top: 0%;
  -webkit-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-15 {
  top: 20%;
  -webkit-animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 19s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.anim-icon .icon-16 {
  top: 30%;
  -webkit-animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 16s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.anim-icon .icon {
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.anim-icon .icon {
  background: #5277ff;
}
.anim-icon .icon-4,
.anim-icon .icon-7,
.banner-style-two .image-box .anim-icon .icon-3 {
  width: 15px;
  height: 15px;
}
.shape1 {
  position: absolute;
  top: 20%;
  left: 5%;
  z-index: -1;
}
.shape2 {
  position: absolute;
  top: 150px;
  left: 85px;
  z-index: -1;
}
.shape3 {
  position: absolute;
  left: 205px;
  bottom: 75px;
  z-index: -1;
}
.shape5 {
  position: absolute;
  top: 25px;
  right: 150px;
  z-index: -1;
}
.tk-blob {
  animation: blob-turn var(--time, 30s) linear infinite;
  position: absolute;
  right: 60px;
  top: 50px;
  float: right;
  transform-origin: center;
  width: 100px;
}
.zoominout {
  animation-name: zoomInOut;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: zoomInOut;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: zoomInOut;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-name: zoomInOut;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-name: zoomInOut;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: ease-in-out;
}

@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform: rotate(180deg) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.7);
    transform: rotate(360deg) scale(0.7);
    opacity: 0;
  }
}

.zoominout-2 {
  animation-name: zoomInOut;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: zoomInOut2;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: zoomInOut2;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-name: zoomInOut2;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-name: zoomInOut2;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: ease-in-out;
}

@-webkit-keyframes zoomInOut2 {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }
}

@-webkit-keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(-30px);
  }
}

@keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(-30px);
  }
}

.float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.lqd-shape-bottom {
  bottom: -1px;
}
.lqd-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

@-webkit-keyframes float-bob-x-2 {
  0% {
    transform: translateX(-30px) translateY(-50%);
  }
  50% {
    transform: translateX(-10px) translateY(-50%);
  }
  100% {
    transform: translateX(-30px) translateY(-50%);
  }
}

@keyframes float-bob-x-2 {
  0% {
    transform: translateX(-30px) translateY(-50%);
  }
  50% {
    transform: translateX(-10px) translateY(-50%);
  }
  100% {
    transform: translateX(-30px) translateY(-50%);
  }
}

.float-bob-x-2 {
  -webkit-animation-name: float-bob-x-2;
  animation-name: float-bob-x-2;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.slider {
  position: relative;
}
.mb-4 {
  margin-bottom: 5rem !important;
}
.slider .owl-item.active.center .slider-card {
  transform: scale(0.9);
  opacity: 1;
  color: #fff;
  margin-top: 4px;
}
.slider-card {
  padding: 0px 0px;
  margin: 0px 10px 0px 10px;
  transform: scale(0.9);
  opacity: 0.5;
  transition: all 0.3s;
}
.slider-card img {
  border-radius: 0px;
}
.car {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.87), 0 6px 20px 0 rgba(0, 0, 0, 0.796);
}
.owl-nav .owl-prev {
  position: absolutes;
  top: calc(50% - 25px);
  left: 0;
  opacity: 1;
  font-size: 30px !important;
  z-index: 1;
}
.owl-nav .owl-next {
  position: absolute;
  top: calc(50% - 25px);
  right: 0;
  opacity: 1;
  font-size: 30px !important;
  z-index: 1;
}
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  height: 5px;
  width: 60px;
  border-radius: 10px;
  background: #ccc !important;
  margin-left: 5px;
  margin-right: 5px;
  outline: none;
}
.owl-dots .owl-dot.active {
  /* background: #0099ff !important; */
}
.x_why_left_main_wrapper {
  float: left;
  width: 50%;
  position: absolute;
  right: 160px;
  top: 150px;
  -webkit-animation: movebounce 2.9s linear infinite;
  animation: movebounce 2.9s linear infinite;
}

@keyframes movebounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.efective__thumb img {
  width: 100%;
  animation: bns1 5s linear infinite;
}
.efect__element2 {
  position: absolute;
  bottom: 76px;
  right: 60px;
  animation: animName 2s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ani-left-right1 {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: LeftRight;
  animation-timing-function: ease-in-out;
}

@keyframes LeftRight {
  0% {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(10px, 0);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ani-move1 {
  animation: ImageMove 2s infinite linear;
  animation-duration: 3s;
}

@keyframes ImageMove {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(2px, 2px);
  }
  50% {
    transform: translate(3px, 5px);
  }
  75% {
    transform: translate(2px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ani-left-right {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: LeftRight;
  animation-timing-function: ease-in-out;
}

@keyframes LeftRight {
  0% {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(30px, 0);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ani-top-bottom {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: TopBottom;
  animation-timing-function: ease-in-out;
}

@keyframes TopBottom {
  0% {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0, 30px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ani-move {
  animation: ImageMove 4s infinite linear;
  animation-duration: 5s;
}

@keyframes ImageMove {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(10px, 20px);
  }
  50% {
    transform: translate(30px, 30px);
  }
  75% {
    transform: translate(20px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ani-move2 {
  animation: ImageMove2 2s infinite linear;
  animation-duration: 10s;
}

@keyframes ImageMove2 {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(20px, 30px);
  }
  50% {
    transform: translate(40px, 40px);
  }
  75% {
    transform: translate(30px, 50px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ani-left-right {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: LeftRight;
  animation-timing-function: ease-in-out;
}
.right-15 {
  right: 5%;
  left: inherit;
}
.right-2 {
  right: 2%;
  left: inherit;
}
.right-30 {
  right: 22%;
  left: inherit;
}
.left-30 {
  left: 25%;
  right: inherit;
}
.bot100 {
  bottom: 80px;
}
.right-0 {
  right: 0px;
  left: inherit;
}
.left-0 {
  left: 0px;
  right: inherit;
}
.top-0 {
  top: 0px;
  bottom: inherit;
}
.top-30 {
  top: 30%;
  bottom: inherit;
}
.top-20 {
  top: 18%;
  bottom: inherit;
}
.bottom-30 {
  bottom: 5%;
  top: inherit;
}
.bottom-0 {
  bottom: 0px;
  top: inherit;
}
.z-index-1 {
  z-index: 1;
}
.position-absolute {
  position: absolute !important;
}
.ani-top-bottom {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: TopBottom;
  animation-timing-function: ease-in-out;
}
.left-5 {
  left: 10%;
  right: inherit;
}
.top-20 {
  top: 20%;
  bottom: inherit;
}
.left-45 {
  left: 45%;
  right: inherit;
}
.bottom-5 {
  bottom: 5%;
  top: inherit;
}
::selection {
  background-color: #2d1a6a;
  color: #ffffff;
  text-shadow: none;
}
-webkit-::selection {
  background-color: #2d1a6a;
  color: #ffffff;
  text-shadow: none;
}
::-moz-selection {
  background-color: #2d1a6a;
  color: #ffffff;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #2e2e2e;
}
.container-fluid-md {
  padding-left: 15px;
  padding-right: 15px;
}
.row-10 {
  margin-left: -5px;
  margin-right: -5px;
}
.row-10 > [class^="col-"],
.row-10 > [class*=" col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 1280px) {
  .container {
    /* max-width: 1200px; */
  }
  .row-60 {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
  .row-60 > [class^="col-"],
  .row-60 > [class*=" col-"] {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

@media (min-width: 1600px) {
  .container-fluid {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .container-fluid-md {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

.full-height {
  min-height: 100vh;
}
h2,
.h2,
h3,
.h3 {
  font-weight: bold;
}
h4,
.h4 {
  margin-bottom: 1.75rem;
  letter-spacing: -0.035em;
  font-weight: bold;
}
.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}
.text-underline {
  text-decoration: underline;
}
.font-custom {
  font-family: "Brandon", Serif;
}
.font-teko {
  font-family: "Brandon", sans-serif;
}
.font-libre {
  font-family: "Brandon", serif;
}
.text-blue {
  color: #17355d;
}
.font-abril {
  font-family: "Brandon", cursive;
  font-weight: normal;
}
.base-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.base-list > li {
  padding-left: 1rem;
  position: relative;
  margin-bottom: 1.25rem;
}
.base-list > li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0.7em;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 5rem;
  background: #000000;
}
.more {
  text-transform: uppercase;
}
.more-lg {
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: bold;
}
.more-xl {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
}
.more a,
.more-lg a {
  color: #000000;
  text-decoration: none;
}
.more a:hover,
.more-lg a:hover,
.more-total a:hover {
  color: #02a6fd;
}
.more-lg i {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  font-size: 110%;
  line-height: 0.75;
}
.ion-ios-arrow-right:before {
  content: "\f3d3";
}
.ion-ios-arrow-thin-right:before {
  content: "\f3d6";
}
.ion-ios-arrow-thin-right {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}
a:hover .ion-ios-arrow-thin-right,
button:hover .ion-ios-arrow-right,
.btn:hover .ion-ios-arrow-right {
  transform: translateX(0.25rem);
}
.ion-ios-arrow-left,
.ion-ios-arrow-right {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}
a:hover .ion-ios-arrow-right,
button:hover .ion-ios-arrow-right,
.btn:hover .ion-ios-arrow-right {
  transform: translateX(0.25rem);
}
a:hover .ion-ios-arrow-left,
button:hover .ion-ios-arrow-left,
.btn:hover .ion-ios-arrow-left {
  transform: translateX(-0.25rem);
}
html > body .mr20 {
  margin-right: 1.25rem;
}
html > body .mt5 {
  margin-top: 5px;
}
html > body .mt10 {
  margin-top: 0.625rem;
}
html > body .mt20 {
  margin-top: 1.25rem;
}
html > body .mt30 {
  margin-top: 1.875rem;
}
html > body .mt40 {
  margin-top: 2.5rem;
}
html > body .mt50 {
  margin-top: 3.125rem;
}
html > body .mt55 {
  margin-top: 3.4375rem;
}
html > body .mt60 {
  margin-top: 3.75rem;
}
html > body .mt65 {
  margin-top: 4.0625rem;
}
html > body .mt70 {
  margin-top: 4.375rem;
}
html > body .mt80 {
  margin-top: 5rem;
}
html > body .mt90 {
  margin-top: 5.625rem;
}
html > body .mt100 {
  margin-top: 6.5rem;
}
html > body .mt150 {
  margin-top: 9.375rem;
}
html > body .mb5 {
  margin-bottom: 5px;
}
html > body .mb10 {
  margin-bottom: 0.625rem;
}
html > body .mb20 {
  margin-bottom: 1.25rem;
}
html > body .mb30 {
  margin-bottom: 1.875rem;
}
html > body .mb35 {
  margin-bottom: 2.1875rem;
}
html > body .mb40 {
  margin-bottom: 2.5rem;
}
html > body .mb45 {
  margin-bottom: 2.8125rem;
}
html > body .mb50 {
  margin-bottom: 3.125rem;
}
html > body .mb55 {
  margin-bottom: 3.4375rem;
}
html > body .mb60 {
  margin-bottom: 3.75rem;
}
html > body .mb65 {
  margin-bottom: 4.0625rem;
}
html > body .mb70 {
  margin-bottom: 4.375rem;
}
html > body .mb75 {
  margin-bottom: 4.6875rem;
}
html > body .mb80 {
  margin-bottom: 5rem;
}
html > body .mb90 {
  margin-bottom: 5.625rem;
}
html > body .mb100 {
  margin-bottom: 6.25rem;
}
html > body .mb120 {
  margin-bottom: 7.5rem;
}
html > body .mb130 {
  margin-bottom: 8.125rem;
}
button:focus,
input:focus {
  outline: none !important;
}
/* video {
  all: initial;
} */
.btn {
  padding: 0.75rem 2rem 0.75rem;
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 0;
  outline: none;
}
.btn .btn:focus,
.rmbtn:focus,
.btn-primary {
  outline: none !important;
  border: none !important;
}
.btn-sm {
  padding: 0.875rem 1.5rem 0.8125rem;
}
.btn-md {
  padding: 0.875rem 2rem 0.9375rem;
}
.btn-xs {
  border-color: #cccccc;
  padding: 3px 12px 3px;
  font-size: 0.75rem;
  color: #a7a9b8;
  letter-spacing: 0.025em;
}
.btn-light {
  background: #ffffff;
  border-color: #999999;
}
.btn-dark {
  background: #000000;
  border-color: #000000;
}
.btn-dark:hover,
.btn-dark:not(:disabled):not(.disabled):active {
  background: #23272b;
  border-color: #23272b;
}
.btn .icon {
  margin-left: 0.75rem;
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.375rem;
  margin-bottom: -0.25rem;
  line-height: 1;
  font-size: 2rem;
}
.btn-sm .icon {
  font-size: 1.125rem;
  line-height: 0.75;
}
.btn-sm .ion-ios-email-outline {
  font-size: 1.5rem;
}
.btn-light:hover,
.btn-light:not(:disabled):not(.disabled):active {
  background-color: #02a6fd;
  border-color: #02a6fd;
  color: #ffffff;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #ffffff;
  color: #02a6fd;
}
.btn-danger {
  background: #02a6fd;
  border-color: #02a6fd;
}
.btn-rounded {
  border-radius: 30px;
}
.btn-link.text-black:hover {
  color: #000000;
}
.form-control {
  border-radius: 0;
  padding: 0.625rem 1.25rem 0.625rem;
  height: calc(1.5em + 1.25rem + 2px);
}

.form-control-lg {
  padding: 0.875rem 1.25rem 0.9375rem;
  height: calc(1.5em + 1.8125rem + 2px);
  font-size: 1rem;
}
.control-with-icon {
  position: relative;
}
.control-with-icon .form-control {
  padding-right: 3rem;
}
.control-icon {
  padding-top: 1px;
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 1.5rem;
  color: #999999;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.control-icon i,
.control-icon i:before {
  display: block;
  line-height: 0.75;
}
.control-btn {
  margin-top: 2.75rem;
}
.label-custom {
  margin-bottom: 0;
  display: block;
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: bold;
}
/* .form-control:focus {
  border-bottom: 1px solid white;
}

.form-control-custom:focus {
  border-bottom: 1px solid white;
} */

.form-control-custom {
  font-size: 16px;
  border: none;
  border-bottom: #fff 1px solid;
  padding: 0 0 0.625rem;
  height: calc(1.5em + 0.625rem + 1px);
  color: #fff;
  font-weight: normal;
  background: none !important;
}
.form-control-custom::-webkit-input-placeholder {
  color: #fff;
}
.form-control-custom::-moz-placeholder {
  color: #fff;
}
.form-control-custom:-ms-input-placeholder {
  color: #fff;
}
.form-control-custom::-ms-input-placeholder {
  color: #fff;
}
.form-control-custom::placeholder {
  color: #fff;
}
.form-control-custom:focus {
  border-color: #fff;
}
.preloader {
  position: fixed;
  overflow: hidden;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: grey;
  text-align: center;
}
.form-control {
  font-size: 1rem;
  color: #fff !important;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #02a6fd;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.header {
  padding-top: 1.5rem;
  transition: margin-top 0.5s ease-in-out;
  margin-top: 0;
}
.header.shadow {
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

@media (min-width: 768px) {
  .header {
    padding-top: 1rem;
  }
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 900;
}
.header-fixedwhite {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  background: #ffffff;
}
.header-affix {
  position: fixed !important;
  top: -5rem !important;
  left: 0;
  width: 100%;
  z-index: 900;
  background: #ffffff;
  padding-top: 0.75rem !important;
  padding-bottom: 0.5rem !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 15px;
  margin-top: 5rem;
}
.header-affix .nav-logo {
  transform: scale(0.45);
}

@media (max-width: 767px) {
  .header-affix .nav-logo {
    margin-left: -50%;
  }
}

.menu .menu-lang,
.menu .menu-main,
.menu .social,
.menu .menu-copyright {
  opacity: 0;
  transform: translateY(3rem);
  transition: all 0.5s ease-in-out;
}
.menu .menu-lang {
  transition-delay: 0.2s;
}
.menu .menu-main {
  transition-delay: 0.4s;
}
.menu .social {
  transition-delay: 0.6s;
}
.menu .menu-copyright {
  transition-delay: 0.8s;
}
.body-menu-opened .menu .menu-lang,
.body-menu-opened .menu .menu-main,
.body-menu-opened .menu .social,
.body-menu-opened .menu .menu-copyright {
  opacity: 1;
  transform: translateY(0);
}
.brand {
  float: left;
  margin-left: 2rem;
  margin-top: 1rem;
  white-space: nowrap;
}
.brand a {
  color: inherit;
  text-decoration: none;
}
.brand-name,
.brand-text {
  display: inline-block;
  vertical-align: middle;
}
.brand-name {
  margin-right: 0.875rem;
  font-family: "Brandon", Serif;
  font-size: 2.875rem;
  font-weight: 900;
  line-height: 1.1;
}
.brand-text {
  margin-bottom: 0.375rem;
  font-size: 1.125rem;
  line-height: 1.15;
  letter-spacing: -0.5px;
}
.header-content {
  margin-left: 17.5%;
  margin-right: 17.5%;
}
.header-contacts {
  padding-top: 0.5rem;
  font-weight: bold;
}
.header-contact-item,
.header-contact-divider {
  display: inline-block;
  vertical-align: middle;
}
.header-contact-divider {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  opacity: 0.2;
}
.phone-link,
.phone-link:hover,
.mail-link,
.mail-link:hover {
  color: inherit;
  text-decoration: none;
}
.phone-link {
  cursor: default;
}
.mp0 {
  margin: 0px;
  padding: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.nav-toggle-btn {
  background: none;
  border: none;
  padding: 10px;
  margin: 0px;
  border-radius: 0;
  outline: none;
  float: right;
  position: relative;
  z-index: 995;
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
  background: #17355d;
}
.nav-toggle-btn:hover {
  color: #02a6fd;
}
.nav-toggle-btn:focus {
  outline: none;
}
.nav-toggle-title {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
}
.nav-toggle {
  width: 29px;
  height: 29px;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
.nav-toggle-sm {
  transform: scale(0.65);
  margin-right: -0.3125rem;
}
.stick {
  width: 29px;
  height: 3px;
  margin-bottom: 5px;
  background-color: #fff;
  display: inline-block;
}
.stickw {
  width: 29px;
  height: 3px;
  margin-bottom: 5px;
  background-color: #ffffff;
  display: inline-block;
}
.nav-toggle-btn:hover .stickw {
  background-color: #02a6fd;
}
.nav-toggle-btn:hover .stick {
  background-color: #02a6fd;
}
.body-menu-opened .nav-toggle-btn {
  color: #000000;
}
.body-menu-opened .nav-toggle-btn .stick {
  background-color: #ffffff;
}
.stick:last-child {
  margin-bottom: 0px;
}
.nav-toggle:hover .stick-2 {
  width: 29px;
}
.body-menu-opened .stick-1 {
  animation: stick-1-open 0.85s ease-out forwards;
}
.body-menu-opened .stick-2 {
  animation: stick-2-open 0.85s linear forwards;
  width: 29px;
  margin-left: 0px;
}
.body-menu-opened .stick-3 {
  animation: stick-3-open 0.85s linear forwards;
}

@-webkit-keyframes stick-1-open {
  0% {
    width: 29px;
  }
  30% {
    width: 3px;
    transform: translate(14px, 0px);
  }
  65% {
    width: 3px;
    transform: translate(14px, -29px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  100% {
    width: 2px;
    transform: translate(9px, 17px);
  }
}

@keyframes stick-1-open {
  0% {
    width: 29px;
  }
  30% {
    width: 3px;
    transform: translate(14px, 0px);
  }
  65% {
    width: 3px;
    transform: translate(14px, -29px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  100% {
    width: 2px;
    transform: translate(9px, 17px);
  }
}

@-webkit-keyframes stick-2-open {
  65% {
    width: 29px;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    width: 29px;
    transform: translate(0px, 0px) rotate(45deg);
  }
}

@keyframes stick-2-open {
  65% {
    width: 29px;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    width: 29px;
    transform: translate(0px, 0px) rotate(45deg);
  }
}

@-webkit-keyframes stick-3-open {
  65% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, -8px) rotate(-45deg);
  }
}

@keyframes stick-3-open {
  65% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, -8px) rotate(-45deg);
  }
}

.body-menu-close .stick-1 {
  width: 3px;
  animation: stick-1-close 0.85s ease-out forwards;
}
.body-menu-close .stick-2 {
  animation: stick-2-close 0.85s ease-out forwards;
  margin-left: 0px;
}
.body-menu-close .stick-3 {
  animation: stick-3-close 0.85s ease-out forwards;
}

@-webkit-keyframes stick-1-close {
  0%,
  70% {
    width: 0px;
    transform: translate(0, 0);
  }
  100% {
    width: 29px;
    transform: translate(0, 0);
  }
}

@keyframes stick-1-close {
  0%,
  70% {
    width: 0px;
    transform: translate(0, 0);
  }
  100% {
    width: 29px;
    transform: translate(0, 0);
  }
}

@-webkit-keyframes stick-2-close {
  0% {
    width: 29px;
    transform: translate(0px, 0px) rotate(45deg);
  }
  20% {
    width: 3px;
    transform: translate(0, 0px) rotate(45deg);
  }
  40% {
    width: 0px;
  }
  65% {
    transform: translate(0, -26px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  80% {
    width: 0px;
  }
  100% {
    width: 29px;
    transform: translate(0, 0px);
  }
}

@keyframes stick-2-close {
  0% {
    width: 29px;
    transform: translate(0px, 0px) rotate(45deg);
  }
  20% {
    width: 3px;
    transform: translate(0, 0px) rotate(45deg);
  }
  40% {
    width: 0px;
  }
  65% {
    transform: translate(0, -26px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  80% {
    width: 0px;
  }
  100% {
    width: 29px;
    transform: translate(0, 0px);
  }
}

@-webkit-keyframes stick-3-close {
  0% {
    width: 29px;
    transform: translate(0px, -8px) rotate(-45deg);
  }
  20% {
    width: 3px;
    transform: translate(0, -8px) rotate(-45deg);
  }
  40% {
  }
  65% {
    transform: translate(0, -35px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  90% {
    width: 3px;
  }
  100% {
    width: 29px;
    transform: translate(0, 0px);
  }
}

@keyframes stick-3-close {
  0% {
    width: 29px;
    transform: translate(0px, -8px) rotate(-45deg);
  }
  20% {
    width: 3px;
    transform: translate(0, -8px) rotate(-45deg);
  }
  40% {
  }
  65% {
    transform: translate(0, -35px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  90% {
    width: 3px;
  }
  100% {
    width: 29px;
    transform: translate(0, 0px);
  }
}

@-webkit-keyframes fadeZoom {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes fadeZoom {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.navbar-nav {
  margin-left: 8rem;
  margin-right: 8rem;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
}
.navbar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navbar-nav > ul > li {
  display: inline-block;
  vertical-align: middle;
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar-nav a {
  position: relative;
  color: #999999;
  text-decoration: none;
  transition: color 0.3s cubic-bezier(0.82, 0, 0.21, 1);
}
.navbar-nav a:before {
  content: attr(data-text);
  position: absolute;
  width: 0%;
  transition: width cubic-bezier(0.82, 0, 0.21, 1) 0.3s;
  color: #02a6fd;
  z-index: 2;
  overflow: hidden;
}
.navbar-nav li.active > a,
.navbar-nav a:hover {
  color: #999999;
  font-weight: normal;
}
.navbar-nav li.active > a:before,
.navbar-nav a:hover:before {
  width: 100%;
  font-weight: normal;
}
.navbar-nav li.active > a {
  font-weight: normal;
  color: transparent !important;
}
.navbar-nav .dropdown-toggle::after {
  display: none;
}
.navbar-nav .dropdown-menu {
  width: 25rem;
  padding: 27px 40px 22px;
  border: none;
  background: #ffffff;
  border-radius: 0;
  margin-top: 1.125rem;
  margin-left: -40px;
  text-transform: none;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 20px !important;
  top: 100% !important;
  transform: translate3d(0, 0, 0) !important;
}
.navbar-nav .dropdown:hover .dropdown-menu {
  animation: transform 0.3s ease-in-out forwards;
}
.navbar-nav .dropdown-menu:before {
  content: "";
  display: block;
  width: 100%;
  height: 1.125rem;
  position: absolute;
  left: 0;
  right: 0;
  top: -1.125rem;
}

@keyframes transform {
  0% {
    margin-top: 3rem;
    opacity: 0;
  }
  100% {
    margin-top: 1.125rem;
    opacity: 1;
  }
}

.navbar-nav .dropdown-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navbar-nav .dropdown-menu ul li {
  margin-bottom: 10px;
}
.navbar-nav .dropdown-menu a {
  white-space: nowrap;
  color: #999999;
}
.navbar-nav .dropdown-menu .active a {
  font-weight: normal;
}
.dropdown-menu-title {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
}
.menu {
  position: fixed;
  font-family: "Brandon";
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
  background: #17355d;
  max-width: 100%;
  width: 100%;
  padding: 5rem 7rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  transition: transform 0.4s ease-in-out;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.menu .form-control {
  display: block;
  width: 70%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f7f7f8;
  background: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2rem;
  margin-left: 20%;
  margin-bottom: 3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.body-menu-opened .menu {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

@media (min-width: 576px) {
  .menu {
    width: 35rem;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .navbar-nav > ul > li {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.body-menu-opened body {
}

@media (max-width: 575px) {
  .body-menu-opened {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.hide-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 980;
}
.body-menu-opened .hide-menu {
  bottom: 0;
}
.menu-lang {
  font-size: 0.875rem;
  font-weight: normal;
  text-transform: capitalize;
  text-align: right;
}
.menu .menu-lang {
  padding-bottom: 1rem;
  padding-top: 0rem;
  margin-right: 3rem;
}
.menu-lang.float-right,
.text-right .menu-lang {
  margin-right: -1rem;
}
.menu-lang-item,
.menu .menu-lang-item {
  margin-right: 1rem;
  color: #ffffff;
}
.menu-lang-item,
.menu .menu-lang-itemlast {
  margin-right: 0rem;
}
.menu-lang-item:hover {
  color: #02a6fd;
  text-decoration: none;
}
.menu-lang-item.active {
  color: #02a6fd;
}
.menu-lang .divider {
  margin-right: 1rem;
}
.menu-main {
  height: 100%;
  /* overflow-x: hidden;
  overflow-y: auto; */
}
.menu-main > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-main > ul > li {
  margin-bottom: 0.5rem;
  text-align: right;
  margin-right: 10%;
}
.menu-main > ul > li > a:after {
  content: "+";
  padding-left: 15px;
}
.menu-main li.active > a {
  font-weight: normal;
  color: #02a6fd !important;
}
.menu-main li.active > a:before {
  font-weight: normal;
}
.menu-main > ul > li.show > a:before {
  content: "-";
}
.menu-main > ul > li.active {
  content: "-";
  padding-left: 15px;
}
.menu-main li:active {
  color: #02a6fd !important;
}
.menu-main li.expand {
  color: #02a6fd;
}
.menu-main > ul > li > a,
.menu-main > ul > li > span {
  font-size: 1.1rem;
  font-weight: normal;
  color: #fff;
  text-align: right;
  text-transform: capitalize;
}
.menu-main > ul > li ul {
  padding: 0.2rem 0 1px 0.875rem;
  margin: 0;
  list-style: none;
}
.menu-main > ul > li ul > li {
  margin-bottom: 0.375rem;
  padding-right: 30px;
}
.menu-main > ul > li ul > li > a,
.menu-main > ul > li ul > li > span {
  color: #fff;
  font-weight: normal;
}
.menu-main a,
.menu-main a:hover {
  position: relative;
  text-decoration: none;
}
.menu-main a:before {
  content: attr(data-text);
  position: absolute;
  width: 0%;
  transition: width cubic-bezier(0.82, 0, 0.21, 1) 0.3s;
  color: #02a6fd;
  z-index: 2;
  overflow: hidden;
  white-space: nowrap;
}
.menu-main li.active > a:before,
.menu-main a:hover:before {
  width: 100%;
  font-weight: normal;
}
.menu-footer {
  padding-top: 2rem;
  margin-right: 2.8rem;
}

@media (max-height: 380px) {
  .menu-footer {
    display: none;
  }
}
.margintop {
  margin-top: -2.3125rem;
}
.header-custom {
  padding-top: 3rem;
}
.header-absolute {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 900;
}
.nav-logo {
  margin-top: -2.3125rem;
  margin-bottom: -2.3125rem;
  display: block;
}
.header-custom .social,
.header-custom .menu-lang {
  margin-top: 0.5rem;
}
.header-custom .social a {
  color: #999999;
}
.header-custom .navbar-nav a {
  color: #000000;
}
.header-custom .menu-lang {
  font-size: 1rem;
  font-weight: normal;
  color: #cccccc;
}
.header-custom .menu-lang a {
  color: #999999;
}
.header-custom .menu-lang a.active {
  color: #000000;
}
.header-custom .menu-lang a:hover {
  color: #02a6fd;
}

@media (min-width: 768px) {
  .header-custom {
    padding-top: 5rem;
  }
}

.promo-zoom {
  position: relative;
  z-index: 100;
}
/* .mountains {position:fixed; height:100%; top:0; left:0; right:0; z-index:10; background:url("../img/bg-promo-zoom-mountains2.jpg") 50% 100% no-repeat; background-size:cover;} */
.mountains.animated {
}
/* .house-left {position:fixed; width:28.958333%; height:59.16666666%; bottom:0; left:0; z-index:20; background:url("../img/bg-promo-zoom-house-left.png") 0 100% no-repeat; background-size:contain;} */
/* .house-right {position:fixed; width:62.76041667%; height:60%; bottom:0; right:0; z-index:30; background:url("../img/bg-promo-zoom-house-right.png") 100% 100% no-repeat; background-size:contain;} */
.step-opacity {
  position: absolute;
  left: 0;
  top: 85%;
}
.promo-zoom-titles {
  position: fixed;
  top: 30%;
  left: 10%;
  right: 10%;
  z-index: 40;
  text-align: center;
}
.top-title {
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  text-transform: uppercase;
  letter-spacing: 0.5625rem;
  color: #666666;
}
.promo-zoom-titles h1,
.promo-screen-titles h1 {
  font-size: 11.11111111vmin;
  font-weight: bold;
}
/* .text-texture {display:inline-block; background-image:url("../img/bg-title.jpg"); background-position:50% 50%; -webkit-background-clip:text; -webkit-text-fill-color:transparent;} */
.text-texture > .inside {
  display: inline-block;
  mix-blend-mode: lighten;
  background: #ffffff;
}
.promo-zoom-titles .slogan,
.promo-screen-titles .slogan {
  margin-top: 1.125rem;
  font-size: 1.25rem;
  color: #666666;
  line-height: 1.5;
}
.brand .text-texture {
  background-image: linear-gradient(to bottom, #ff7c00, #02a6fd);
  display: inline-block;
  vertical-align: middle;
  width: 4.75rem;
  height: 3.125rem;
}
.section {
  padding-top: 6rem;
  padding-bottom: 4rem;
}
.section-content {
  padding-top: 6rem;
}
.section-item {
  text-align: center;
}
.section-item + .section-item {
  margin-top: 4rem;
}
.page-content {
  background: #ffffff;
  padding-top: 12%;
  position: relative;
  z-index: 100;
}

@media (min-width: 768px) {
  .page-content {
    padding-top: 8.75rem;
  }
}

.section-mini {
  padding-top: 6rem;
}
.section-centered {
  display: flex;
  align-items: center;
}
.section-centered > div {
  width: 100%;
}
.full-image,
.full-image-dark {
  min-height: 100vh;
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}
.full-image-dark:before {
  content: "";
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.3;
}
.full-image-dark > div {
  z-index: 100;
  position: relative;
}

@media (min-width: 768px) {
  .section-item + .section-item {
    margin-top: 8.125rem;
  }
  .section {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .section-content {
    padding-bottom: 8.125rem;
  }
  .section-mini {
    padding-top: 8.125rem;
  }
}

.section-last {
  padding-bottom: 0;
}
.section-item-title {
  margin-bottom: 1.875rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.375rem;
  line-height: 1.5;
}
.section-item-title,
.section-item-title a {
  color: #02a6fd;
}
.section-item-title-sm {
  font-size: 1.125rem;
  letter-spacing: 0.5em;
}
.section-item-title-md {
  font-size: 1.25rem;
  letter-spacing: 0.25em;
}
.promo-about {
  padding-top: 9rem;
  padding-bottom: 6rem;
  line-height: calc(100vh - 15rem);
}
.promo-about .about {
  position: relative;
  top: 300px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .promo-about {
    padding-top: 3rem;
    padding-bottom: 3rem;
    line-height: calc(100vh - 6rem);
  }
}

.about {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  line-height: 1.5;
}
.rounded-logo {
  width: 11.5rem;
  height: 11.5rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.rounded-logo svg {
  transform: rotate(175deg);
  text-transform: uppercase;
  letter-spacing: 2.65px;
  word-spacing: 4px;
  font-weight: bold;
  fill: #999999;
}
.rounded-logo .logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: 0.625rem;
  text-align: center;
  font-size: 4rem;
  font-weight: 900;
  font-family: "Brandon", Serif;
  color: #000000;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.rounded-logo-sm {
  width: 7.125rem;
  height: 7.125rem;
}
.rounded-logo-sm .logo {
  margin-top: 0.25rem;
  font-size: 2.5rem;
}
.rounded-logo-md {
  width: 14.125rem;
  height: 14.125rem;
}
.rounded-logo-md .logo {
  font-size: 4.8125rem;
}
.rounded-logo-lg {
  width: 17rem;
  height: 17rem;
}
.rounded-logo-lg .logo {
  font-size: 6.125rem;
}
.rounded-logo.stamp {
  width: 14.125rem;
  height: 14.125rem;
  position: absolute;
  left: 0;
  bottom: -3rem;
}
.rounded-logo.stamp svg {
  transform: rotate(150deg);
}
.rounded-logo.font-custom svg {
  letter-spacing: 0.1em;
  word-spacing: 2px;
}
.about-logo {
  margin-bottom: 6.25rem;
}
.about-title {
  font-size: 2.25rem;
  font-weight: bold;
}
.about-descr {
  margin-top: 1.875rem;
  font-size: 1.5rem;
  line-height: 1.5;
}
.services-descr,
.flash-descr {
  font-size: 1rem;
  line-height: 1.875;
}
.service-item {
  margin-top: 4.375rem;
  display: block;
}
a.service-item {
  text-decoration: none;
  color: #000000;
}
.services-letter {
  overflow: hidden;
  position: relative;
  font-size: 13.75rem;
  font-weight: 900;
  line-height: 1.25;
}
.services-letter .mask {
  position: absolute;
  z-index: 10;
  width: 200%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1)
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1)
  );
  height: 100%;
  top: 0;
  left: 100%;
}
/* .letter-g .text-texture {background-image:url("../img/bg-letter-g.jpg"); background-position:50% 30%;} */
/* .letter-w .text-texture {background-image:url("../img/bg-letter-w.jpg"); background-position:50% 30%;} */
/* .letter-a .text-texture {background-image:url("../img/bg-letter-a.jpg"); background-position:50% 30%;} */
.service-item-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.service-item-title a,
a .service-item-title {
  color: #000000;
  text-decoration: none;
}
.service-item-title a:hover,
a:hover .service-item-title {
  color: #02a6fd;
}
.service-item-descr {
  margin-top: 1.625rem;
  line-height: 1.625;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.services-3d .row > div {
  display: flex;
}
.services-3d .service-item {
  position: relative;
  padding: 2.875rem 1.75rem 10.3125rem;
  margin-top: 2.5rem;
  overflow: hidden;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
.services-3d .service-item:hover {
  background-color: #f3f4f6;
}
.services-3d .service-item .services-letter {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.services-3d .service-item > * {
  position: relative;
  z-index: 200;
}
.services-3d .service-item > div.service-item-photo {
  position: absolute;
  z-index: 100;
}
.services-3d .service-item-photo {
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transform: translate(0, -3rem);
  transform: translate(0, -3rem);
  transition: all 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .services-3d .service-item:hover .service-item-photo {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .services-3d .service-item:hover .services-letter {
    opacity: 0;
  }
}

.services-3d .service-item .btn {
  position: absolute;
  bottom: 4.375rem;
  left: 50%;
  white-space: nowrap;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.services-3d .service-item-title a:hover,
.services-3d a:hover .service-item-title {
  color: #000000;
}
.projects {
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .projects {
    padding-bottom: 10.9375rem;
  }
}

.projects .section-item-title {
  margin-bottom: 5.625rem;
}
.carousel-container {
  position: relative;
  margin-left: -3.2rem;
  margin-right: -3.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  overflow: hidden;
}
.carousel-container .mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 100;
  width: 100%;
  background: #ffffff;
  animation-fill-mode: forwards;
}
.project-carousel-item {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  position: relative;
  text-align: left;
}
.project-carousel-photo,
.project-listing-photo,
.project-metro-photo {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: solid 1px #6b7a8e;
}
.project-metro-photo {
  z-index: 300;
}
.project-list-listing a,
.project-list-masonry a {
  color: #000000;
  text-decoration: none;
}
.project-list-listing a:hover .project-listing-title,
.project-list-masonry a:hover .project-masonry-title {
  color: #02a6fd;
}
.project-listing-detail {
  margin-top: 2.25rem;
}
.project-listing-title {
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}
.project-metro-title {
  font-size: 3rem;
  font-weight: normal;
}
.project-masonry-detail {
  margin-top: 24px;
}
.project-masonry-title {
  font-size: 1.875rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}
.project-parallax-detail {
  margin-bottom: 46px;
  font-size: 1.125rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.project-piling-title {
  font-size: 9.25925926vmin;
  line-height: 1;
  letter-spacing: -0.025em;
  font-weight: normal;
}
.project-piling-title a:hover {
  text-decoration: underline !important;
}
.project-list-piling .footer {
  background: #2c1a6a;
}
.project-carousel-detail,
.project-metro-detail,
.project-carousel-date,
.project-metro-date {
  position: absolute;
  z-index: 20;
  left: 2.5rem;
  right: 2rem;
  bottom: 5rem;
  color: #ffffff;
}
.project-carousel-date,
.project-metro-date {
  bottom: auto;
  top: 2rem;
}
.project-carousel-detail {
  opacity: 0;
  transition: opacity 0.5s 0.5s ease-in-out;
}
.owl-item.active .project-carousel-detail {
  animation: fadeInDown 0.5s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 1;
}
.project-carousel-title {
  margin-top: 0.875rem;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}
.project-carousel-more {
  position: absolute;
  opacity: 0;
  z-index: 30;
  left: 2.5rem;
  bottom: 1.5rem;
  transition: opacity 0.5s 1.1s ease-in-out;
}
.owl-item.active .project-carousel-more {
  animation: fadeInUp 0.35s ease-out forwards;
  animation-delay: 1.1s;
  opacity: 1;
}
.project .owl-theme .owl-nav {
  margin-top: 0;
}
.more-total {
  margin-top: 4.375rem;
  font-weight: bold;
  text-transform: uppercase;
}
.more-total a {
  color: #000000;
  text-decoration: underline;
}
.more-total a:hover {
  text-decoration: none;
}
.more-total-lg {
  margin-top: 8.125rem;
}
.owl-theme .owl-nav [class*="owl-"] {
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.owl-theme .owl-nav [class*="owl-"].disabled {
  cursor: default;
}
.owl-theme .owl-nav [class*="owl-"],
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none;
  background-color: transparent;
}
.owl-prev {
  left: -3rem;
}
.owl-next {
  right: -3rem;
}
.nav-inside.owl-theme .owl-nav {
  margin-top: 0;
}
.nav-inside .owl-prev {
  left: 15px;
}
.nav-inside .owl-next {
  right: 15px;
}
.nav-square.owl-theme .owl-nav [class*="owl-"] {
  width: 3.75rem;
  height: 3.75rem;
  border: #333333 1px solid;
  background: transparent;
  margin: 0;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
}
.nav-square.owl-theme .owl-nav [class*="owl-"]:hover:not(.disabled) {
  background: #333333;
}
.nav-square .arrow {
  position: relative;
  width: 0.875rem;
  height: 1.5rem;
}
.nav-square .arrow-top,
.nav-square .arrow-bottom {
  background-color: #333333;
}
.nav-square .arrow-top:after,
.nav-square .arrow-bottom:after {
  background-color: #ffffff;
}
.nav-white-red.owl-theme .owl-nav [class*="owl-"] {
  border-color: #ffffff;
  background: #ffffff;
}
.nav-white-red.owl-theme .owl-nav [class*="owl-"]:hover:not(.disabled) {
  background: #02a6fd;
  border-color: #02a6fd;
}
.nav-white-orange.owl-theme .owl-nav [class*="owl-"] {
  border-color: #ffffff;
}
.nav-white-orange.owl-theme .owl-nav [class*="owl-"]:hover:not(.disabled) {
  background: #02a6fd;
  border-color: #02a6fd;
}
.nav-white-orange .arrow-top,
.nav-white-orange .arrow-bottom {
  background-color: #ffffff;
}

@media (min-width: 1600px) {
  .nav-inside .owl-prev {
    left: 3.75rem;
  }
  .nav-inside .owl-next {
    right: 3.75rem;
  }
}

.arrow {
  height: 3.125rem;
  width: 1.875rem;
  margin-left: auto;
  margin-right: auto;
}
.arrow-top,
.arrow-bottom {
  background-color: #cccccc;
  height: 2px;
  left: -10%;
  position: absolute;
  top: 50%;
  width: 100%;
}
.arrow-top:after,
.arrow-bottom:after {
  background-color: #02a6fd;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}
.arrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}
.arrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}
.arrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}
.arrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}
.owl-prev:not(.disabled):hover .arrow-top:after,
.owl-next:not(.disabled):hover .arrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}
.owl-prev:not(.disabled):hover .arrow-bottom:after,
.owl-next:not(.disabled):hover .arrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}
.arrow:active {
  transform: scale(0.9);
}
.owl-prev .arrow {
  transform: scaleX(-1);
}
.owl-prev .arrow:active {
  transform: scaleX(-1) scale(0.9);
}
.dots-md {
  margin-bottom: 16.5rem;
}
.dots-md.owl-theme .owl-dots,
.dots-md.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 5.625rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}
.dots-md.owl-theme .owl-dots .owl-dot {
  vertical-align: middle;
}
.owl-theme .owl-dots .owl-dot:focus {
  outline: none;
}
.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  background: transparent;
  border: transparent 1px solid;
  position: relative;
  margin: 5px;
}
.dots-md.owl-theme .owl-dots .owl-dot span {
  width: 1.5rem;
  height: 1.5rem;
}
.owl-theme .owl-dots .owl-dot:hover span {
  background: transparent;
}
.owl-theme .owl-dots .owl-dot.active span {
  border-color: #02a6fd;
  background-color: transparent;
}
.owl-theme .owl-dots .owl-dot span:before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #000000;
  position: absolute;
  left: 50%;
  margin-left: -1.5px;
  top: 50%;
  margin-top: -1.5px;
}
.dots-md.owl-theme .owl-dots .owl-dot span:before {
  width: 4px;
  height: 4px;
  margin-left: -2px;
  margin-top: -2px;
}
.owl-theme .owl-dots .owl-dot.active span:before,
.owl-theme .owl-dots .owl-dot:hover span:before {
  background-color: #02a6fd;
}
.reviews-carousel-sm.owl-theme .owl-dots .owl-dot span:before {
  background-color: #ffffff;
}
.reviews-carousel-sm.owl-theme .owl-dots .owl-dot.active span:before,
.reviews-carousel-sm.owl-theme .owl-dots .owl-dot:hover span:before {
  background-color: #f4882e;
}
.reviews-carousel-sm.owl-theme .owl-dots .owl-dot.active span {
  border-color: #f4882e;
}

@media (min-width: 1400px) {
  .carousel-container {
    margin-left: -7.5rem;
    margin-right: -7.5rem;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .owl-prev {
    left: -7rem;
  }
  .owl-next {
    right: -7rem;
  }
}

@media (min-width: 768px) {
  .project-carousel-item {
    padding-bottom: 44.44444444%;
  }
  .project-carousel-detail,
  .project-carousel-date,
  .project-metro-date {
    left: 3.75rem;
    right: auto;
    top: auto;
    bottom: 2.625rem;
    max-width: 60%;
    color: #ffffff;
  }
  .project-carousel-date,
  .project-metro-date {
    bottom: auto;
    top: 2.625rem;
  }
  .project-carousel-title {
    font-size: 3rem;
  }
  .project-carousel-more {
    left: auto;
    right: 3.4375rem;
    bottom: 3.25rem;
  }
}

@media (max-width: 767px) {
  .project-carousel-item .btn {
    padding: 7px 12px 7px;
  }
  .owl-prev {
    left: -0.5rem;
  }
  .owl-next {
    right: -0.5rem;
  }
  .carousel-container {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.clients {
  background: url("../img/bg-clients.gif") 50% 0 no-repeat;
  background-size: cover;
}
/* .testimonial {background:url("../img/bg-testimonial.png") 50% 0 no-repeat; background-size:cover;} */
.testimonial .owl-theme .owl-dots,
.testimonial .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 3.75rem;
}
.reviews-carousel-container {
  background: #ffffff;
  padding-top: 2.8125rem;
  padding-bottom: 5.875rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 5rem;
}
.carousel-center-v.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
.reviews-carousel-text {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.33333333;
  letter-spacing: -1px;
}
.testimonial .reviews-carousel-text {
  line-height: 1.66666667;
  letter-spacing: -0.025em;
  font-weight: normal;
}
.reviews-carousel-author {
  margin-top: 3rem;
  font-size: 1.125rem;
}
.reviews-carousel-author:before,
.reviews-carousel-author:after {
  content: "-";
  margin: 0 0.625rem;
  color: #999999;
}
.reviews-carousel-sm .reviews-carousel-item {
  min-height: 22.5rem;
}
.reviews-carousel-sm .reviews-carousel-item > .inside {
  vertical-align: top;
}
.reviews-carousel-sm .reviews-carousel-author {
  text-transform: uppercase;
  font-weight: bold;
}
.reviews-carousel-sm .reviews-carousel-author:before,
.reviews-carousel-sm .reviews-carousel-author:after {
  display: none;
}
.reviews-carousel-author-status {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
}
.home-piling .reviews-carousel-author-status {
  color: #99cccc;
}
.home-piling .reviews-carousel-author-status .text-grey-light {
  color: #669999;
}
.reviews-carousel-sm .owl-dots {
  margin-left: -5px;
  text-align: left;
}

@media (min-width: 992px) {
  .reviews-carousel-text {
    font-size: 2.25rem;
  }
  .reviews-carousel-sm .reviews-carousel-text {
    font-size: 1.875rem;
    letter-spacing: -0.25px;
    line-height: 1.6;
  }
  .reviews-carousel-container {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
}

.partner-list {
  margin-top: 29vmin;
  text-align: center;
}
.partner-item {
  display: flex;
  text-align: center;
  margin: 10px 0px;
}
.partner-item img {
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}
.partner-item img:hover,
.partner-item a:hover img,
.partner-item .inside:hover img {
  opacity: 1;
}
.partner-bordered .partner-item {
  margin: 15px 0px 15px 0;
  min-height: 13.88888889vmin;
}
.partner-item > .inside {
  padding: 0;
  align-self: center;
  display: block;
  width: 100%;
}
.partner-item {
  flex: 33.33333333%;
  max-width: 33.33333333%;
}
.partner-item img {
  max-width: 100%;
}

@media (min-width: 768px) {
  .partner-item {
    flex: 40%;
    max-width: 31%;
  }
}

.article-list {
  margin-top: 6.25rem;
}
.article-list > div {
  display: flex;
}
.article-item {
  width: 100%;
  margin-bottom: 2.5rem;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
}
a.article-item {
  color: #000000;
  text-decoration: none;
}
.article-item-photo {
  margin-bottom: 2rem;
  height: 0;
  padding-bottom: 62.16216216%;
  position: relative;
  overflow: hidden;
}
.article-item-photo img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-item-masonry {
  margin-bottom: 10px;
  display: block;
  position: relative;
}
.article-item-masonry,
.article-item-masonry:hover {
  color: #ffffff;
}
.article-item-masonry .article-item-photo {
  margin-bottom: 0;
  height: auto;
  padding-bottom: 0;
}
.article-item-masonry .article-item-photo img {
  position: relative;
  z-index: 100;
  width: 100%;
  height: auto;
}
.article-item-masonry .article-item-photo:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 150;
  opacity: 0.6;
  background: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0) 70%
  );
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0) 70%
  );
  transition: all 0.3s ease-in-out;
}
.article-item-masonry:hover .article-item-photo:before {
  opacity: 0;
}
.article-list-listing .article-item {
  display: block;
  margin-bottom: 4.625rem;
}
.article-list-listing .article-item-photo {
  padding-bottom: 51.94805195%;
  margin-bottom: 2.125rem;
}
.article-list-listing .article-item-title {
  margin-bottom: 0.375rem;
  font-size: 1.5rem;
  line-height: 1.41666667;
  letter-spacing: -0.025em;
}
.article-item-info {
  color: #999999;
}
.article-item-info a {
  color: #000000;
}
.article-item-info a:hover {
  color: #02a6fd;
  text-decoration: none;
}
.article-item-descr {
  margin-top: 1.375rem;
  line-height: 1.625;
}
.article-item-detail {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: 250;
  padding: 2.5rem;
}
.article-item-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
  background: #000000;
  opacity: 0.1;
  transition: all 0.3s ease-in-out;
}
.article-item-masonry:hover .article-item-mask {
  opacity: 0.5;
}
.article-item-masonry .article-item-title {
  font-size: 1.5rem;
  line-height: 1.41666667;
}
.article-item-masonry:hover .article-item-title {
  text-decoration: underline;
}
.zooming {
  overflow: hidden;
}
.zooming img {
  transform: scale(1);
  transition: all 1s linear;
}
.zooming:hover img,
a:hover .zooming img {
  transform: scale(1.08);
}
.article-item-category {
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.article-item-category,
.article-item-category a {
  color: #02a6fd;
}
.article-item-category-dark {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  background: #333333;
  padding: 1px 8px 0;
  color: #ffffff;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-weight: bold;
}
.article-item-title {
  flex: 1 1 auto;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.36363636;
  letter-spacing: -0.5px;
}
.article-item-title a,
.article-item-title a:hover {
  color: #000000;
}
.article-item-date {
  margin-top: 1.875rem;
}
.article-item-masonry .article-item-date {
  margin-top: 1.125rem;
}
.article-list-mini {
  margin-top: 1.75rem;
}
.article-item-mini {
  margin-bottom: 1.25rem;
}
.article-item-mini .article-item-photo {
  display: block;
  padding-bottom: 71.08433735%;
  margin-top: 0.25rem;
  margin-bottom: 0;
  width: 5.25rem;
}
.article-item-mini .article-item-title {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.42857143;
}
.article-item-mini .article-item-title a:hover {
  text-decoration: none;
}
.article-item-mini .article-item-date {
  margin-top: 0.25rem;
  color: #999999;
  font-size: 0.875rem;
}
.promo-zoom-footer {
  overflow: hidden;
}
.promo-zoom-footer .inside {
  margin-left: -10%;
  margin-right: -10%;
}
.promo-zoom-footer img {
}

@media (min-width: 768px) {
  .promo-zoom-footer .inside {
    margin-left: 0;
    margin-right: 0;
  }
}

.horizontal-nav {
  width: 100%;
  position: fixed;
  z-index: 300;
  bottom: 2vmin;
  left: 0;
}
.horizontal-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.horizontal-nav li {
  float: left;
  height: 2px;
  width: 3rem;
  background: #02a6fd;
  overflow: hidden;
  transition: background 0.5s ease-in-out;
  position: relative;
}
.horizontal-nav li:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #02a6fd;
}
.horizontal-nav li.active:before {
  animation: navWidth 0.5s ease-in-out forwards;
}

@-webkit-keyframes navWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes navWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.horizontal-nav li.active,
.horizontal-nav li.active ~ li {
  background: #cccccc;
}
.horizontal-nav li:before {
  width: 100%;
}
.horizontal-nav li.active ~ li:before {
  width: 0%;
}
.horizontal-controls {
  position: absolute;
  right: 15px;
  bottom: -0.625rem;
}

@media (min-width: 992px) {
  .horizontal-nav li {
    width: 6.25rem;
  }
}

@media (min-width: 1600px) {
  .horizontal-controls {
    right: 3.75rem;
  }
}

.horizontal-controls button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  color: #999999;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}
.horizontal-controls button:hover {
  color: #000000;
}
.horizontal-controls .divider {
  margin-left: 1.625rem;
  margin-right: 1.625rem;
  font-weight: normal;
  color: #cccccc;
}
.home-horizontal .section {
  transition: transform 1.5s ease-in-out;
}
.home-horizontal .active + .section {
  transform: translate3d(30%, 0px, 0px) !important;
}
.home-horizontal .section.active {
  transform: translate3d(0, 0px, 0px);
}
.pp-scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}
.slide {
  background: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 100vh;
}
.slide-container {
  position: relative;
  min-height: 100vh;
  overflow: hidden !important;
  width: 100%;
  padding-top: 9rem;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  .slide-container {
    padding-top: 15vmin;
    padding-bottom: 7vmin;
  }
}

@media (min-width: 1400px) {
  .slide-container {
    /* padding-top: 21.66666667vmin; */
  }
}

.slide-dark {
  background-color: #0c0c0c;
  color: #ffffff;
}
.slide-container .video-container {
  pointer-events: none;
}
.slide-container .video-container:before {
  display: none;
}
.slide-bg,
.slide-bg > .inside {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-position: 70% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.slide-bg {
  overflow: hidden;
}
.home-horizontal .slide1 .slide-bg > .inside {
  background-image: url(../img/bg-horizontal1.jpg);
}
.home-horizontal .slide2 .slide-bg > .inside {
  background-image: url(../img/bg-horizontal2.jpg);
}
/* .home-horizontal .slide3 .slide-bg > .inside {
  background-image: url(../img/bg-horizontal3.jpeg);
} */
.home-horizontal .slide4 .slide-bg > .inside {
  background-image: url(../img/bg-horizontal4.jpg);
}
/* .home-horizontal .slide5 .slide-bg > .inside {background-image:url(../img/bg-horizontal5.jpg);} */
/* .home-horizontal .slide6 .slide-bg > .inside {background-image:url(../img/bg-horizontal6.jpg);} */
/* .home-horizontal .slide7 .slide-bg > .inside {background-image:url(../img/bg-horizontal7.jpg);} */

.slide-container .container {
  z-index: 100;
}
.slide [class^="col-"] {
  position: static;
}
.num {
  font-size: 3.75rem;
  font-size: 5.55555556vmin;
  font-weight: 500;
  line-height: 1;
  font-family: "Brandon", sans-serif;
}
.slide-num {
  margin-bottom: 1.5vmin;
}

@media (min-width: 1400px) {
  .slide-num {
    position: absolute;
    left: 15px;
    top: 22.22222222vmin;
  }
}

@media (min-width: 1600px) {
  .slide-num {
    left: 3.75rem;
  }
}

.slide-content {
  min-height: 50vmin;
  margin-bottom: 3vmin;
}
.slide-video-container {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.home-horizontal h1,
.home-horizontal .h1 {
  margin-left: -0.25rem;
  margin-bottom: 4.07407407vmin;
  font-size: 12.03703704vmin;
  text-transform: uppercase;
  line-height: 0.92307692;
  font-weight: normal;
}
.home-horizontal .slide5 h1,
.home-horizontal .slide5 .h1 {
  font-size: 11.11111111vmin;
}
.home-horizontal h2,
.home-horizontal .h2 {
  font-size: 6.66666667vmin;
  text-transform: uppercase;
  line-height: 0.92307692;
  font-weight: normal;
}
.horizontal-top-title {
  margin-top: 0.925925926vmin;
  margin-bottom: 4.44444444vmin;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 0.92307692;
}
.slide-title-sub {
  margin-bottom: 2.12962963vmin;
  font-size: 1.875rem;
  text-transform: uppercase;
  line-height: 0.92307692;
  letter-spacing: 0.5px;
}
.slide-descr {
  color: #999999;
  line-height: 1.625;
}
.home-horizontal .slide2 .slide-descr {
  line-height: 1.875;
}
.home-horizontal .slide1 .slide-photo {
  position: absolute;
  height: 70.92592593%;
  left: 46.875%;
  right: 0;
  top: 13.24074074%;
  z-index: -1;
}
.home-horizontal .slide1 .slide-photo img {
  height: 100%;
  width: auto;
  max-width: none;
}
.btn-play {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  background: transparent;
  border: #02a6fd 2px solid;
  width: 6.48148148vmin;
  height: 6.48148148vmin;
  min-width: 3rem;
  min-height: 3rem;
  line-height: 2.5rem;
  border-radius: 4.375rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem;
  color: #ffffff;
}
.btn-play:hover {
  background: #02a6fd;
  text-decoration: none;
}
.btn-play .ion-ios-play {
  margin-left: 0.25rem;
}
button.btn-play i,
button.btn-play i:before {
  display: block;
  line-height: 1;
}
a.btn-play i {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.btn-play-lg {
  border: #ffffff 2px solid;
  padding: 0 0 0 0.75rem;
  min-width: 6.875rem;
  min-height: 6.875rem;
  line-height: 6.2rem;
  font-size: 4.5rem;
  color: #ffffff;
}
.btn-play-lg:hover {
  border-color: #02a6fd;
}
.media-title {
  margin-top: 0.55555556vmin;
  margin-bottom: 3rem;
}
.media-title h2,
.media-title .h2 {
  margin-right: 2.375rem;
}

@media (min-width: 992px) {
  .media-title {
    display: flex;
    margin-bottom: 0;
  }
}

.playpause {
  position: absolute;
  /* left: 47.5%;
  top: 47.5%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.playpause i {
  /* display: none; */
  position: relative;
  /* color: white; */
  z-index: 10;
  /* font-size: 20px; */
  /* background: rgba(203, 199, 199, 0.1); */
  padding: 10px;
  border-radius: 50%;
}

.pause i {
  /* display: none; */
  position: relative;
  color: white;
  z-index: 10;
  font-size: 20px;
  background: rgba(203, 199, 199, 0.1);
  padding-top: 10px;
  padding-bottom: 11px;
  padding-left: 12px;
  padding-right: 9px;
  border-radius: 50%;
}

/* .playpause i :hover {
  position: relative;
  color: white;
  z-index: 10;
  font-size: 20px;
  background: white;
  padding-top: 10px;
  padding-bottom: 11px;
  padding-left: 12px;
  padding-right: 9px;
  border-radius: 50%;
} */
.pausevid {
  visibility: hidden;
}
.pausevid:hover {
  visibility: visible;
}

.horizontal-service-list {
  margin-top: 3.33333333vmin;
}
.horizontal-service-list > div {
  display: flex;
}
.horizontal-service-item {
  padding: 3.88888889vmin 3.125rem 5.09259259vmin;
  position: relative;
  margin-top: 4.62962963vmin;
  display: flex;
  flex-direction: column;
}
.horizontal-service-item:before {
  content: "";
  display: block;
  height: 4px;
  width: 0;
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: #02a6fd;
  transition: width 0.3s ease-in-out;
}
.horizontal-service-item:hover:before {
  width: calc(100% + 2px);
}
.horizontal-service-item .num {
  color: #cccccc;
  font-weight: normal;
}
.horizontal-service-item .num,
.horizontal-service-item-photo {
  margin-bottom: 3.51851852vmin;
}
.horizontal-service-item-photo img.photo-position {
  position: absolute;
  top: -3.125rem;
  left: 36.48648649%;
  max-width: 67.83783784%;
  height: auto;
}
.horizontal-service-item-photo.photo3 img.photo-position {
  top: -3.75rem;
}
.horizontal-service-item-title {
  margin-bottom: 2.59259259vmin;
  font-size: 2.25rem;
  font-family: "Brandon", sans-serif;
  text-transform: uppercase;
  line-height: 1.11111111;
}
.horizontal-service-item-descr {
  height: 100%;
  padding-bottom: 5.37037037vmin;
  line-height: 1.625rem;
}
.horizontal-service-item-title,
.horizontal-service-item-descr {
  position: relative;
  z-index: 100;
}
.horizontal-more-total {
  border-bottom: #000000 1px solid;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-decoration: none;
}
.slide-dark a:not(.btn),
.slide-dark a:not(.btn):hover {
  border-bottom-color: #ffffff;
  color: #ffffff;
  text-decoration: none;
}
.slide-dark a.underline {
  text-decoration: underline;
}
.home-horizontal .partner-list {
  margin-top: 6.48148148vmin;
}
.slide-contact-address {
  margin-bottom: 7.03703704vmin;
  font-size: 1.25rem;
  color: #999999;
}
.slide-contact-mail,
.slide-contact-phone {
  margin-bottom: 2.77777778vmin;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.slide5 .slide-title {
  margin-bottom: 0.8125rem;
}
.slide-num {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 1.5s;
}
.home-horizontal .section.active .slide-num {
  opacity: 1;
}
.home-horizontal .slide-title,
.home-horizontal .slide-body {
  opacity: 0;
  transform: translateX(-200px);
  transition: all 0.5s ease-in-out;
}
.home-horizontal .slide-body {
  transition-delay: 0.2s;
}
.home-horizontal .active + .section .slide-title,
.home-horizontal .active + .section .slide-body {
  opacity: 0;
  transform: translateX(400px);
}
.home-horizontal .section.active .slide-title,
.home-horizontal .section.active .slide-body {
  opacity: 1;
  transform: translateX(0);
}
.home-horizontal .active + .section .slide-title,
.home-horizontal .section.active .slide-title {
  transition-delay: 0.7s;
}
.home-horizontal .active + .section .slide-body,
.home-horizontal .section.active .slide-body {
  transition-delay: 0.9s;
}
.home-horizontal .slide-note {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transition-delay: 1.2s;
}
.home-horizontal .section.active .slide-note {
  opacity: 1;
}
.slide-bg > .inside {
  transition: transform 0.8s ease-in-out;
  transform: scale(1.08);
  transition-delay: 0.8s;
}

.pp-tooltip.right {
  top: -25px !important;
  right: -50% !important;
  font-size: 12px !important;
}
.pp-tooltip {
  top: -30px !important;
  right: -140% !important;
  font-size: 10px !important;
}

#pp-nav {
  top: 95% !important;
  left: 45% !important;
}

#pp-nav ul {
  display: flex !important;
  width: fit-content;
  background-color: #3fa9f5;
  border-radius: 10px;
}
#pp-nav ul li span {
  border-color: #fff !important;
  /* border-color: rgb(23 53 93) !important; */
}
.section.active .slide-bg > .inside {
  transform: scale(1);
}
.dark-horizontal .header,
.dark-horizontal .social-fixed a,
.dark-horizontal .establised,
.dark-horizontal .copyright-fixed,
.dark-horizontal .social-rounded-fixed a,
.dark-horizontal .navbar-nav > ul > li > a {
  color: #ffffff;
}
.dark-horizontal .stick,
.dark-horizontal #pp-nav span,
.dark-horizontal .pp-slidesNav span {
  background: #ffffff;
}
.dark-horizontal .horizontal-nav li.active,
.dark-horizontal .horizontal-nav li.active ~ li {
  background: #333333;
}
.dark-horizontal .horizontal-controls button {
  color: #666666;
}
.dark-horizontal .horizontal-controls button:hover {
  color: #ffffff;
}
.dark-horizontal .social-rounded-fixed a {
  border-color: #ffffff;
}
.home-piling .section,
.project-list-piling .section {
  transition: transform 1.2s ease-in-out;
  position: relative;
  z-index: 10;
}
.a-pagepiling.home-piling .section,
.a-pagepiling.project-list-piling .section {
  position: absolute;
}
.project-list-piling.a-pagepiling .section {
  position: absolute;
}
.home-piling .active + .section,
.project-list-piling .active + .section {
  transform: translate3d(0px, 0px, 0px) !important;
}
.home-piling .section.active,
.project-list-piling .section.active {
  transform: translate3d(0px, 0px, 0px);
}

@media (max-width: 575px) {
  .home-piling .container {
    padding-right: 45px;
  }
}

#pp-nav.right {
  right: 15px;
}

@media (min-width: 1600px) {
  #pp-nav.right {
    right: 3.75rem;
  }
}

#pp-nav li,
.pp-slidesNav li {
  height: 24px;
  margin: 6px 0;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#pp-nav li a,
.pp-slidesNav li a {
  border: 1px solid transparent;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}
#pp-nav li a.active,
.pp-slidesNav li a.active {
  border-color: #fff;
  /* border-color: #17355d; */
}
#pp-nav span,
.pp-slidesNav span {
  width: 6px;
  height: 6px;
  border: none;
  background: #00008b;
  /* left: 8px;
  top: 8px; */
}
#pp-nav li a.active span,
.pp-slidesNav li a.active span,
#pp-nav li a:hover span,
.pp-slidesNav li a:hover span {
  /* background: rgb(23 53 93); */
  background: #fff;
}
.home-piling h1,
.home-piling .h1 {
  margin-left: -0.25rem;
  margin-bottom: 7.03703704vmin;
  font-size: 10.18518519vmin;
  line-height: 1;
}
.home-piling .slide1 .slide-bg > .inside {
  background-image: url("../img/serviceimg1.jpg");
}
.home-piling .slide2 .slide-bg > .inside {
  background-image: url("../img/serviceimg2.jpg");
}
.home-piling .slide3 .slide-bg > .inside {
  background-image: url("../img/serviceimg3.jpg");
}
.home-piling .slide4 .slide-bg > .inside {
  background-image: url("../img/serviceimg4.jpg");
}
.home-piling .slide1 .slide-title-sub {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: bold;
}
.home-piling h2,
.home-piling .h2 {
  margin-bottom: 6.11111111vmin;
  font-size: 6.66666667vmin;
  font-weight: normal;
  line-height: 1.14;
  letter-spacing: -0.125rem;
}
/* .solution-num {margin-right:1rem; margin-top:.25rem; background-image:url("../img/bg-letter-solution.jpg"); background-size:cover; font-size:37.03703704vmin; font-family:'Brandon'; line-height:.75;} */
.solution-num > .inside {
  background: #f6f6f6;
}
.solution-num-title {
  margin-top: 3vmin;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.solution-num,
.solution-num-title {
  display: inline-block;
  margin-bottom: 5vmin;
}

@media (min-width: 992px) {
  .solution-num,
  .solution-num-title {
    margin-bottom: 0;
    margin-right: 0;
  }
  .solution-num-title {
    display: block;
    margin-top: 23.7037037vmin;
  }
}

.slide2 .ornament {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
  transform: translate3d(-80px, 0, 0);
  overflow: hidden;
}
.slide2 .ornament > .inside {
  display: block;
  width: 88.51851852vmin;
  height: 44.25925926vmin;
  background: #f4e9db;
  border-radius: 50% 50% 0% 0% / 100% 100% 0% 0%;
  position: absolute;
  left: -80px;
  bottom: 0;
}
.solution-collapse-item {
  margin-bottom: 2rem;
}
.solution-collapse-item-title {
  font-size: 1.625rem;
}
.solution-collapse-item-title a {
  display: block;
  padding-right: 2rem;
  cursor: pointer;
  position: relative;
}
.solution-collapse-item-title a:not(.collapsed),
.solution-collapse-item-title a:not(.collapsed):hover,
.solution-collapse-item-title a.collapsed:hover {
  color: #f4882e;
}
.solution-collapse .card-body {
  padding: 1.25rem 1.75rem 0.25rem;
  color: #666666;
  line-height: 1.875;
}
.icon-plus {
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  position: relative;
  color: #ffffff;
}
.icon-plus:before,
.icon-plus:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 0;
  background: #ffffff;
  transform: rotate(0);
  transition: transform 0.4s ease-in-out;
}
.solution-collapse-item-title a.collapsed .icon-plus:after {
  transform: rotate(-90deg);
}
.solution-collapse-item-title a:not(.collapsed) .icon-plus:before,
.solution-collapse-item-title a:not(.collapsed) .icon-plus:after,
.solution-collapse-item-title a:hover .icon-plus:before,
.solution-collapse-item-title a:hover .icon-plus:after {
  background: #ffffff;
}

.piling-project-item {
  margin-bottom: 7.22222222vmin;
}
.piling-project-title {
  margin-bottom: 1.375rem;
  font-size: 6.11111111vmin;
  font-weight: normal;
  line-height: 1.06060606;
}
.piling-project-title a:hover,
.slide-dark .piling-project-title a:hover,
.piling-more-total a:hover,
.slide-dark .piling-more-total a:hover {
  text-decoration: underline;
}
.piling-project-detail {
  font-size: 0.875rem;
  text-transform: uppercase;
}
.section.active .slide-bg-list .slide-bg > .inside {
  transition: all 0.8s ease-in-out;
  transform: scale(1.08);
}
.slide-bg-list .slide-bg.active > .inside {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0s;
  transform: scale(1) !important;
}
.slide-bg-list .slide-bg.active:first-child > .inside {
}
.slide-bg-list .slide-bg > .inside {
  opacity: 0;
}
.piling-project-list-title,
.piling-more-total {
  font-size: 1.5rem;
  font-size: 2.22222222vmin;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.25;
}
.piling-project-list-title {
  margin-bottom: 7.77777778vmin;
}
.piling-more-total {
  margin-top: 6.94444444vmin;
}
.rounded-icon {
  display: inline-block;
  border: #ff9000 2px solid;
  width: 6.48148148vmin;
  height: 6.48148148vmin;
  border-radius: 4.375rem;
  min-width: 3rem;
  min-height: 3rem;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in-out;
}
/* .icon-quote {display:inline-block; position:absolute; left:50%; top:50%; width:1.125rem; height:1.125rem; background:url("../img/icon-quote.png") 50% 50% no-repeat; background-size:contain; transform:translate(-50%, -50%);} */
.home-piling .slide6 h2,
.home-piling .slide6 .h2 {
  margin-bottom: 2.40740741vmin;
  font-size: 6.48148148vmin;
}
.home-piling .slide-contact-address {
  margin-bottom: 6.85185185vmin;
}
.home-piling .slide-contact-mail,
.home-piling .slide-contact-phone {
  font-weight: normal;
}
.home-piling .slide-contact-mail a {
  color: inherit;
  text-decoration: underline;
}
.promo-minimal-container {
  position: relative;
}
.promo-minimal {
  position: relative;
  z-index: 200;
}
.promo-minimal-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
}
.minimal-item {
  display: block;
  border-top: #cccccc 1px solid;
  padding-top: 23.7962963vmin;
  padding-bottom: 8vmin;
  text-align: center;
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.promo-minimal .minimal-item:hover {
  opacity: 0;
}
a.minimal-item,
a.minimal-item:hover {
  color: inherit;
  text-decoration: none;
}
.minimal-item:first-child {
  border: none;
}
.minimal-item-letter {
  margin-bottom: 4.81481481vmin;
  font-size: 41.66666667vmin;
  font-size: 23.4375vmax;
  font-weight: 900;
  line-height: 0.75;
}
.minimal-item-letter .letter {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.minimal-item-letter .text-texture {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-position: 50% 50%;
  background-size: auto 132%;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: inherit;
}
.minimal-item-letter .text-texture > .inside {
  display: block;
  height: 100%;
  padding-top: 23.7962963vmin;
}
.promo-minimal-hover .text-texture > .inside {
  background: rgba(0, 0, 0, 0.65);
  mix-blend-mode: darken;
}
.promo-minimal-hover .minimal-item {
  transition: opacity 0.5s ease-in-out;
}
.promo-minimal-hover .minimal-item.visible {
  opacity: 1;
}
.minimal-item-title {
  margin-bottom: 1.5rem;
  font-size: 2.625rem;
  font-weight: 900;
}
.minimal-item-title,
.minimal-item-descr,
.minimal-item-more {
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
  z-index: 200;
}
.minimal-item-descr {
  line-height: 1.44444444;
  font-size: 1.125rem;
}
.promo-minimal-hover,
.promo-minimal-hover .text-grey {
  color: #ffffff;
}
.promo-minimal-hover > .row {
  position: relative;
  z-index: 200;
}
.promo-minimal-hover .minimal-item-letter .letter {
  overflow: visible;
}
.promo-minimal-hover .text-texture {
  transition: all 0.75s ease-in-out;
}
.promo-minimal-hover .visible .text-texture {
  background-size: auto 120%;
}
.promo-minimal-hover .minimal-item-title,
.promo-minimal-hover .minimal-item-descr,
.promo-minimal-hover .minimal-item-more {
  transform: translate(0, 150px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.promo-minimal-hover .visible .minimal-item-title,
.promo-minimal-hover .visible .minimal-item-descr,
.promo-minimal-hover .visible .minimal-item-more {
  opacity: 1;
  transform: translate(0, 0);
}
.promo-minimal-hover .minimal-item-title {
  transition-delay: 0.35s;
}
.promo-minimal-hover .minimal-item-descr {
  transition-delay: 0.5s;
}
.promo-minimal-hover .minimal-item-more {
  transition-delay: 0.65s;
}
.minimal-item-more {
  margin-top: 1.625rem;
  font-size: 4.44444444vmin;
  font-weight: bold;
  opacity: 0;
}

@media (max-width: 991px) {
  .minimal-item {
    overflow: hidden;
  }
}

.dark-horizontal .promo-minimal-container a {
  color: #000000 !important;
}

@media (min-width: 992px) {
  .minimal-item {
    min-height: 100vh;
    border-left: #cccccc 1px solid;
    border-top: none;
  }
  .minimal-item.over {
    opacity: 0;
  }
  .promo-minimal-hover .minimal-item {
    opacity: 0;
  }
  .minimal-item-letter .letter1 .text-texture {
    left: 0;
    right: -202%;
    padding-right: 202%;
  }
  .minimal-item-letter .letter2 .text-texture {
    left: -101%;
    right: -101%;
    padding-left: 101%;
    padding-right: 101%;
  }
  .minimal-item-letter .letter3 .text-texture {
    left: -202%;
    right: 0;
    padding-left: 202%;
  }
  .promo-minimal-hover:before,
  .promo-minimal-hover:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 300;
    background: #ffffff;
    opacity: 0.25;
  }
  .promo-minimal-hover:before {
    left: 33.33333333%;
  }
  .promo-minimal-hover:after {
    left: 66.66666667%;
  }
  .promo-minimal-hover .minimal-item-letter .letter3 .text-texture {
    padding-left: 0;
  }
  .promo-minimal-hover .minimal-item-letter .letter3 .text-texture > .inside {
    padding-left: 66.66666667%;
  }
}

.video-bnr {
  position: relative;
  margin-bottom: -15.375rem;
  height: 37.5rem;
}
.video-container {
  position: absolute;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.video-container:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 600;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.35;
  pointer-events: none;
}
.video-item {
  display: none;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.video-item.is-active {
  display: block;
}
.video-container iframe {
  border: none;
  margin: 0;
  padding: 0;
  height: 500%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circular-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  z-index: 99;
  width: 80%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.circular-container.is-active {
  opacity: 1;
  transition: opacity 0.5s ease-out 0.8s;
}
.circular-names {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .circular-container {
    display: none;
  }
}

.circular-container .circular-wrapper {
  position: relative;
  width: 59.72222222vmin;
  height: auto;
  margin-top: 7.96296296vmin;
}
.circular-container .circular-wrapper .circular-names li {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 300;
  overflow: hidden;
  padding-bottom: 0;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.5;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.08333333em;
}
.circular-container .circular-wrapper .circular-names li .name-wrapper {
  display: inline-block;
  padding: 10px;
  padding-bottom: 0;
  white-space: nowrap;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.circular-container .circular-wrapper .circular-names li .name-wrapper-date {
  padding-top: 0;
  padding-bottom: 10px;
  font-size: 0.875rem;
  white-space: nowrap;
  opacity: 0.7;
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="1"] {
  bottom: 61.8%;
  left: 98%;
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="2"] {
  top: 87.65%;
  left: 80%;
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="3"] {
  top: 87.65%;
  right: 80%;
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="4"] {
  right: 98%;
  bottom: 61.8%;
}

@media (max-width: 960px) {
  .circular-container
    .circular-wrapper
    .circular-names
    li[data-portrait-index="4"] {
    bottom: 58.6%;
  }
  .circular-container
    .circular-wrapper
    .circular-names
    li[data-portrait-index="4"]
    .name-wrapper {
    white-space: normal;
  }
}

.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="5"] {
  bottom: 99.5%;
  left: 48.5%;
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="1"]
  .name-wrapper,
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="2"]
  .name-wrapper,
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="5"]
  .name-wrapper {
  transform: translate3d(-100%, 0, 0);
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="3"],
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="4"] {
  text-align: right;
}
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="3"]
  .name-wrapper,
.circular-container
  .circular-wrapper
  .circular-names
  li[data-portrait-index="4"]
  .name-wrapper {
  transform: translate3d(100%, 0, 0);
}
.circular-container
  .circular-wrapper
  .circular-names
  li.is-active
  .name-wrapper {
  transform: translateZ(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
}
.circular-container .circular-wrapper .circular-names li.is-init .name-wrapper {
  transform: translateZ(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.circular-container svg {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.circular-container svg .circle-path.circle-path--background {
  opacity: 0.2;
}
.circular-container svg .circle-path.circle-path--background circle {
  stroke-dasharray: 2644;
  stroke-dashoffset: 2644;
}
.circular-container svg .circle-path.circle-path--fill {
  opacity: 1;
}
.circular-container svg .circle-path.circle-path--fill circle {
  stroke-dasharray: 2644;
  stroke-dashoffset: 2644;
}
.circular-container svg .circle-path circle {
  fill: none;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.circular-container svg .circle-dot {
  position: relative;
  cursor: pointer;
}
.circular-container svg .circle-dot .circle-outside {
  fill: none;
  stroke-width: 2;
  stroke: #fff;
  opacity: 0.2;
  stroke-miterlimit: 10;
}
.circular-container svg .circle-dot .circle-center {
  fill: #fff;
  stroke: none;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1),
    transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.circular-container svg .circle-dot:hover .circle-center {
  opacity: 1;
}
.circular-container svg .circle-dot.is-active .circle-center {
  opacity: 1;
  transition: opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1) 1s,
    transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.video-text-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 400;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  color: #ffffff;
}
.video-text-item.is-active {
  visibility: visible;
}
.video-item-title {
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 5.55555556vmin;
  font-weight: bold;
  letter-spacing: -0.05em;
  line-height: 1.2;
}
.video-text-container a {
  pointer-events: all;
  color: inherit;
}
.video-item-title-sub {
  font-size: 1rem;
  text-transform: uppercase;
}
.video-item-title-sub:first-child {
  margin-bottom: 0.5rem;
}
.video-item-descr {
  margin-top: 4.07407407vmin;
  margin-bottom: 4vmin;
  overflow: hidden;
  height: auto;
  line-height: 1.875;
  font-size: 1rem;
  color: #cccccc;
}
.video-item-more {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.25em;
}

@media (min-width: 768px) {
  .video-text-container {
    width: 59.72222222vmin;
    pointer-events: none;
  }
  .video-item-descr {
    height: 9.375em;
    height: 13.88888889vmin;
    margin-bottom: 2.59259259vmin;
  }
  .video-text-item {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 16.962963vmin;
    text-align: center;
    transform: translate(0, -50%);
  }
}

@media (max-width: 767px) {
  .video-text-container {
    display: flex !important;
  }
  .video-text-container .owl-stage-outer,
  .video-text-container .owl-stage,
  .video-text-container .owl-item {
    height: 100%;
  }
  .video-text-item {
    height: 100%;
    position: relative;
  }
  .video-text-item > .inside {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 92%;
    text-align: center;
  }
  .video-text-container.owl-theme .owl-dots {
    position: absolute;
    top: 80%;
    left: 10%;
    right: 10%;
    text-align: center;
  }
  .video-text-container.owl-theme .owl-dots .owl-dot span:before {
    background: #ffffff;
  }
  .video-text-container.owl-theme .owl-dots .owl-dot.active span {
    border-color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
  }
  .mobile-video-bg {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mobile-video-bg:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.35;
  }
  .promo-video-container {
    position: relative;
    z-index: 200;
  }
}

.video-item-more i {
  margin-left: 1.25rem;
}
.video-text-item > .inside > * {
  transform: translateY(10vmin);
  opacity: 0;
}
.video-text-item.is-active > .inside > .video-item-title-sub,
.video-text-item.is-active > .inside > .video-item-title:first-child {
  animation: videoText 2s ease-in-out forwards;
  animation-delay: 0s;
}
.video-text-item.is-active
  > .inside
  > .video-item-title-sub
  + .video-item-title,
.video-text-item.is-active
  > .inside
  > .video-item-title
  + .video-item-title-sub {
  animation: videoText 2s ease-in-out forwards;
  animation-delay: 0.15s;
}
.video-text-item.is-active > .inside > .video-item-descr {
  animation: videoText2 0.5s ease-in-out forwards;
  animation-delay: 1.8s;
}
.video-text-item.is-active > .inside > .video-item-more {
  animation: videoText2 0.5s ease-in-out forwards;
  animation-delay: 1.95s;
}

@-webkit-keyframes videoText {
  0% {
    opacity: 0;
    transform: translateY(24vmin);
  }
  30% {
    opacity: 1;
    transform: translateY(12vmin);
  }
  70% {
    transform: translateY(12vmin);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes videoText {
  0% {
    opacity: 0;
    transform: translateY(24vmin);
  }
  30% {
    opacity: 1;
    transform: translateY(12vmin);
  }
  70% {
    transform: translateY(12vmin);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes videoText2 {
  0% {
    opacity: 0;
    transform: translateY(5vmin);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes videoText2 {
  0% {
    opacity: 0;
    transform: translateY(5vmin);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.screen {
  min-height: 100vh;
  width: 100%;
  padding-top: 5vmin;
  padding-bottom: 5vmin;
  display: flex;
  align-items: center;
  text-align: center;
}
.promo-3d {
  padding-top: 25vmin;
  position: relative;
  text-align: center;
}
.promo-3d-bg,
.promo-3d-man {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -10vmin;
  z-index: 100;
}
.promo-3d-man {
  left: 50%;
  right: auto;
  bottom: -40vmin;
  width: 100%;
  transform: translate(-50%, 0) scale(1.05);
}
.promo-3d-bg img,
.promo-3d-man img {
  width: 100%;
}

@media (max-width: 575px) {
  .promo-3d-bg img,
  .promo-3d-man img {
    width: 200vmin;
    margin-left: -100vmin;
    margin-right: -100vmin;
  }
}

.promo-3d-man-start {
  position: absolute;
  left: 0;
  top: 10%;
}
.promo-3d-opacity {
  position: absolute;
  left: 0;
  top: 70%;
}
.promo-3d .promo-screen-titles {
  position: relative;
  z-index: 200;
}
.about-3d {
  position: relative;
  opacity: 0;
}
.section-about-3d.active .about-3d {
  opacity: 1;
}
.section-about-3d .section-item-title,
.section-about-3d .about-3d-detail {
  opacity: 0;
  transform: translateY(16rem);
  transition: all 0.75s ease-in-out;
}
.section-about-3d .section-item-title {
  transition-delay: 0.3s;
}
.section-about-3d .about-3d-detail {
  transition-delay: 1.2s;
}
.section-about-3d.active .section-item-title,
.section-about-3d.active .about-3d-detail {
  opacity: 1;
  transform: translateY(0);
}
.about-3d-photo {
  position: relative;
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  margin-top: 12px;
  margin-bottom: 8vmin;
}
.photo-container {
  position: relative;
  overflow: hidden;
}
.photo-container img {
  transform: scale(1.2);
  transform-origin: right top;
  transition: all 1.5s 0.5s ease-in-out;
}
.section-about-3d.active .photo-container img {
  transform: scale(1);
}
.photo-container .mask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transition: all 1.3s 0.5s ease-in-out;
}
.section-about-3d.active .photo-container .mask {
  top: -100%;
}
.section-about-3d .stamp {
  opacity: 0;
  transform: scale(1.5);
  transform-origin: right center;
  transition: all 1s 0.5s ease-in-out;
}
.section-about-3d.active .stamp {
  transform: scale(1);
  opacity: 1;
}
.about-3d .section-item-title {
  margin-bottom: 8.51851852vmin;
}
.about-3d-detail {
  position: relative;
  padding-bottom: 3.75rem;
  margin-bottom: 5vmin;
}

@media (min-width: 768px) {
  .about-3d-photo {
    padding-left: 6.25rem;
    padding-right: 4.375rem;
  }
  .rounded-logo.stamp {
    bottom: -8rem;
  }
}

@media (min-width: 992px) {
  .about-3d-photo,
  .about-3d-detail {
    margin-bottom: 11.85185185vmin;
  }
}

.about-3d-detail .lead {
  margin-bottom: 2.5rem;
}
.about-3d-detail .more {
  position: absolute;
  bottom: 0px;
  left: 15px;
}
.h2-3d {
  margin-bottom: 2.5rem;
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -0.035em;
}
.h2-flash {
  margin-bottom: 6rem;
  font-size: 3rem;
  line-height: 1.25;
}
.vertical-text-left {
  position: absolute;
  left: auto;
  right: 100%;
  margin-right: -34px;
  top: 0;
  transform: rotate(-90deg);
  transform-origin: top right;
  white-space: nowrap;
  text-align: right;
}
.vertical-text-right {
  position: absolute;
  right: auto;
  left: 100%;
  margin-left: -34px;
  top: 0;
  transform: rotate(90deg);
  transform-origin: top left;
  white-space: nowrap;
  text-align: left;
}
.vertical-text-left,
.vertical-text-right {
  font-size: 1rem;
  color: #666666;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}
.about-3d .vertical-text-left,
.about-3d .vertical-text-right {
  top: 90px;
}

@media (max-width: 575px) {
  .rounded-logo.stamp {
    width: 7rem;
    height: 7rem;
    left: 3rem;
    bottom: -2rem;
  }
}

.project-carousel-3d {
  overflow: hidden;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 576px) {
  .project-carousel-3d .owl-carousel {
    width: 78.33333333%;
    position: static;
  }
}

.project-carousel-3d .owl-stage-outer {
  overflow: visible;
  transform: translate(0%, 0%);
  transition: all 0.4s ease-in-out;
}

@media (min-width: 768px) {
  .project-carousel-3d .project-carousel-item {
    padding-bottom: 54.04255319%;
  }
}

.project-carousel-3d:hover .owl-carousel .owl-stage-outer {
  transform: translate(-5rem, 0%);
}
.project-carousel-3d.move-left .owl-carousel .owl-stage-outer {
  transform: translate(-8rem, 0%);
}
.project-carousel-3d.move-right .owl-carousel .owl-stage-outer {
  transform: translate(-2rem, 0%);
}
.project-carousel-3d .owl-item .project-carousel-photo,
.project-carousel-3d .owl-item .project-carousel-date,
.project-carousel-3d .owl-item .project-carousel-detail {
  transition: all 0.4s ease-in-out;
  transform: translate(0rem, 0%);
}
.project-carousel-3d .owl-item.active + .owl-item .project-carousel-photo {
  transform: translate(-4rem, 0%);
}
.project-carousel-3d:hover
  .owl-item.active
  + .owl-item
  .project-carousel-photo {
  transform: translate(-6rem, 0%);
}
.project-carousel-3d.move-left
  .owl-item.active
  + .owl-item
  .project-carousel-photo {
  transform: translate(-7rem, 0%);
}
.project-carousel-3d:hover .owl-item.active .project-carousel-date,
.project-carousel-3d:hover .owl-item.active .project-carousel-detail {
  transform: translate(5rem, 0%);
}
.project-carousel-3d.move-left .owl-item.active .project-carousel-date,
.project-carousel-3d.move-left .owl-item.active .project-carousel-detail {
  transform: translate(8rem, 0%);
}
.project-carousel-3d.move-right .owl-item.active .project-carousel-date,
.project-carousel-3d.move-right .owl-item.active .project-carousel-detail {
  transform: translate(2rem, 0%);
}
.project-carousel-3d
  .project-carousel-item:not(.text-white)
  .project-carousel-title,
.project-carousel-3d
  .project-carousel-item:not(.text-white)
  .project-carousel-date {
  color: #000000;
}
.project-carousel-3d .project-carousel-item .project-carousel-category,
.project-listing-category,
.project-masonry-category {
  color: #999999;
}
.project-carousel-3d .project-carousel-category,
.project-listing-category,
.project-masonry-category {
  font-size: 0.875rem;
  text-transform: uppercase;
}
.project-carousel-date {
  font-size: 1.5rem;
}
.project-carousel-3d .project-carousel-detail,
.project-carousel-3d .owl-item.active .project-carousel-detail {
  opacity: 1;
  animation: none;
}
.project-carousel-3d .project-carousel-detail {
  bottom: 2rem;
}
.project-carousel-3d .owl-item {
  overflow: hidden;
}

@media (min-width: 768px) {
  .project-carousel-3d .project-carousel-detail {
    bottom: 5rem;
  }
}

.flash-item-nav {
  position: fixed;
  left: 15px;
  z-index: 500;
  padding-left: 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}
.flash-item-nav {
  transition: padding 0.2s ease-in-out;
}
.flash-item-nav:before {
  transition: width 0.2s ease-in-out;
}
.flash-item-nav a {
  transition: color 0.2s ease-in-out;
}
.flash-item-nav:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  margin-top: -1px;
  width: 0;
}
.flash-item-nav.active:before {
  width: 1.875rem;
}
.flash-item-nav.active {
  padding-left: 3rem;
}
.flash-item-nav a {
  color: #999999 !important;
}
.flash-item-nav a:hover,
.flash-item-nav.active a {
  color: #000000 !important;
  text-decoration: none;
}
.flash-item-nav:before {
  background: #000000;
}
.home-flash-dark .flash-item-nav a,
.home-flash.dark-horizontal .flash-item-nav a {
  color: #666699 !important;
}
.home-flash-dark .flash-item-nav a:hover,
.home-flash.dark-horizontal .flash-item-nav a:hover,
.home-flash-dark .flash-item-nav.active a,
.home-flash.dark-horizontal .flash-item-nav.active a {
  color: #ffffff !important;
}
.home-flash-dark .flash-item-nav:before,
.home-flash.dark-horizontal .flash-item-nav:before {
  background: #ffffff;
}
.item-nav-1 {
  top: calc(100% - 330px);
}
.item-nav-2 {
  top: calc(100% - 280px);
}
.item-nav-3 {
  top: calc(100% - 230px);
}
.item-nav-4 {
  top: calc(100% - 180px);
}
.item-nav-5 {
  top: calc(100% - 130px);
}
.item-nav-6 {
  top: calc(100% - 80px);
}
.flash-item {
  position: relative;
}
.flash-pin-2 {
  position: absolute;
  left: 0;
  top: 276px;
}
.flash-pin-3 {
  position: absolute;
  left: 0;
  top: 228px;
}
.flash-pin-4 {
  position: absolute;
  left: 0;
  top: 172px;
}
.flash-pin-5 {
  position: absolute;
  left: 0;
  top: 128px;
}
.flash-pin-6 {
  position: absolute;
  left: 0;
  top: 78px;
}

@media (max-width: 575px) {
  .flash-item-nav {
    display: none;
  }
}

@media (min-width: 1600px) {
  .flash-item-nav {
    left: 3.75rem;
  }
}

.home-flash-dark .home-flash-section .container,
.home-flash.dark-horizontal .home-flash-section .container,
.home-flash-dark .home-flash-section .container-fluid,
.home-flash.dark-horizontal .home-flash-section .container-fluid,
.home-flash .footer {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.home-flash-section {
  padding-bottom: 0;
}
.home-flash-section-dark {
  padding-top: 21.01851852vmin;
  margin-bottom: 0;
}

@media (min-width: 576px) and (max-width: 1599px) {
  .home-flash-section {
    padding-left: 10rem;
  }
}

.home-flash-dark .home-flash-section-dark .container,
.home-flash.dark-horizontal .home-flash-section-dark .container,
.home-flash-dark .home-flash-section-dark .container-fluid,
.home-flash.dark-horizontal .home-flash-section-dark .container-fluid,
.home-flash.dark-horizontal .footer {
  opacity: 1;
  pointer-events: all;
}
.home-flash {
  background: #ffffff;
  transition: background 0.3s ease-in-out;
}
.home-flash-dark,
.home-flash.dark-horizontal {
  background: #2c1a6a;
  color: #9999cc;
}
.home-flash-dark hr,
.home-flash.dark-horizontal hr {
  border-top-color: rgba(255, 255, 255, 0.05);
}
.home-flash-dark a,
.dark-horizontal a,
.home-flash-dark strong,
.home-flash.dark-horizontal strong,
.home-flash-dark h1,
.home-flash.dark-horizontal h1,
.home-flash-dark .h1,
.home-flash.dark-horizontal .h1,
.home-flash-dark h2,
.home-flash.dark-horizontal h2,
.home-flash-dark .h2,
.home-flash.dark-horizontal .h2,
.home-flash-dark h3,
.home-flash.dark-horizontal h3,
.home-flash-dark .h3,
.home-flash.dark-horizontal .h3 {
  color: #ffffff;
}
.home-flash .section-item-title,
.section-item-title-xs,
.section-item-title-xs-narrow {
  font-size: 0.875rem;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-weight: bold;
}
.section-item-title-xs-narrow {
  letter-spacing: 0.1em;
  font-weight: normal;
}
.section-item-title-xs {
  margin-bottom: 1.25rem;
}
.home-flash-dark .section-item-title,
.home-flash-dark .section-item-title a {
  color: #666699;
  text-decoration: none;
}
.h1-flash {
  margin-bottom: 7.40740741vmin;
  background: linear-gradient(to bottom, #ff7c00, #02a6fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 9.25925926vmin;
  line-height: 1;
}
.about-flash {
  position: relative;
}
.about-flash .vertical-text-right {
  right: auto;
  left: 0;
  margin-left: 35px;
  margin-top: 1.66666667vmin;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.125rem;
}
.rounded-logo-scroll {
  float: right;
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
  margin-top: 22px;
}
.rounded-logo-scroll svg {
  letter-spacing: 9px;
  word-spacing: 0;
  fill: #666699;
}
.scroll-icon {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 38px;
  border: #ffffff 2px solid;
  border-radius: 10px;
  padding-top: 18px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.scroll-icon i {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1px;
  background: #ffffff;
  border-radius: 10px;
}
.scroll-icon i:nth-child(1) {
  width: 2px;
  height: 2px;
}
.scroll-icon i:nth-child(2) {
  width: 4px;
  height: 4px;
}
.scroll-icon i:nth-child(3) {
  width: 6px;
  height: 6px;
}
.flash-video-container {
  position: relative;
  padding-right: 8.54700855%;
  padding-top: 8.54700855%;
}
.flash-video-container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 14.28571429%;
  left: 8.54700855%;
  right: 0;
  background: url("../img/bg-dotted.png") 0px 2px;
  z-index: 1;
}
.flash-video-container.mask-skew .mask .inside {
  background: #2c1a6a;
}
.flash-video {
  display: block;
  width: 100%;
  position: relative;
  z-index: 50;
}
.flash-video img {
  border: #ffffff 8px solid;
  max-width: 100%;
}
.flash-video-icon {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  background: #ffffff;
  position: absolute;
  z-index: 30;
  left: 50%;
  top: 50%;
  text-align: center;
  line-height: 4.75rem;
  font-size: 2.375rem;
  color: #02a6fd;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.flash-video-icon:before,
.flash-video-icon:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100px;
  background: #ffffff;
  opacity: 0.4;
}
.flash-video-icon:before {
  left: -0.625rem;
  top: -0.625rem;
  right: -0.625rem;
  bottom: -0.625rem;
}
.flash-video-icon:after {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation-name: pulsation;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes pulsation {
  0% {
    opacity: 0.5;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  15% {
    opacity: 0;
    -webkit-transform: scale(1.75);
    -ms-transform: scale(1.75);
    -o-transform: scale(1.75);
    transform: scale(1.75);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.75);
    -ms-transform: scale(1.75);
    -o-transform: scale(1.75);
    transform: scale(1.75);
  }
}

.flash-video-icon i {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.25rem;
  line-height: 0.75;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.services-flash {
  box-shadow: rgba(2, 3, 3, 0.1) 0 0 120px;
  margin-top: 80px;
  margin-bottom: 11.2962963vmin;
}
.services-flash .service-item {
  border: #40407a 1px solid;
  background: #331d7f;
  padding: 2.125rem 1.625rem 2.125rem 2rem;
  margin-left: -1px;
  margin-bottom: -1px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  letter-spacing: -0.025em;
}

@media (min-width: 576px) and (max-width: 767px) {
  .services-flash .service-item:nth-child(odd) {
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .services-flash .service-item {
    margin-left: 0;
  }
}

.services-flash .service-item:hover {
  z-index: 10;
}
.services-flash .service-item:before {
  content: "";
  background: #02a6fd;
  height: 5px;
  width: 0;
  position: absolute;
  left: -1px;
  bottom: -1px;
  transition: all 0.4s ease-in-out;
}
.services-flash .service-item:hover:before {
  width: calc(100% + 2px);
}
.services-flash .service-item:first-child {
  margin-left: 0;
}
.services-flash .service-item-title {
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #ffffff;
}
.services-flash a:hover .service-item-title,
.services-flash .service-item-title:hover {
  color: #ffffff;
}
.services-flash .service-item-descr {
  display: flex;
  flex: 1 1 auto;
  line-height: 1.5;
  margin-top: 1rem;
}
/* .icon-design-graphic {display:inline-block; vertical-align:bottom; width:5rem; height:3.4375rem; background-position:50% 50%; background-repeat:no-repeat; background-image:url("../img/icon-design-graphic.png"); background-size:100%;} */
/* .icon-design-web {display:inline-block; vertical-align:bottom; width:4.0625rem; height:3.3125rem; background-position:50% 50%; background-repeat:no-repeat; background-image:url("../img/icon-design-web.png"); background-size:100%;} */
/* .icon-marketing {display:inline-block; vertic.al-align:bottom; width:3.4375rem; height:3.9375rem; background-position:50% 50%; background-repeat:no-repeat; background-image:url("../img/icon-marketing.png"); background-size:100%;} */
/* .icon-photography {display:inline-block; vertical-align:bottom; width:4.1875rem; height:3.3125rem; background-position:50% 50%; background-repeat:no-repeat; background-image:url("../img/icon-photography.png"); background-size:100%;} */
.service-item-icon {
  margin-top: 2.125rem;
  height: 4rem;
  line-height: 4rem;
}
.service-item-plus {
  height: 1rem;
  position: relative;
  margin-top: 2.625rem;
}
.service-item-plus:before,
.service-item-plus:after {
  content: "";
  display: block;
  width: 1rem;
  height: 0.25rem;
  background: #666699;
  position: absolute;
  left: 0;
  bottom: 0.375rem;
  transform: rotate(-180deg);
  transition: all 0.4s ease-in-out;
}
.service-item:hover .service-item-plus:before,
.service-item:hover .service-item-plus:after {
  background: #ffffff;
}
.service-item:hover .service-item-plus:after {
  transform: rotate(90deg);
}

.fact-item {
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
}
.fact-item-value {
  position: relative;
  padding-right: 1.5rem;
  line-height: 1.1;
  font-size: 2.75rem;
  visibility: hidden;
}
.fact-item-value > .inside {
  visibility: visible;
  position: absolute;
  left: 0;
  top: 0;
}
.fact-item-param {
  font-size: 1.125rem;
  line-height: 1.44444444;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .fact-item-value {
    line-height: 1.5;
    font-size: 5rem;
  }
}

.project-list-listing + .more-lg {
  margin-top: 4.375rem;
}
.project-list-masonry + .more-lg {
  margin-top: 6.25rem;
}
.grid-item {
  width: 100%;
}
.project-list-flash .project-item,
.project-list-listing .project-item {
  display: block;
  position: relative;
}
.project-list-flash .project-item {
  padding-bottom: 100%;
  overflow: hidden;
}
.project-list-listing .grid-item {
  display: block;
  margin-bottom: 5.625rem;
}
.project-list-masonry .grid-item {
  display: block;
  margin-bottom: 2.5rem;
  padding-left: 15px;
  padding-right: 15px;
}
.project-list-listing .project-item {
  padding-bottom: 44.44444444%;
  overflow: hidden;
}
.project-list-listing .project-listing-photo {
  transform: scale(1);
  transition: all 1s ease-in-out;
}
.project-list-listing a:hover .project-listing-photo {
  transform: scale(1.08);
}
.project-list-flash .section-item-title {
  margin-top: -0.375rem;
  margin-bottom: 1.625rem;
}
.project-list-flash .project-carousel-detail,
.project-metro-detail {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  padding: 1.5rem 3.5rem 2.875rem;
  background: transparent;
  transition: all 0.5s linear;
}
.project-metro-detail {
  padding-left: 2.875rem;
  padding-right: 2.875rem;
  padding-bottom: 2.375rem;
}
.project-metro-title {
  margin-bottom: 1.125rem;
}
.project-list-flash .project-item:hover .project-carousel-detail {
  background: #191a1e;
}
.project-list-flash .project-carousel-title {
  padding-right: 2rem;
  font-family: "Brandon", cursive;
  font-weight: normal;
}
.project-list-title {
  padding-bottom: 3rem;
}
.project-list-title h2 {
  line-height: 1.333333;
}
.project-list-flash .project-carousel-category {
  margin-top: 1.125rem;
}
.project-list-flash .project-carousel-photo {
  transform: scale(1);
  transition: all 1s ease-in-out;
}
.project-list-flash a:hover .project-carousel-photo {
  transform: scale(1);
  border: solid 1px #6b7a8e;
}
.project-item-more {
  padding-top: 3rem;
}
.project-masonry-photo {
  overflow: hidden;
}
.project-masonry-photo img {
  max-width: 100%;
  width: 100%;
}
.project-list-metro .project-item {
  height: 32.5rem;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
.project-metro-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
a:hover .project-metro-photo img {
  transform: scale(1.1);
  opacity: 0;
}

@media (min-width: 576px) {
  .project-list-masonry .grid-item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .project-list-listing .grid-item {
    width: 100%;
  }
  .project-list-masonry .grid-item {
    width: 25%;
  }
  .project-list-title,
  .project-item-more {
    margin-left: 17.09401709%;
    margin-right: 25%;
  }
  .project-list-title {
    padding-bottom: 20.51282051%;
  }
  .project-item-more {
    padding-top: 30%;
  }
}

.project-carousel-category .divider,
.project-item .divider {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.project-carousel-go {
  position: absolute;
  right: 2.625rem;
  top: 1.375rem;
  opacity: 0;
  margin-right: 2rem;
  font-size: 3.75rem;
  transition: all 0.5s ease-in-out;
}
.project-item:hover .project-carousel-go {
  margin-right: 0;
  opacity: 1;
}
.project-list-flash .project-carousel-date,
.project-metro-date {
  top: 3.125rem;
  left: 3.5rem;
  font-size: 1.25rem;
  letter-spacing: 0.025em;
  font-weight: bold;
}
.project-metro-date {
  top: 2.625rem;
  left: 2.875rem;
}
.article-list-flash {
  margin-top: 2.375rem;
}
.article-list-flash > div {
  display: flex;
}
.article-list-flash .article-item {
  padding: 2.25rem 2.625rem 2.4375rem;
  background: #f6f7f7;
  overflow: hidden;
}
.article-list-flash .article-item-photo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
  margin: 0;
  height: auto;
  padding-bottom: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s ease-in-out;
}
.article-list-flash .article-item:hover .article-item-photo {
  opacity: 1;
}
.article-list-flash .article-item .article-item-photo {
  transition-property: opacity, transform;
  transition-duration: 0.5s, 8s;
  transform: scale(1);
}
.article-list-flash .article-item:hover .article-item-photo {
  transform: scale(1.2);
}
.article-list-flash .article-item-date {
  position: static;
  left: auto;
  bottom: auto;
  padding-bottom: 27.27272727%;
  margin-bottom: 0;
  color: #999999;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}
.article-list-flash .article-item-date .date {
  display: block;
  margin-bottom: 0.75rem;
  line-height: 0.75;
  font-size: 4.5rem;
  color: #cccccc;
  font-weight: normal;
}
.article-list-flash .article-item-category {
  margin-bottom: 1.375rem;
}
.article-list-flash .article-item-category,
.article-list-flash .article-item-category a {
  color: #02a6fd;
}
.footer-flash-about {
  padding-top: 16.66666667vmin;
  padding-bottom: 13.51851852vmin;
  font-size: 1.875rem;
  line-height: 1.6;
}
.home-flash .footer-flash-about {
  padding-top: 0;
}
.footer-flash {
  padding-top: 4.875rem;
  padding-bottom: 0;
}
.footer-content-flash h5 {
  margin-bottom: 1.5rem;
  /* font-size: 1rem; */
  font-size: 1rem ;
  color: #29abe2;
  text-transform: uppercase;
  font-weight: bold;
}
.footer-content-flash .contact-item,
.footer-content-flash .contact-item a,
.footer-content-flash .social-rounded a {
  color: #ffffff;
}
.footer-content-flash .contact-item {
  margin-bottom: 0.625rem;
}
.footer-content-flash .contact-item,
.footer-content-flash .footer-nav {
  /* font-size: 1rem; */
  font-size: 12px;
  line-height: 2rem;
}
.footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-nav li {
  margin-bottom: 0.5rem;
}
.footer-content-flash .contact-item a:not(.phone-link):hover,
.footer-content-flash .footer-nav a:hover {
  color: #29abe2;
  text-decoration: none;
}
.footer-flash .site-info {
  border-top: none;
}

@media (min-width: 768px) {
  .footer-content-flash .social-rounded {
    margin-right: -0.625rem;
    text-align: right;
  }
  .footer-flash .site-info {
    padding-top: 3.125rem;
    padding-bottom: 4rem;
  }
}

.footer-flash .copyright {
  text-transform: none;
}
.nav-inline {
  margin-right: -15px;
  margin-bottom: 2.625rem;
  letter-spacing: -0.015em;
}
.nav-inline li {
  margin-right: 2.25rem;
  margin-bottom: 1rem;
}
.nav-inline a {
  cursor: pointer;
}
.nav-inline .active a,
.nav-inline a:hover {
  text-decoration: none;
}
.text-center .nav-inline {
  margin-right: 0;
  justify-content: center;
}
.text-center .nav-inline li {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}
.nav-inline-wide {
  letter-spacing: 0.025em;
}
.nav-inline-wide li {
  margin-right: 3.125rem;
}
.text-center .nav-inline-wide li {
  margin-left: 1.5625rem;
  margin-right: 1.5625rem;
}
.nav-inline.text-black .active a,
.nav-inline.text-black a:hover {
  color: #02a6fd;
}
.parallax-link a,
.parallax-link a:hover {
  position: relative;
  text-decoration: none;
  color: #666666 !important;
}
.parallax-link a:before {
  content: attr(data-text);
  position: absolute;
  width: 0%;
  transition: width cubic-bezier(0.82, 0, 0.21, 1) 0.3s;
  color: #02a6fd;
  z-index: 2;
  overflow: hidden;
  white-space: nowrap;
}
.parallax-link li.active > a:before,
.parallax-link a:hover:before {
  width: 100%;
}
.nav.flex-column li {
  margin-bottom: 1rem;
}
.nav.flex-column a {
  color: inherit;
  text-decoration: none;
}
.nav.flex-column .active a,
.nav.flex-column a:hover {
  color: #02a6fd;
}
.project-card-mobile {
  padding-top: 5.375rem;
}
.project-card-promo {
  height: 0;
  padding-bottom: 44.79166667%;
  background-size: cover;
  background-position: 50% 20%;
  overflow: hidden;
  transition: all 0.1s linear;
}
.project-info {
  padding-top: 4.875rem;
  padding-bottom: 1.125rem;
  border-bottom: #eaeaea 1px solid;
}
.project-info-item {
  margin-bottom: 3.5rem;
}
.project-info-title {
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
}
.project-info-descr {
  line-height: 1.625;
  color: #999999;
}
.lead {
  font-weight: normal;
}
.lead-lg {
  font-size: 1.5rem;
}
.like-btn {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  background: #f4f6f6;
  border: #f4f6f6 1px solid;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 2.25rem;
  color: #02a6fd;
}
.like-btn i {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.like-btn:hover i {
  transform: scale(1.08);
}
.pager {
  letter-spacing: 0.025em;
}
.pager i {
  vertical-align: middle;
  line-height: 1;
  font-size: 1.25rem;
}
.pager .ion-ios-arrow-left {
  margin-right: 0.5rem;
}
.pager .ion-ios-arrow-right {
  margin-left: 0.5rem;
}
.pager a {
  color: #000000;
  text-decoration: none;
}
.pager a:hover {
  color: #02a6fd;
}
.pagination {
  margin-top: 7rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.page-link {
  border: none;
  padding: 0;
  background: transparent;
  margin-right: 1.625rem;
  color: #999999;
}
.page-item.disabled .page-link {
  color: #999999;
  opacity: 0.3;
}
.page-link:hover,
.page-item.active .page-link {
  background: transparent;
}
.page-item.active .page-link,
a.page-link:hover {
  color: #16171e;
}
.page-link i,
.page-link i:before {
  display: block;
  font-size: 1.5rem;
  line-height: 0.75;
}
.tags li {
  margin-right: 10px;
}
.tags .active .btn-outline-secondary,
.tags .btn-outline-secondary:hover,
.tags .btn-outline-secondary:not(:disabled):not(.disabled):active,
.theme-orange .tags .btn-outline-secondary:hover {
  background: transparent;
  border-color: #16171e !important;
  color: #16171e !important;
}
.next-project {
  position: relative;
  background: #eaeeef;
}
.next-project [class^="col-"] {
  position: static;
}
.next-project a {
  display: block;
  padding-top: 3.25rem;
  padding-bottom: 3rem;
  text-decoration: none;
  color: #000000;
}
.next-project-link {
  text-align: right;
}
.next-project-photo {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0;
  transition: all 0.5s 0.5s ease-in-out;
}
.prev-project-link .next-project-photo {
  left: 0;
  right: 50%;
}
.next-project-link .next-project-photo {
  left: 50%;
  right: 0;
}
.next-project a:hover .next-project-photo {
  opacity: 1;
}
.next-project h3,
.next-project .h3 {
  position: relative;
  top: 0;
  opacity: 1;
  font-size: 1.5rem;
  transition: all 0.4s 0.3s ease-in-out;
}
.next-project-promo {
  height: 24rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
  background-position: 50% 0%;
  background-size: cover;
  text-align: center;
}
.next-title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  text-transform: uppercase;
}
.next-project .next-title {
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
  color: #999999;
  position: relative;
  top: 0;
  opacity: 1;
  transition: all 0.4s 0.2s ease-in-out;
}
.next-project a:hover .next-title,
.next-project a:hover h3,
.next-project a:hover .h3 {
  top: -2rem;
  opacity: 0;
}
.screen-item {
  border: #cccccc 1px solid;
  margin-bottom: 30px;
  display: block;
}
.screen-item img {
  display: block;
  width: 100%;
}
.testimonial-text {
  margin-bottom: 5rem;
  letter-spacing: -0.025em;
  font-size: 1.5rem;
  line-height: 1.73333333;
}
.testimonial-item-lg .testimonial-text {
  font-size: 2.25rem;
  line-height: 1.66666667;
}
.testimonial-author {
  letter-spacing: 0.05em;
}
.testimonial-author.text-grey strong {
  color: #000000;
}
.testimonial-author:before,
.testimonial-author:after {
  content: " - ";
}

@media (min-width: 768px) {
  .next-project-promo {
    height: 29.375rem;
  }
  .next-project a {
    padding-top: 5.625rem;
    padding-bottom: 5rem;
  }
  .next-project h3,
  .next-project .h3 {
    font-size: 1.875rem;
  }
  .testimonial-text {
    font-size: 1.875rem;
  }
}

.photo-list {
  overflow-x: hidden;
  overflow-y: visible;
}
.photo-item {
  overflow: hidden;
  text-align: center;
  margin-bottom: 5rem;
}
.project-card-web-promo img,
.photo-item-grey img,
.photo-item img {
  max-width: 100%;
}
.photo-item-grey {
  background: #dde2e4;
  padding: 7.29166667% 15px;
  text-align: center;
}
.photo-item-grey .inside {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  box-shadow: rgba(146, 156, 160, 0.75) 0 2.5rem 9.375rem;
}
.photo-item-grey img {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.photo-item-grey .inside img {
  width: 100%;
}
.photo-item-grey-parallax {
  height: 0;
  padding-bottom: 72.72727273%;
  padding-top: 0;
  overflow: hidden;
  position: relative;
}
.inside-left {
  width: 60%;
  position: absolute;
  left: 10%;
  top: 40%;
  z-index: 10;
}
.inside-right {
  width: 60%;
  position: absolute;
  left: 43.03030303%;
  top: -96.66666667%;
}
.inside-center {
  width: 76.60606061%;
  position: absolute;
  left: 11.75757576%;
  top: 17.16666667%;
}

@media (min-width: 768px) {
  .photo-list {
    margin-bottom: -10.75rem;
  }
  .photo-item {
    margin-bottom: 14.375rem;
  }
  .photo-item.col-md-6:nth-child(odd) {
    text-align: right;
    padding-right: 4.42708333%;
  }
  .photo-item.col-md-6:nth-child(even) {
    text-align: left;
    padding-left: 4.42708333%;
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.project-next-simple {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
}
.project-card-web-promo {
  background: #dde2e4;
  height: 0;
  padding-bottom: 52.08333333%;
  overflow: hidden;
  position: relative;
}
.project-card-web-promo .screen-mobile {
  position: absolute;
  left: 14.58333333%;
  top: 18.7962963%;
  width: 20.83333333%;
}
.project-card-web-promo .screen-desktop {
  position: absolute;
  left: 28.90625%;
  top: 18.7962963%;
  width: 59.0625%;
}
.pallete-list {
  margin-right: -4rem;
}

@media (max-width: 767px) {
  .pallete-list {
    margin-bottom: 5rem;
  }
  .project-card-web-promo {
    padding-bottom: 80%;
  }
  .project-card-web-promo .screen-desktop,
  .project-card-web-promo .screen-mobile {
    margin-top: 4rem;
  }
}

.pallete-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 4rem;
  margin-bottom: 2.375rem;
  text-align: center;
}
.color {
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  margin-bottom: 24px;
}
.color-title {
  color: #999999;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.article-content {
  font-size: 1.125rem;
  line-height: 1.66666667;
}
.article-content .fs20 {
  line-height: 1.5;
}
.article-content .fs20 p {
  margin-bottom: 1.5em;
}
.article-content p {
  margin-bottom: 1.66666667em;
}
.article-content h2,
.article-content .h2 {
  margin-top: 5.5rem;
  margin-bottom: 3.125rem;
}
.article-content h4,
.article-content .h4 {
  margin-top: 3.75rem;
  margin-bottom: 2rem;
  font-family: "Brandon", serif;
  letter-spacing: 0;
}
.article-content h4:first-child,
.article-content .h4:first-child {
  margin-top: 0;
}
.article-content .lead {
  margin-bottom: 50px;
  font-size: 1.5rem;
  color: #999999;
  font-family: "Brandon", serif;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: -0.01em;
}
.article-content .dropcap:first-letter {
  float: left;
  margin-bottom: -1rem;
  margin-right: 1rem;
  font-size: 3.75rem;
  font-family: "Brandon", serif;
  line-height: 1.275;
}
.article-content figure {
  margin-top: 3.5rem;
  margin-bottom: 4rem;
}
.article-content figure img {
  max-width: 100%;
  height: auto;
}
.article-content figcaption {
  display: block;
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #999999;
}
.article-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.article-content ul li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1.125rem;
}
.article-content ul li:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0.7em;
}
.mask-skew {
  overflow: hidden;
  position: relative;
}
.mask-skew .mask {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  z-index: 100;
  width: 120%;
  animation-fill-mode: forwards;
}
.mask-skew .mask .inside {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  transform: skewX(-10deg);
  transform-origin: bottom;
}
.blockquote {
  margin-top: 4.5rem;
  margin-bottom: 4.125rem;
  text-align: center;
  font-size: 1.875rem;
  line-height: 1.53333333;
  letter-spacing: -0.025em;
}
.blockquote footer {
  margin-top: 2.5rem;
  position: relative;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #999999;
}
.blockquote footer:before {
  content: "";
  display: block;
  width: 1.875rem;
  height: 1px;
  background: #a7a9b8;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translate(-50%, 0);
}
.blockquote-status footer {
  text-transform: none;
  letter-spacing: 0;
}
.blockquote-status footer:before {
  display: none;
}
.article-carousel-promo .owl-nav [class*="owl-"] {
  display: none;
}
.article-carousel-promo .owl-dots {
  margin-top: 1rem;
}
.article-promo-item {
  display: block;
  min-height: 35rem;
  background-position: 50% 20%;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 3rem;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.article-promo-item,
.article-promo-item a {
  color: #ffffff;
}
.article-carousel-promo-detail a:not(.btn) {
  text-decoration: underline;
}
.article-carousel-promo-detail a:not(.btn):hover {
  text-decoration: none;
}
.article-promo-item:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.25;
}
.article-promo .article-promo-item:before {
  display: none;
}
.article-promo-item .btn-outline-light:hover {
  background: #02a6fd;
  border-color: #02a6fd;
  color: #ffffff;
}
.bnr {
  position: relative;
  background: #181b31;
  display: block;
}
.bnr-photo {
  opacity: 0.5;
}
.bnr-photo img {
  width: 100%;
  height: auto;
}
.bnr-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 1.25rem 1.875rem;
  text-align: center;
  color: #ffffff;
}
.bnr-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.bnr-title-sub {
  margin-bottom: 10px;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media (min-width: 576px) {
  .article-carousel-promo .owl-nav [class*="owl-"] {
    display: block;
  }
  .article-carousel-promo .owl-dots {
    display: none;
  }
  .article-promo-item {
    min-height: 39.375rem;
    padding: 10.625rem 4.75rem 3rem;
    align-items: flex-start;
  }
  .article-promo-item-md {
    min-height: 48.75rem;
  }
}

.news-info {
  border-bottom: #eaeaea 1px solid;
}
.news-info-item {
  margin-bottom: 3.375rem;
}
.news-info-title {
  margin-bottom: 2px;
  font-size: 0.75rem;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.article-author {
  border-top: #e5e5e5 1px solid;
  border-bottom: #e5e5e5 1px solid;
  padding-top: 3.125rem;
  padding-bottom: 2.8125rem;
}
.article-author-photo {
  width: 5rem;
}
/* .avatar-photo {background-position:50% 50%; border-radius:50%; background-size:cover; background-image:url("../img/no-avatar.gif"); background-repeat:no-repeat; overflow:hidden; position:relative;} */
.avatar-photo:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%;
}
.avatar-photo a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}
.article-author-name {
  margin-bottom: 0;
}
.article-author-name a {
  color: #181b31;
  text-decoration: none;
}
.article-author-name a:hover {
  text-decoration: underline;
}
.article-author-status {
  font-size: 0.875rem;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.article-author-descr {
  margin-top: 10px;
  line-height: 1.625;
}
.comment-item {
  margin-bottom: 2rem;
}
.comment-item-photo {
  width: 4.375rem;
}
.comment-item-sub {
  margin-top: 1.75rem;
  margin-right: 1.25rem;
}
.comment-item-sub .comment-item-photo {
  width: 2.8125rem;
  margin-top: 0.25rem;
}
.comment-item-name {
  margin-top: 2px;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}
.comment-item-name a {
  color: inherit;
}
.comment-item-date {
  margin-top: 0.375rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #999999;
}
.comment-item-descr {
  margin-top: 0.375rem;
  line-height: 1.625rem;
  color: #666666;
}
.comment-item-sub .comment-item-descr {
  margin-top: 0;
}
.comment-item .btn {
  margin-top: 0.875rem;
}
.img404 {
  width: 41.5rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4.875rem;
}
.progress {
  border-radius: 0;
  height: 0.875rem;
  overflow: visible;
}
.progress-bar {
  position: relative;
  width: 0;
  transition: width 1s ease-in-out;
}
.progress-value {
  position: absolute;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.5rem;
  color: #181b31;
  font-size: 1rem;
}
.filter-title {
  line-height: 1.625;
  color: #999999;
  font-weight: bold;
  text-transform: uppercase;
}
.filter-location {
  letter-spacing: -0.025em;
  font-size: 2.25rem;
}
.filter-location a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.filter-location a:hover,
.filter-location .active a {
  color: #02a6fd;
}
.job-item {
  margin-bottom: 4.625rem;
}
.job-item-title a {
  color: inherit;
}
.job-item-title {
  margin-bottom: 1rem;
}
.job-item-descr {
  line-height: 1.625;
  color: #666666;
}
.job-item-category {
  margin-top: 1.75rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
.timer-item,
.timer .divider {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.timer-item {
  min-width: 5.25rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  color: #999999;
}
.timer-item span {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-family: "Brandon", serif;
  color: #000000;
  line-height: 1;
}
.timer .divider {
  margin-top: 0.25rem;
}
.timer .divider:before {
  content: ":";
  font-size: 1.5rem;
  color: #999999;
  font-weight: bold;
}

@media (min-width: 576px) {
  .timer-item {
    min-width: 7.25rem;
    padding-left: 1.625rem;
    padding-right: 1.625rem;
  }
}

.stage-list {
  padding-bottom: 3.75rem;
}
.stage-item {
  background: #f5f5f5;
}
.stage-item-photo {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.stage-item-photo img {
  width: 100%;
}
.stage-item-detail,
.no-gutters > .stage-item-detail {
  padding: 4rem 4.5rem 3.5rem;
}
.stage-item .mask-skew .mask .inside {
  background-color: #f5f5f5;
}
.stage-item-num {
  margin-bottom: 2rem;
  font-size: 2.25rem;
  font-family: "Brandon", serif;
  font-weight: bold;
}
.stage-item-title {
  margin-bottom: 0.75rem;
  font-size: 2.75rem;
  font-weight: normal;
  letter-spacing: -0.025em;
}

@media (min-width: 1200px) {
  .stage-item-detail,
  .no-gutters > .stage-item-detail {
    padding: 7rem 7.5rem 6.625rem;
  }
  .stage-item-num {
    margin-bottom: 4.75rem;
  }
  .stage-item-title {
    font-size: 3.75rem;
  }
}

.member-item {
  margin-bottom: 4rem;
}
.member-item-photo {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 1.875rem;
}
.member-item-photo img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.member-item-name {
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
  font-family: "Brandon", serif;
  font-weight: bold;
}
.member-item-name a {
  color: inherit;
}
.member-item-status {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #666666;
}
.member-become {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  background: #f4f6f6;
  text-align: center;
  text-decoration: none;
}
.member-become .inside {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  padding: 1rem 10% 2rem;
  transform: translate(-50%, -50%);
}
.member-become .icon {
  display: block;
  font-size: 3.75rem;
  color: #cccccc;
}
.member-become-title {
  display: block;
  margin-top: 1.5rem;
  font-size: 1.875rem;
  line-height: 1.53333333;
  font-family: "Brandon", serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #000000;
}
.service-promo-item {
  background: #f8f8f9;
  margin-bottom: 6.25rem;
}
.service-promo-item:last-child {
  margin-bottom: 0;
}
.service-promo-item-photo {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-promo-item-photo img {
  width: 100%;
}
.service-promo-item-detail,
.no-gutters > .service-promo-item-detail {
  background: #f5f5f5;
  padding: 4rem 4.5rem 3.5rem;
}
.service-promo-item-title {
  margin-bottom: 18px;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}
.service-promo-item .nav.flex-column li {
  margin-bottom: 0.625rem;
}

@media (min-width: 1200px) {
  .service-promo-item-detail,
  .no-gutters > .service-promo-item-detail {
    padding: 5rem 3.75rem 5rem 6.125rem;
  }
}

.progress-item {
  border-top: #e2e2e2 1px solid;
  padding-top: 3rem;
  padding-bottom: 3.125rem;
}
.progress-item:first-child {
  border-top: none;
}
.progress-item-num {
  padding-bottom: 0.75rem;
  font-size: 6.25rem;
  font-weight: bold;
  color: #cccccc;
  line-height: 1.1;
}
.progress-item-title {
  margin-bottom: 0.5rem;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}

@media (min-width: 768px) {
  .fixed-footer .wrapper {
    position: relative;
    z-index: 200;
    margin-bottom: 51.875rem;
    background: #ffffff;
  }
  .fixed-footer-lg .wrapper {
    margin-bottom: 59.375rem;
  }
  .fixed-footer .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}

.footer-content {
  padding-top: 3rem;
  padding-bottom: 0rem;
  line-height: 1.625;
  color: #666666;
}
.contact-item,
.twitter-item {
  margin-bottom: 0.875rem;
}
.contact-item a,
.twitter-item a {
  color: #000000;
  text-decoration: underline;
}
.contact-item a:hover,
.twitter-item a:hover {
  text-decoration: none;
}
a.phone-link {
  color: inherit;
  text-decoration: none;
  cursor: default;
}
.social {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.footersocial {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-content .social {
  margin-top: 3.125rem;
}
.social li {
  display: inline-block;
  vertical-align: top;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
}
.social a {
  transition: color 0.2s ease-in-out;
}
.social-rounded {
  margin-right: -0.625rem;
  font-size: 20px;
}
.footersocial-rounded {
  font-size: 20px;
}
.social-rounded li {
  margin-right: 0.625rem;
  margin-bottom: 1rem;
}
.footersocial-rounded li {
  margin-right: 0.625rem;
  margin-bottom: 1rem;
}
.footersocial-rounded a {
  display: inline-block;
  border-radius: 2.5rem;
  width: 2.1rem;
  height: 2.5rem;
  color: #fff;
  border: #fff 1px solid;
  background: transparent;
  text-align: center;
  line-height: 2.3rem;
  transition: all 0.2s ease-in-out;
  margin-inline: 1rem;
}
.social-rounded a {
  display: inline-block;
  border-radius: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border: #cccccc 1px solid;
  background: transparent;
  text-align: center;
  line-height: 2.3rem;
  transition: all 0.2s ease-in-out;
}
.social-rounded a:hover {
  background: #02a6fd;
  border-color: #02a6fd;
  color: #ffffff !important;
}
.footersocial-rounded a:hover {
  background: #02a6fd;
  border-color: #02a6fd;
  color: #ffffff !important;
}
.social-rounded i {
  display: inline-block;
  vertical-align: middle;
  line-height: 0.75;
}
.footersocial-rounded i {
  display: inline-block;
  vertical-align: middle;
  line-height: 0.75;
}
.social-rounded i:before {
  display: block;
  line-height: 0.75;
}
.footersocial-rounded i:before {
  display: block;
  line-height: 0.75;
}
.footersocial-rounded .socicon-twitter,
.footersocial-rounded .socicon-facebook,
.footersocial-rounded .socicon-tumblr,
.footersocial-follow {
  font-size: 1rem;
}
.social-rounded .socicon-twitter,
.social-rounded .socicon-facebook,
.social-rounded .socicon-tumblr,
.social-follow {
  font-size: 1rem;
}
.social-rounded .socicon-rss {
  font-size: 0.875rem;
}
.footersocial-rounded .socicon-rss {
  font-size: 0.875rem;
}
.social-follow li {
  vertical-align: middle;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}
.social .social-title {
  padding-bottom: 0.125rem;
  margin-right: 1rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #999999;
}
.social-follow a {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.65;
  color: #ffffff;
}
.social-rounded .follow-facebook {
  background: #3b5999;
  border-color: #3b5999;
}
.social-rounded .follow-twitter {
  background: #55acee;
  border-color: #55acee;
}
.social-rounded .follow-googleplus {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-rounded.social-follow i {
  font-size: 0.75rem;
}
.social-rounded.social-follow .socicon-googleplus {
  font-size: 1rem;
}
.social-rounded .follow-tumblr {
  background: #2b4b6a;
  border-color: #2b4b6a;
}
.social-rounded .follow-rss {
  background: #fb7000;
  border-color: #fb7000;
}
.social-fixed,
.social-rounded-fixed {
  position: fixed;
  left: 15px;
  bottom: 0;
  z-index: 400;
}
.social-fixed li {
  display: block;
  margin-right: 0;
  margin-bottom: 2.125rem;
}
.social-rounded-fixed {
  left: auto;
  right: 15px;
  margin-right: -0.625rem;
  bottom: 4.62962963vmin;
}
.social-rounded-fixed li {
  margin-bottom: 0;
}
.social-rounded-dark a {
  border-color: #000000;
}
.socicon-facebook {
  font-size: 1.1875rem;
}
.socicon-googleplus {
  font-size: 1.375rem;
}
.ion-social-rss {
  font-size: 16px;
}
.ion-social-twitter,
.ion-social-vimeo,
.ion-social-facebook {
  font-size: 18px;
}
.twitter-date {
  margin-top: 0.625rem;
  color: #999999;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.footer .section-item-title {
  margin-bottom: 2.375rem;
}
.footertoprow {
  margin-bottom: 5rem;
  margin-top: 2rem;
}
.footertop {
  margin-top: 1rem;
  margin-bottom: 4rem;
}
.footertop h3 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #fff;
}
.footertop h4 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.footercopyright {
  margin-top: 2rem;
  font-size: 0.8rem;
  color: #fff;
}
.establised {
  display: none;
  position: fixed;
  left: 34px;
  top: 60%;
  z-index: 300;
  margin-top: -1.625rem;
  text-transform: uppercase;
  letter-spacing: 0.625rem;
  color: #999999;
  font-size: 1.125rem;
  transform: rotate(-90deg) translate(-50%, 0%);
  transform-origin: bottom left;
}
.copyright-fixed {
  position: fixed;
  left: 15px;
  padding-bottom: 0.5rem;
  bottom: 4.62962963vmin;
  z-index: 300;
  color: #000000;
  text-transform: uppercase;
}
.copyright-fixed i {
  margin-top: -2px;
  vertical-align: middle;
  margin-right: 0.75rem;
  font-size: 1.5rem;
  line-height: 0.75;
}
.copyright-fixed a {
  color: inherit;
}

@media (min-width: 576px) {
  .establised {
    display: block;
  }
}

@media (min-width: 1600px) {
  .social-fixed {
    left: 3.75rem;
  }
  .social-rounded-fixed {
    right: 3.75rem;
  }
  .copyright-fixed {
    left: 3.75rem;
  }
  .establised {
    left: 5rem;
  }
}

.site-info {
  margin-top: 2rem;
  margin-bottom: 0rem;
}

@media (min-width: 768px) {
  .footer-content {
    padding-top: 11.25rem;
    padding-bottom: 7.5rem;
  }
  .site-info {
    margin-top: 2rem;
    margin-bottom: 0rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .footer-content,
  .footer-content .text-right {
    text-align: center !important;
  }
}

.more.fs14 {
  letter-spacing: 0.05em;
}
.footer-red {
  background: #02a6fd;
  color: #ffffff;
}
.footer-red .footer-content {
  color: #ffcccc;
}

@media (min-width: 768px) {
  .fixed-footer-md .footer-content {
    padding-top: 15.92592593vmin;
    padding-bottom: 6.48148148vmin;
  }
  .fixed-footer-md .site-info {
    padding-top: 5.74074074vmin;
    padding-bottom: 5.74074074vmin;
  }
}

.footer-red .twitter-date,
.footer-red .social a {
  color: #ff9999;
}
.footer-red a,
.footer-red .contact-item a,
.footer-red .twitter-item a,
.footer-red .social a:hover,
.footer-red .section-item-title,
.footer-red .h2-3d {
  color: #ffffff;
}
.footer-red .site-info {
  border-top-color: #cc3333;
}
.theme-orange .navbar-nav a:before,
.theme-orange .menu-main a:before,
.theme-orange .menu-lang-item.active,
.theme-orange .menu-lang-item:hover,
.theme-orange .nav-toggle-btn:hover,
.theme-orange .text-primary,
.theme-orange a.text-primary:hover,
.theme-orange .social:not(.social-rounded) a:hover,
.theme-orange .article-item-category,
.theme-orange .article-item-category a,
.theme-orange .pager a:hover,
.theme-orange .article-item-info a:hover,
.theme-orange .article-item-mini .article-item-title a:hover,
.theme-orange .nav.flex-column .active a,
.theme-orange .nav.flex-column a:hover,
.theme-orange .btn-outline-secondary:hover,
.theme-orange .filter-location a:hover,
.theme-orange .filter-location .active a,
.theme-orange .nav-inline.text-black .active a,
.theme-orange .nav-inline.text-black a:hover {
  color: #02a6fd !important;
}
.theme-orange .nav-toggle-btn:hover .stick,
.theme-orange .social-rounded a:hover,
.theme-orange .btn-primary,
.theme-orange .owl-theme .owl-dots .owl-dot.active span:before,
.theme-orange .owl-theme .owl-dots .owl-dot:hover span:before {
  background-color: #3fa9f5;
}
.theme-orange .bg-primary {
  background-color: #3fa9f5 !important;
}
.theme-orange .btn-primary:not(:disabled):not(.disabled).active:focus,
.theme-orange .btn-primary:not(:disabled):not(.disabled):active:focus,
.theme-orange .show > .btn-primary.dropdown-toggle:focus,
.theme-orange .btn-primary.focus,
.theme-orange .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #0370aa;
}
.theme-orange .btn-primary:hover,
.theme-orange .btn-primary:not(:disabled):not(.disabled).active,
.theme-orange .btn-primary:not(:disabled):not(.disabled):active,
.theme-orange .show > .btn-primary.dropdown-toggle {
  background-color: #0370aa;
  border-color: #0370aa;
}
.theme-orange .social-rounded a:hover,
.theme-orange .btn-primary,
.theme-orange .owl-theme .owl-dots .owl-dot.active span,
.theme-orange .btn-outline-secondary:hover {
  border-color: #3fa9f5;
  border-radius: 40px;
}
.theme-yellow .navbar-nav a:before,
.theme-yellow .menu-main a:before,
.theme-yellow .menu-lang-item.active,
.theme-yellow .menu-lang-item:hover,
.theme-yellow .nav-toggle-btn:hover,
.theme-yellow .text-primary,
.theme-yellow .social:not(.social-rounded) a:hover,
.theme-yellow .brand-name {
  color: #0370aa !important;
}
.theme-yellow .nav-toggle-btn:hover .stick,
.theme-yellow .social-rounded a:hover,
.theme-yellow .btn-primary,
.theme-yellow .btn-play:hover {
  background-color: #0370aa;
}
.theme-yellow .social-rounded a:hover,
.theme-yellow .btn-primary,
.theme-yellow .btn-play {
  border-color: #0370aa;
}
.theme-white .navbar-nav > ul > li > a:before,
.theme-white .text-primary,
.theme-white .brand-name {
  color: #ffffff !important;
}
.theme-white .nav-toggle-btn:hover .stick,
.theme-white .social-rounded a:hover,
.theme-white .btn-primary,
.theme-white .btn-play:hover {
  background-color: #ffffff;
  color: #000000;
}
.theme-white .menu-main a:before,
.theme-white .menu-lang-item.active,
.theme-white .menu-lang-item:hover {
  color: #000000;
}
.body-menu-opened .theme-white .nav-toggle-btn:hover .stick {
  background-color: #000000;
}
.theme-white .social-rounded a:hover,
.theme-white .btn-primary,
.theme-white .btn-play {
  border-color: #ffffff;
}
.body-menu-opened .theme-white .social-rounded a:hover {
  border-color: #000000;
}

@media (min-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}

test#clients {
  width: 80% !important;
}
.bx-wrapper {
  box-shadow: none !important;
  border: none !important;
  background: #fff !important;
  margin: auto !important;
  width: 90% !important;
}
.bx-wrapper .bx-caption {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  background: rgba(80, 80, 80, 0.5) !important;
  width: 100% !important;
}
.bx-wrapper .bx-caption span {
  color: #fff !important;
  font-family: "Brandon" !important;
  display: block !important;
  font-size: 8px !important;
  padding: 10px !important;
  text-align: center;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}
/* .bx-wrapper .bx-prev {left:10px; background:url(images/controls.png) no-repeat 0 -32px;} */
/* .bx-wrapper .bx-next {right:10px; background:url(images/controls.png) no-repeat -43px -32px;} */
.logosImgs {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
.logosImgs img {
  max-height: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.65;
  transition: all 0.5s;
}
.logosImgs img:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
footer .elementfooter {
  position: absolute;
  top: 80px;
}
.footer .elementfooter {
  position: absolute;
  top: 80px;
}

@media screen and (max-width: 821px) {
  .logosImgs img {
    max-width: 125px;
    max-height: 88px;
    margin: 5px 8px;
  }
  .mtcol {
    display: none;
  }
  .footerimg {
    display: none;
  }
  .leftbg {
    display: none;
  }
  .elementbg {
    display: none;
  }
  .footerform {
    float: left;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .slide-bg,
  .slide-bg > .inside {
    background-position: 50% 50%;
  }
  .animationtext {
    display: none;
  }
  .choose-future {
    display: none !important;
  }
  /* .teammembers {
    display: none !important;
  } */
  video {
    width: 100% !important;
    height: 100vh !important;
    background-position: 50% 50%;
    background-size: cover;
  }

  .element1botWWA {
    right: 6%;
  }
  .element1botWWA img {
    height: 70%;
    width: 70%;
  }
  .contentpartwwd {
    float: left;
    left: 12%;
    /* top: 32%; */
    width: 80%;
    z-index: 10;
  }
  .contentpartserviceswwd {
    left: 0% !important;
    top: 15% !important;
    width: 100%;
  }
  .contentparthome {
    left: 12%;
    /* top: 40%; */
    width: 80%;
    z-index: 10;
  }
  .contentparttechnology {
    left: 12%;
    /* top: 19%; */
    width: 80%;
    z-index: 10;
  }
  .contentpartwwa {
    left: 12%;
    /* top: 32%; */
    /* top: 17%; */
    width: 80%;
    z-index: 10;
  }
  .techlogobg {
    background: #227aaa !important;
  }
  .techrighttxt {
    width: 80% !important;
    line-height: 3rem !important;
    color: #000033;
  }
  .contentparttechnology .boldtext {
    /* margin-top: 17%; */
    margin-top: 5%;
    font-size: 117%;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 560px) {
  .logosImgs img {
    max-width: 85px;
    max-height: 60px;
    margin: 3px 5px;
  }
  .contentpartserviceswwd {
    left: 0% !important;
    top: 25% !important;
    width: 100%;
  }
}

#circle {
  overflow: hidden;
}
#circle text {
  font-family: Brandon;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
#circle svg {
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -ms-animation-duration: 4s;
  -o-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}

@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}

@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

.historybiglogo {
  position: absolute;
  left: 2%;
  top: 6%;
  /* opacity: 1; */
  z-index: 1;
}

.pluselementwwa {
  position: absolute;
  left: 30%;
  top: 16%;
  z-index: 1;
}

.misviselementsmallx {
  position: absolute;
  left: 45%;
  top: 4%;
  z-index: 2;
  opacity: 0.6;
}

.award .section {
  padding: 0px;
}
.pluselementaward {
  position: absolute;
  left: 1%;
  top: 23%;
  z-index: 40;
  opacity: 1;
}
.pluselementaward1 {
  position: absolute;
  right: 0%;
  bottom: 0%;
  z-index: 40;
  opacity: 1;
}
.awardelementsmallx {
  position: absolute;
  right: 0%;
  bottom: -2%;
  z-index: 40;
  opacity: 1;
}
.awardelement1bot {
  position: absolute;
  left: 4%;
  bottom: 3%;
  z-index: 40;
  opacity: 1;
}

.partnerpluselement {
  position: absolute;
  left: 2%;
  top: 10%;
  z-index: 1;
}
.uparrowelementpartner {
  position: absolute;
  right: 6%;
  bottom: 54%;
  z-index: 1;
}

.contentpartpartner h3 {
  position: relative;
  margin-top: 56px;
  font-size: 2.3rem;
  text-align: right;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
  z-index: 2;
}

.rightpartpartner {
  z-index: 10;
  position: relative;
}

.rightpartpartner h3 {
  left: 10%;
  position: relative;
  font-size: 2em;
  text-align: left;
  /* line-height: 3.2rem; */
  margin-bottom: 6rem;
  letter-spacing: 0.035em;
  font-weight: bold;
  font-family: "Brandon";
  color: #fff;
  z-index: 2;
}

.rightpartpartner .normaltext {
  left: 10%;
  position: relative;
  font-size: 2rem;
  letter-spacing: 0.035em;
  text-align: left;
  line-height: 2.5rem;
  font-weight: medium;
  color: #fff;
  z-index: 2;
  text-decoration: underline;
  font-family: "Brandon";
}

.partners .slide-bg,
.partners .slide-bg > .partners .inside {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-position: 70% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.partners .slide-bg {
  overflow: hidden;
}

.partners .partner-list {
  z-index: 40;
}

.partners .partner-item img {
  filter: gray;

  -webkit-filter: grayscale(1);
  -webkit-transition: all 0.8s ease-in-out;
}
.partners .partner-item1 img {
  filter: gray;

  -webkit-filter: grayscale(1) brightness(2);
  -webkit-transition: all 0.8s ease-in-out;
}
.partners .partner-item1 img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}
.partners .partner-item img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}
.footer .getintouchwwa {
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 40;
}
.footerlogo {
  position: absolute;
  right: -1%;
  top: 3%;
  z-index: 300;
}
.footer .pluselementfootertop {
  position: absolute;
  top: 16%;
  left: 27%;
  z-index: 1;
}

.getintouchwwa .brand {
  position: absolute;
  left: 2%;
  top: 4%;
  z-index: 40;
}
.getintouchwwa .brand img {
  width: 120%;
}

.getintouchwwa .elementfooter {
  position: absolute;
  left: 4%;
  top: 23%;
  z-index: 2;
}

.listtext li {
  list-style-type: none;
  position: relative;
}

.ion-chevron-right {
  font-weight: 300;
  font-size: 0.7rem;
}
.ion-chevron-right:before {
  content: "\f125";
  padding-right: 5px;
}

.getintouch .brand {
  position: absolute;
  left: 2%;
  top: 4%;
  z-index: 40;
}
.getintouch .brand img {
  width: 120%;
}

.getintouch .elementfooter {
  position: absolute;
  left: 4%;
  top: 23%;
  z-index: 2;
}
.clientsbluebg {
  position: absolute;
  left: 0%;
  top: 25%;
  z-index: 5;
}

.pluselementteam {
  position: absolute;
  left: 42%;
  top: 9%;
  z-index: 1;
}

.team .slide-bg,
.team .slide-bg > .team .inside {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-position: 70% 50%;
  background-size: cover;
}
.team .slide-bg {
  overflow: hidden;
}

.water1elementteam {
  position: absolute;
  right: 0%;
  top: 17%;
  z-index: 10;
}

.semicircleelementteam {
  position: absolute;
  right: 15%;
  top: 12%;
  z-index: 1;
  animation: animName 20s linear infinite;
}

.waveselementdarkteams {
  position: relative;
  /* left: -25%; */
  z-index: 1;
}

.smallcircleelementteams {
  position: relative;
  left: -11%;
  bottom: 35px;
  z-index: 10;
  /* animation: animName 2s linear infinite; */
}

.serviceelementteams {
  position: absolute;
  right: 20%;
  bottom: 5%;
  z-index: 10;
}

.contentpartteam .gmimg {
  position: relative;
  /* margin-top: 18em; */
  margin-left: 0px;
  z-index: 5;
}

.gmsmsg p {
  font-size: 1.05rem;
  margin-bottom: 2rem;
  letter-spacing: 0.035em;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Brandon";
  color: #fff;
}

.teamselementsmallx {
  position: absolute;
  left: 60%;
  bottom: 7%;
  z-index: 20;
  opacity: 1;
}
.teamselement1bot {
  position: absolute;
  left: 29%;
  top: 0%;
  z-index: 40;
  opacity: 1;
}
.teams {
  position: absolute;
  left: 0%;
  top: 126%;
  text-align: center;
  width: 100%;
  z-index: 5;
  color: #fff;
}
.teams h5 {
  position: relative;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 4rem;
  letter-spacing: 0.035em;
  font-weight: 600;
  font-family: "Brandon";
  color: #fff;
}

.team .slide {
  background: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 150vh;
}
.team .slide-container {
  position: relative;
  min-height: 150vh;
  padding-top: 9rem;
  padding-bottom: 4rem;
}

.teammemberslider {
  text-align: center;
  padding: 30px 0px 30px 0px;
  position: relative;
}

.teammemberslider .pic {
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.teammemberslider .pic img {
  width: 100%;
  height: auto;
}

.teammemberslider .name {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: capitalize;
  margin: 10px 0 5px 0;
}

.teammemberslider .title {
  display: block;
  font-size: 14px;
  color: #ffd9b8;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.min-height-100vh {
  min-height: 100vh !important;
}
.choose-future {
  display: block;
}

.owl-carousel {
  display: block;
}

.clamph2 {
  font-size: clamp(0.75rem, 3vw, 1.5rem);
}

.clamph3 {
  font-size: clamp(0.5rem, 2vw, 1.25rem);
}

.clampp {
  font-size: clamp(10px, 2vw, 16px);
}

@media (max-width: 426px) {
  .verline img {
    height: 340px !important;
  }
  .botline img {
    width: 340px !important;
  }

  html > body .mb70 {
    margin-bottom: 1.375rem;
  }
  /* .contentpartwwd {
    top: 36% !important;
  } */
  #pp-nav {
    left: 35% !important;
  }
  /* .playpause {
    left: 43.5% !important;
  } */

  .element1botWWA {
    display: none;
  }
  .element2 {
    left: 70% !important;
  }
  .servicepage .ani-move {
    height: 100px;
    width: 200px;
  }
  /* .servicepage .serviceelement1bot {
    display: none !important;
  } */
  .servicepage .semicircledownelement {
    display: none !important;
  }
  .servicepage .semicircleelement {
    display: none !important;
  }
  .servicepage .semicircledownelementHA {
    display: none !important;
  }
  .servicepage .semicircleelementHA {
    display: none !important;
  }
  .servicepage .serviceroundtxttechnology {
    display: none !important;
  }
  .servicepage .serviceelement2 {
    display: none !important;
  }
  .servicepage .serviceroundtxttechnologyHA {
    display: none !important;
  }
  .servicepage .serviceroundtxttechnologyCR {
    display: none !important;
  }
  .servicepage .serviceelement2CR {
    display: none !important;
  }
  .servicepage .serviceelement1botCR {
    display: none !important;
  }
  .servicepage .serviceroundtxttechnologyPV {
    display: none !important;
  }
  .servicepage .serviceelement1botPV {
    display: none !important;
  }
  .servicepage .leftarrowHA {
    display: none !important;
  }
  /* .servicepage .elementsmallx {
    display: none !important;
  } */
  .servicepage .leftarrowRAIL {
    display: none !important;
  }
  .servicepage .leftarrowMG {
    display: none !important;
  }
  .servicepage .serviceelement2MICE {
    display: none !important;
  }
  .servicepage .serviceelement2PV {
    display: none !important;
  }
  .servicepage .elementsmallxTIDL {
    display: none !important;
  }
  .servicepage .serviceelement2support {
    display: none !important;
  }
  /* by me  */
  .servicepage .contentpartservicesAT {
    z-index: 2;
    position: relative;
    width: 100%;
    left: 0;
    padding-inline: 12px;
  }

  .servicepage .servciebiglogo-fixed {
    display: none;
  }

  .servicepage .contentpartservicesAT h3 {
    font-size: 2.5rem;
  }

  .servicepage .pluselement {
    /* left: 35% !important;
    top: 10% !important; */
    visibility: hidden;
  }
  .servicepage .pluselementHA {
    visibility: hidden;
  }
  .servicepage .contentpartservicesCR {
    width: 100% !important;
    z-index: 2;
    right: -4px;
    left: 0;
    position: relative;
    /* margin-inline: -3px; */
    flex-wrap: wrap;
    display: flex;
    /* margin-inline: 25px; */
    padding-inline: 29px;
  }
  .servicepage .contentpartservicesTIDL {
    width: 100%;
    z-index: 2;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-inline: 29px;
    right: 0;
  }
  .servicepage .contentpartservicesMICE {
    position: relative;
    width: 100%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    padding-inline: 24px;
  }
  .servicepage .contentpartservicesRAIL {
    width: 100% !important;
    z-index: 2;
    right: 0;
    /* left: 0; */
    position: relative;
    /* margin-inline: -3px; */
    flex-wrap: wrap;
    display: flex;
    /* margin-inline: 25px; */
    padding-inline: 25px;
  }
  .servicepage .contentpartservicesSUP {
    position: relative;
    width: 100%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    padding-inline: 24px;
  }
  .servicepage .contentpartservicesPV {
    position: relative;
    width: 100% !important;
    left: 0;
    display: flex;
    z-index: 2;
    flex-wrap: wrap;
    padding-inline: 24px;
  }

  .servicepage .contentpartservicesMG {
    width: 100%;
    z-index: 2;
    position: relative;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    padding-inline: 24px;
  }

  .servicepage .contentpartservicesHA {
    position: relative;
    width: 100%;
    right: 0;
    /* display: flex; */
    flex-wrap: wrap;
    padding-inline: 12px;
  }
  .servicepage .smallcircleelementHA {
    position: absolute;
    right: 17%;
    bottom: 12%;
    z-index: 1;
    animation: animName 2s linear infinite;
  }
  .servicepage .contentpartservicesHA .normaltext {
    letter-spacing: initial !important;
  }
  .servicepage .pluselementfootertop {
    display: none !important;
  }
  .servicepage .pluselementfooter {
    display: none !important;
  }
  .servicepage .footerlogo {
    display: none !important;
  }
  .servicepage .elementfooter {
    display: none !important;
  }
  .servicepage .servicefooterimg {
    width: 150px;
  }
  .servicepage .footersub {
    margin-bottom: 20px !important;
  }
  /* by me  */
  /* .servciebiglogoha-fixed {
    visibility: hidden;
  } */
  /* .servciebiglogo-fixed {
    visibility: hidden;
  } */
  .servicepage .pluselementRAIL {
    visibility: hidden;
  }
  .servicepage .pluselementCR {
    visibility: hidden;
  }
  .servciebiglogo-fixedMICE {
    visibility: hidden;
  }
  .servicepage .pluselementTIDL {
    visibility: hidden;
  }
  .servicepage .contentpartservicesHA h3 {
    width: -webkit-fill-available;
    font-size: 2.5rem;
  }
  footer .pluselementfootertop {
    display: none !important;
  }
  footer .pluselementfooter {
    display: none !important;
  }
  footer .footerlogo {
    display: none !important;
  }
  footer .elementfooter {
    display: none !important;
  }
  footer .servicefooterimg {
    width: 150px;
  }
  footer .footersub {
    margin-bottom: 20px !important;
  }
  footer .uparrowelementfooter {
    left: 93%;
    bottom: 14%;
  }
  footer .slide [class^="col-"] {
    position: static;
    margin-left: 0px;
  }
  footer .slide [class^="col-"] {
    margin-left: 10px;
  }
  .footersocial-rounded {
    padding-top: 10px;
    font-size: 20px;
    justify-content: space-evenly;
  }
}

/* service page for tab view  */
@media screen and (min-width: 768px) {
  /* .servicepage .semicircledownelement {
    visibility: hidden;
  } */
  .servicepage .serviceelement2 {
    position: absolute;
    left: 8%;
    bottom: 20%;
    z-index: 2;
  }
  .servicepage .pluselement {
    position: absolute;
    left: 26%;
    top: 12%;
    z-index: 1;
  }
  /* .servicepage .semicircledownelementHA {
    visibility: hidden;
  } */
  .servicepage .serviceroundtxttechnology {
    position: absolute;
    right: 9%;
    bottom: 30%;
    z-index: 1;
  }
  .servicepage .leftarrowHA {
    position: absolute;
    right: 11%;
    top: 69%;
    z-index: 2;
    opacity: 1;
  }
  .servicepage .serviceelement2CR {
    position: absolute;
    left: 8%;
    bottom: 27%;
    z-index: 2;
  }
  .servicepage .leftarrowRAIL {
    position: absolute;
    right: 13%;
    top: 67%;
    z-index: 2;
    opacity: 1;
  }
  .servicepage .serviceelement2PV {
    position: absolute;
    left: 8%;
    bottom: 19%;
    z-index: 2;
  }
  .servciebiglogoha-fixed {
    /* visibility: hidden; */
  }
  /* .servciebiglogo-fixed {
    visibility: hidden;
  } */
  .serviceelement2support {
    position: absolute;
    left: 8%;
    bottom: 29%;
    z-index: 2;
  }
  .elementsmallxTIDL {
    position: absolute;
    left: 50%;
    bottom: 2%;
    z-index: 2;
    opacity: 1;
  }
  .servicepage .serviceelement2MICE {
    position: absolute;
    left: 8%;
    bottom: 23%;
    z-index: 2;
  }
  .smallcircleelement {
    position: absolute;
    left: 62%;
    top: 25%;
    z-index: 1;
    /* animation: animName 2s linear infinite; */
  }
  .leftarrowMG {
    position: absolute;
    right: 11%;
    top: 70%;
    z-index: 2;
    opacity: 1;
  }
  .servicepage .pluselementHA {
    position: absolute;
    right: 40%;
    top: 3%;
    z-index: 1;
  }
  .servicepage .pluselementTIDL {
    position: absolute;
    right: 57%;
    top: 3%;
    z-index: 1;
  }
  /* .servicepage .contentpartservicesHA h3 {
    margin-left: 10px;
  } */
}

/* @media (min-width: 427px) and (max-width: 769px) { */
@media (min-width: 427px) and (max-width: 821px) {
  .pluselementfootertop {
    display: none !important;
  }
  .pluselementfooter {
    display: none !important;
  }
  .technologyjs .techpluselement {
    left: 40% !important;
    top: 35% !important;
  }
  .technologyjs .techbotline {
    display: none !important;
  }
  .technologyjs .smlogo {
    top: 25% !important;
  }
  footer .elementfooter {
    top: 33px;
  }
}
/* ////////////////////////////////  */

@media screen and (min-width: 768px) {
  /* .footersocial-rounded {
    justify-content: space-evenly;
  } */
}
/* ///////////////////////////////////////////////// */
/* mobile view for menu bar  */
@media (max-width: 426px) {
  .menu-footer {
    padding-top: 2rem;
    margin-right: -2.2rem;
  }
  .menu .menu-lang {
    display: flex !important;
    flex-direction: column !important;
    font-size: 12px;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin-right: -3rem;
  }
  .menu-lang-item,
  .menu .menu-lang-itemlast {
    margin-right: 1rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 11px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
    margin-right: -2rem;
  }
  .menu .menu-main {
    margin-right: -59px;
  }
  .menu .form-control {
    text-align: center;
    display: block;
    width: 106%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 11%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
/* laptop view for menubar  */
@media screen and (min-width: 1024px) {
  .menu-footer {
    padding-top: 2rem;
    margin-right: -2.2rem;
  }
  .menu .menu-lang {
    font-size: 12px;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin-right: -3rem;
  }
  .menu-lang-item,
  .menu .menu-lang-itemlast {
    margin-right: 1rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 11px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
    margin-right: -2rem;
  }
  .menu .menu-main {
    margin-right: -59px;
  }
  .menu .form-control {
    display: block;
    width: 57%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.25rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 48%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .biglogo-fixed {
    visibility: hidden;
  }
}
/* mobile -M for menu bar  */
@media only screen and (min-device-width: 375px) and (max-device-width: 537px) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 82%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 27%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

/* ipad mini for menu bar  */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .menu .form-control {
    display: block;
    width: 58%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 48%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

/* ipad 12 pro  for menu bar  */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 90%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 21%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 1.4rem;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }
  .menu .menu-lang {
    display: inline-flex !important;
    flex-direction: column !important;
    font-size: 14px;
    padding-bottom: 1rem;
    padding-top: 0rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 14px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
  }
}

/* 8 plus  */
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 103%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 9%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 1.4rem;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }
  .menu .menu-lang {
    display: grid;
    font-size: 14px;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin-right: -3rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 13px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
  }
}

/* samsung galaxy ultra  for menu view */
@media (width: 412px) and (height: 915px) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 90%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 22%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 1.4rem;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }
  .menu .menu-lang {
    display: flex !important;
    flex-direction: column !important;
    font-size: 14px;
    padding-bottom: 1rem;
    padding-top: 0rem;
  }
  .menu-lang-item {
    margin-right: 0px !important;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 13px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
  }
}
/* for service hotel accomaodation  */
/* @media (min-width: 412px) {
  .servicepage .contentpartservicesHA h3 {
    margin-left: 71px;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    letter-spacing: 0.035em;
    text-align: right;
    font-weight: 600;
    font-family: "Brandon";
    color: #fff;
  }
} */

/* mobile -L for menu bar  */
@media only screen and (min-device-width: 425px) and (max-device-width: 537px) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 70%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 39%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }
  .menu-main > ul > li {
    margin-bottom: 0.5rem;
    text-align: right;
    margin-right: 8%;
  }
  .menu-lang-item {
    margin-right: 1rem !important;
  }
}

/* iphone XR for menu bar  */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 89%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 20%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 1.4rem;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }
  .menu .menu-lang {
    display: grid;
    font-size: 14px;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin-right: -3rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 14px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
    margin-right: -2rem;
  }
}

/* ipad Air for menu bar  */
@media screen and (min-width: 820px) {
  .biglogo-fixed {
    visibility: hidden;
  }
  .menu .form-control {
    text-align: center;
    display: block;
    width: 96%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 14%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 19px;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }

  .menu .menu-lang {
    display: flex !important;
    flex-direction: column !important;
    font-size: 14px;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin-right: -3rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 13px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
  }
}
/* laptop - Large  */
@media screen and (min-width: 1440px) {
  .menu .form-control {
    text-align: center;
    display: block;
    width: 94%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 14%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

/* 16 inch mac 3072 * 1929 px  */
@media only screen and (min-device-pixel-ratio: 2) and (min-resolution: 3072x1920) {
  .verline img {
    height: 1300px !important;
  }
  .contentparthome h3 {
    font-size: 54px;
    margin-bottom: 2rem;
    letter-spacing: -0.035em;
    font-weight: bold;
    font-family: "Brandon";
    color: #b3b3b3;
  }
  .contentparthome .boldtext {
    font-size: 56px;
    margin-bottom: 2rem;
    letter-spacing: -0.035em;
    line-height: -0.7em;
    font-weight: bold;
    font-family: "Brandon";
    color: #fff;
  }
  .contentparthome .normaltext {
    font-size: 54px;
    margin-bottom: 3rem;
    letter-spacing: -0.035em;
    line-height: -0.7em;
    font-weight: normal;
    font-family: "Brandon";
    color: #fff;
  }
  .rmbtn {
    display: flex;
    position: absolute;
    bottom: -25%;
    left: 0;
    font-size: 51px;
  }
}
/* tabview for menu bar  */

@media screen and (min-width: 768px) {
  .menu-footer {
    padding-top: 2rem;
    margin-right: -2.2rem;
  }
  .menu .menu-lang {
    display: flex !important;
    flex-direction: row !important;
    font-size: 12px;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin-right: -3rem;
  }
  .menu-lang-item,
  .menu .menu-lang-itemlast {
    margin-right: 1rem;
  }
  .menu-copyright {
    margin-top: 0px;
    font-size: 11px;
    line-height: 1.71428571;
    color: #ffffff;
    text-transform: capitalize;
    margin-right: -2rem;
  }
  .menu .menu-main {
    margin-right: -59px;
  }
  .menu .form-control {
    text-align: center;
    display: block;
    width: 86%;
    text-align: center;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.25rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f7f7f8;
    background: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    margin-left: 20%;
    margin-bottom: 3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .menu-main > ul {
    margin-right: -12px;
  }
  .menu-main > ul > li > a,
  .menu-main > ul > li > span {
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
  }
}

/*career page*/
.carbpluselement {
  position: absolute;
  left: 26%;
  top: 27%;
  z-index: 1;
}
.cararelement {
  position: absolute;
  left: 13%;
  bottom: 10%;
  z-index: 2;
}
.carblunbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: auto;
  width: 100%;
}
.carblunbg h5 {
  font-size: 1.5rem;
  text-align: left;
  margin-left: 5%;
  font-weight: 600;
  padding: 10px;
  color: #fff;
  margin-top: 50px;
  margin-bottom: 20px;
}
.dblunbg {
  background: #17355d;
  height: auto;
  width: 100%;
}
.dblunbg h5 {
  font-size: 1.3rem;
  text-align: center;
  margin-left: 0%;
  font-weight: 500;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 20px;
}
.pt50 {
  padding-top: 7rem;
}
.dblunbg .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333 !important;
  background: #fff !important;
  background-clip: padding-box;
  border-radius: 2rem;
  margin-left: 0%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: left;
  margin: 0 auto;
}
.careerform {
  float: left;
  position: relative;
  margin-right: 50px;
  width: 25%;
}
.careerform h5 {
  font-size: 1.3rem;
  text-align: left;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #fff;
}
.carformpart {
  margin: 0% 0 0 13%;
}
.carimgtitle {
  font-size: 1.2rem;
  letter-spacing: 0rem;
  font-weight: 600;
  color: #fff;
  padding-left: 1rem;
  margin-top: -15px;
}
.carbnbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: 105rem;
  width: 100%;
}
.carimgrbbtn {
  position: absolute;
  top: 80%;
  left: 60%;
  z-index: 5;
}
.carimgrbbtn1 {
  position: absolute;
  top: 76%;
  left: 60%;
  z-index: 5;
}
.carimgarelement {
  position: absolute;
  left: 63%;
  bottom: 26%;
  z-index: 2;
}
.car1 .blogpartpluselement {
  position: absolute;
  margin-top: 0rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  z-index: 1;
}
.car1 .blogelementsmallx {
  position: absolute;
  left: 78%;
  top: 2%;
  z-index: 2;
  opacity: 1;
}
.car1 h5 {
  position: absolute;
  left: 15.5%;
  top: 6%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.car1 h6 {
  position: absolute;
  left: 15.5%;
  top: 14%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.car2 .blogpartpluselement {
  position: relative;
  margin-top: -2rem;
  margin-left: 0rem;
  z-index: 1;
}
.car2 .blogelementsmallx {
  position: absolute;
  left: 93%;
  top: -11%;
  z-index: 2;
  opacity: 1;
}
.car2 h5 {
  position: absolute;
  left: 18.5%;
  top: 8%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.car2 h6 {
  position: absolute;
  left: 18.5%;
  top: 43%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}

/*career detail page*/
.cdetext {
  font-size: 1.2rem;
  color: #c4c4c4;
  line-height: 2.3rem;
  letter-spacing: 0.1rem;
  margin-left: 10rem;
  margin-top: 12.5rem;
  width: 81%;
}
.cdetext h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #c9c9c9;
  padding: 0px;
  padding-bottom: 0rem;
  margin-left: 2rem;
}
.cdetext h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  margin-left: 2rem;
  padding: 0px;
  padding-bottom: 0.5rem;
  letter-spacing: 0.1rem;
  width: 93%;
}
.cdetext p {
  font-size: 2.2rem;
  color: #fff;
  font-weight: 600;
  line-height: 2.3rem;
  margin-top: 20px;
  letter-spacing: 0.1rem;
  margin-left: 2rem;
}
.cdpluselement {
  position: absolute;
  left: 14%;
  top: 43%;
  z-index: 1;
}
.marl5 {
  margin-left: 5%;
}
.cdedestext {
  font-size: 1.2rem;
  color: #ffff;
  line-height: 2.5rem;
  margin-top: 4%;
  margin-left: 15%;
  letter-spacing: 0.1rem;
  text-align: left;
}
.cdedestext h2 {
  font-size: 3rem;
  color: #ffff;
  line-height: 3.5rem;
  letter-spacing: 0.5rem;
  text-align: left;
  margin-top: -2rem;
}
.cdedestextsmallx {
  position: absolute;
  left: -9%;
  top: -8%;
  z-index: 2;
  opacity: 1;
}
.cdedestext h4 {
  font-size: 1.8rem;
  color: #ffff;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: left;
  position: absolute;
  right: 22%;
  top: 17%;
}
.cdedestext h5 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffff;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: left;
  position: absolute;
  right: 22%;
  top: 74%;
}
.cdecontent {
  font-size: 1.1rem;
  color: #ffff;
  line-height: 1.8rem;
  margin-top: 4%;
  margin-left: 4%;
  letter-spacing: 0.1rem;
  text-align: left;
}
.cdecontent1 {
  font-size: 1.1rem;
  color: #ffff;
  line-height: 1.8rem;
  margin-top: 4%;
  margin-left: 3%;
  letter-spacing: 0.1rem;
  text-align: left;
}
.cdcontentpluselement {
  position: absolute;
  left: -6%;
  top: -1.2%;
  z-index: 1;
}
.cdcontentpluselement1 {
  position: absolute;
  left: -6%;
  top: 59%;
  z-index: 1;
}
.cdcontentpluselement2 {
  position: absolute;
  left: -6%;
  top: 88%;
  z-index: 1;
}
.cdecontent h5 {
  font-size: 1.3rem;
  font-weight: 700;
  color: #ffff;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: left;
  margin-bottom: 3rem;
}
.cdecontent2 h5 {
  font-size: 1.3rem;
  font-weight: 700;
  color: #ffff;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: left;
  margin-bottom: 3rem;
}
.cdecontent3 h5 {
  font-size: 1.3rem;
  font-weight: 700;
  color: #ffff;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: left;
  margin-bottom: 3rem;
}
.cdebnbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  width: 100%;
}
.cdebnbg .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333 !important;
  background: #fff !important;
  background-clip: padding-box;
  border-radius: 2rem;
  margin-left: 0%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: left;
  margin: 0 auto;
}
.cdeform {
  float: left;
  position: relative;
  margin-right: 50px;
  width: 40%;
}
.cdeform h5 {
  font-size: 1.2rem;
  text-align: left;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #fff;
}
.cdeformpart {
  margin: 3% 0 0 13%;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 30%;
}
.socontentpart {
  font-size: 1.3rem;
  color: #fff;
  line-height: 2.3rem;
  margin-bottom: 5rem;
  margin-top: 3rem;
  z-index: 5;
}
.socontentpart h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  left: -27.5rem;
  top: 7rem;
  letter-spacing: 0.1rem;
  position: relative;
}
.sosmallxbn {
  position: absolute;
  margin-left: 5rem;
  margin-top: 1rem;
  z-index: 2;
  opacity: 1;
}
.ptso10 {
  padding-top: 10rem;
}
.cdepagebg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: 115rem;
  width: 100%;
}
.capimg {
  left: -8rem;
  top: 1.5rem;
  position: relative;
  z-index: 11;
}
/* 
.opacityBG {
  position: relative;
}
.opacityBG::before {
  content: "";
  background-color: rgb(0, 45, 98, 0.3) !important;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
.opacityBG img {
  display: block;
  width: 100%;
  height: 100%;
}

.blunbg {
  background: #29a8df;
  height: 15rem;
  width: 100%;
}
.blunbg1 {
  background: #29a8df;
}
.blunbg2 {
  background: rgba(2, 112, 183, 1);
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.darkblunbg1 {
  background: #17355d;
}
.opacityBG1 img {
  filter: contrast(50%);
}

@media (max-width: 430px) {
  .brand-logo {
    height: 70%;
    width: 70%;
  }
  /* .footerimage{
  height:200px;
}
.footerimage img{
  height:200px;
}
.rotatee img{
  height:105px;
  width:105px;
} */
}
.marginleftforfooter {
  margin-left: -50px;
}
/* .marginleftforfooter1{
  margin-left:0 !important;
} */

.textwrap-no {
  text-wrap: nowrap;
}

.map-canvas {
  height: 500px;
  overflow: hidden;
  background-color: #f9f9f9;
}
.map-canvas iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .map-canvas {
    height: 400px;
  }
}

.filter-opacity {
  filter: opacity(0.8);
}
.marginleftforfooter1 {
  margin-left: -100px;
}
.marginleftforfooter {
  margin-left: -50px;
}

.marginleftforfooter h2 {
  font-size: 20px;
}
.notfound .error {
  font-size: 150px;
  color: #fff;
  text-shadow: 1px 1px 1px #c0c0c0, 2px 2px 1px #c0c0c0, 3px 3px 1px #c0c0c0,
    4px 4px 1px #c0c0c0, 5px 5px 1px #c0c0c0, 6px 6px 1px #c0c0c0,
    7px 7px 1px #c0c0c0, 8px 8px 1px #c0c0c0, 25px 25px 8px rgba(0, 0, 0, 0.2);
}
.notfound {
  background-color: #17355d;
}

.cargo {
 width: 50%;
}
