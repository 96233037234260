.spacecargoourservices{
    background-image: url("../img/woman-safety-equipment-work.jpg");
    object-fit: contain;
    /* resize: auto; */
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: cover;
    /* filter: opacity(0.5); */
}


.plustitle-ml-60{
 margin-left: -60px;
}

.transperentbgblue{
    background-color: rgb(2, 112, 183,0.3);
}
.transperentbgblue2{
    background-color: rgb(41, 144, 210 ,0.75);
}

.text-align-justify{
    text-align: justify;
}

.stats-bg{
    background-image: url("../img/shipping-industry-carries-cargo-large-container-ships-generative-ai.jpg");
    object-fit: contain;
    /* resize: auto; */
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: cover;
}

.stwaveselementdark{
        position: absolute;
        left: 0%;
        bottom: 4%;
        z-index: 1;
}
.stwater1element {
    position: absolute;
    right: 0%;
    top: 0;
    z-index: 1;
}

.stcontentpartservicesAT {
    position: absolute;
    left: 8%;
    top: 25%;
    text-align: justify;
}

.stbiglogoservicepng{
    left: -3%;
    top:5%;
    width: 50%;
    height: 50%;

}
.stparentuparrowelement{
    position: absolute;
    right: 7%;
    bottom: 15%;
    z-index: 1;
}
.stuparrowelement{
        margin-left: -60px;
        margin-top: 60px;
    }

    /* .sttitle{
        font-size: 3rem !important;
        font-weight: 700 !important;
        letter-spacing: 2px;
    } */


  .lufthansacards{
     height: 100vh;
     display: flex;
     justify-content: center;
  }  
  
  



.lufcontainer{
    padding: 1em 0;
    float: left;
    width: 50%;
  }
  @media screen and (max-width: 640px){
    .lufcontainer{
      display: block;
      width: 100%;
    }
  }
  
  @media screen and (min-width: 900px){
    .lufcontainer{
      width: 33.33333%;
    }
  }
  
  .lufcontainer .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .lufcontent {
    position: relative;
    width: 95%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
  }
  
  .lufcontent .lufcontent-overlay {
    background: #17355d;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 2;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  .lufcontent .lufcontent-overlay2 {
    background: #17355d55;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .lufcontent:hover .lufcontent-overlay{
    opacity: 1;
  }
  
  .lufcontent-image{
    width: 100%;
    height: 120vh;
    object-fit: cover;
  }
  
  .lufcontent-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    z-index: 2;
  }
  
  .lufcontent:hover .lufcontent-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .lufcontent-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .lufcontent-details p{
    color: #fff;
    font-size: 0.8em;
  }
  
  .luffadeIn-bottom{
    top: 80%;
  }
/*   
  .fadeIn-top{
    top: 20%;
  }
  
  .fadeIn-left{
    left: 20%;
  }
  
  .fadeIn-right{
    left: 80%;
  } */

  .lufcontent-text {
    font-size:1.1rem; 
    color:#ffff; 
    line-height:1.8rem; 
    margin-top:4%; 
    margin-left:4%; 
    letter-spacing:0.1rem; 
    text-align:left;
}