.globalbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: auto;
  width: 100%;
}
.text p {
  /* font-size: 1.1rem; */
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  display: flex;
  text-align: justify;
  font-family: "Brandon";
}
.readbutton {
  margin-left: 34px;
}
.blunbgglobal {
  background: #29a8df;
  height: auto;
  width: 100%;
}

.globalformpart .btn-primary {
  background: #17355d;
}
.globalformpart h4 {
  /* font-size: 1.7rem; */
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.09rem;
  color: #17355d;
  margin: 10px;
  padding: 0px;
  text-align: center;
}
.globalformpart .globalnormaltxt {
  /* font-size: 1.2rem; */
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}
.globalformtext {
  font-size: 0.75rem;
  font-weight: normal;
  color: #17355d;
  text-align: justify;
  line-height: normal;
}
.globalformtext span {
  color: #fff;
  margin: 0px;
  padding: 0px;
}
.globalformpart {
  padding: 4%;
}
.globalformpart .responsive {
  width: 100%;
  height: -webkit-fill-available;
}
.globalbnbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 5%,
    rgba(85, 81, 140, 1) 100%
  );
  height: auto;
  width: 100%;
}
.textglobalp p {
  /* font-size: 1.1rem; */
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  justify-content: center;

  display: flex;
  align-items: center;
  text-align: justify;
}
.globallogo {
  justify-content: center;
  justify-self: center;
  display: flex;
  height: fit-content;
  object-fit: contain;
}
.globallogo img {
  display: flex;
  justify-self: center;
  justify-content: center;
  object-fit: contain;
}
.lineglobal {
  width: 100%;
  justify-self: center;
  display: flex;
  color: #fff;
  border: none;
  border-top: clamp(1px, 3px, 3px) dashed #fff;
  margin: 0;
  padding: 0;
}
.lineglobal1 {
  margin: 0 0 50px 0;
  display: block;
  border: none;
  height: 7px;
  background: #0071b9;
  background: linear-gradient(
    to right,
    #0071b9,
    #26abff,
    white,
    #26abff,
    white,
    white
  );
}

.smlogoglobal {
  position: absolute;
  left: -1%;
  top: 18%;
}

.bntextglobal {
  font-size: 1.1rem;
  color: #c4c4c4;
  margin-left: 4%;
  /* margin-top: 23%; */
  margin-top: 15%;
  width: 85%;
}
.bntextglobal h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #c9c9c9;
  padding: 0px;
  padding-bottom: 0rem;
  margin-left: 2rem;
}
.bntextglobal h3 {
  /* font-size: 4rem; */
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-left: 2rem;
  padding: 0px;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px #c4c4c4;
  letter-spacing: 0.1rem;
  width: 100%;
  margin-top: -4%;
}
.bntextglobal p {
  /* font-size: 1.1rem; */
  font-size: 16px;
  color: #fff;
  text-align: justify;
  margin-top: 20px;
  font-weight: normal;
  margin-left: 2rem;
}

.pluselementglobal {
  position: absolute;
  left: 26%;
  top: 27%;
  z-index: 1;
}
.downplus {
  position: absolute;
  right: 1%;
  bottom: 9%;
  z-index: 1;
}

.roundtextanimation {
  position: absolute;
  top: 45px;
  right: 40px;
  animation: animName 12s linear infinite;
  z-index: 2;
}
.roundtextanimation img {
  width: 220px;
  height: 220px;
}

.roundtxtanimationplus {
  position: absolute;
  right: 65px;
  top: 73px;
  z-index: 2;
}
@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.uparrowelementall {
  position: absolute;
  right: 3%;
  bottom: 8%;
  z-index: 1;
}
.downanimationelement {
  position: absolute;
  right: 54%;
  bottom: 1%;
  z-index: 2;
  opacity: 1;
}

/* Mobile S */
@media only screen and (max-width: 320px) {
  /* Styles for Mobile S go here */
  .text p {
    font-size: 1rem;
  }
  .textglobalp p {
    font-size: 1rem;
  }
  .bntextglobal p {
    width: 100%;
    font-size: 1rem;
  }
  .smlogoglobal img {
    height: 105px;
    width: 105px;
  }
  .pluselementglobal img {
    height: 100px;
    width: 100px;
  }
  .pluselementglobal {
    left: 31%;
    top: 22%;
  }
  .bntextglobal h3 {
    /* font-size: 2.2rem; */
    font-size: 20px;
    width: 100%;
  }
  .bntextglobal {
    /* line-height: 2.1rem; */
    margin-left: 1%;
    margin-top: 46%;
    width: 88%;
  }
  .roundtextanimation {
    position: absolute;
    top: 73px;
    right: 38px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 105px;
    width: 105px;
  }
  .roundtxtanimationplus img {
    height: 100px;
    width: 100px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 42px;
    top: 78px;
    z-index: 2;
  }
  .downplus img {
    height: 115px;
    width: 115px;
  }
  .uparrowelementall img {
    height: 80%;
    width: 80%;
  }
  .downanimationelement {
    display: none;
  }
  .leftarrowanimationelement img {
    height: 9px;
    width: 70px;
  }
}

/* Mobile M */
@media only screen and (min-width: 321px) and (max-width: 376px) {
  /* Styles for Mobile M go here */
  .textglobalp p {
    font-size: 1rem;
  }
  .bntextglobal p {
    width: 100%;
    font-size: 1rem;
  }
  .smlogoglobal img {
    height: 110px;
    width: 110px;
  }
  .pluselementglobal img {
    height: 105px;
    width: 105px;
  }
  .pluselementglobal {
    left: 28%;
    top: 22%;
  }
  .bntextglobal h3 {
    font-size: 2.4rem !important;
    width: 100%;
  }
  .bntextglobal {
    margin-left: 1%;
    margin-top: 43%;
  }
  .roundtextanimation {
    position: absolute;
    top: 73px;
    right: 46px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 118px;
    width: 118px;
  }
  .roundtxtanimationplus img {
    height: 110px;
    width: 110px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 50px;
    top: 78px;
    z-index: 2;
  }
  .downplus img {
    height: 115px;
    width: 115px;
  }
  .uparrowelementall img {
    height: 80%;
    width: 80%;
  }
  .downanimationelement {
    display: none;
  }
  .leftarrowanimationelement img {
    height: 10px;
    width: 70px;
  }
}
/* Mobile L */
@media only screen and (min-width: 376px) and (max-width: 430px) {
  /* Styles for Mobile L go here */
  .textglobalp p {
    font-size: 1rem;
  }
  .bntextglobal p {
    width: 100%;
    font-size: 1rem;
  }
  .smlogoglobal img {
    height: 110px;
    width: 110px;
  }
  .pluselementglobal img {
    height: 110px;
    width: 110px;
  }
  .pluselementglobal {
    display: none;
  }
  .bntextglobal h3 {
    /* font-size: 2.5rem; */
    font-size: 20px;
    width: 100%;
  }
  .bntextglobal {
    margin-left: 1%;
    margin-top: 40%;
    width: 85%;
  }
  .roundtextanimation {
    position: absolute;
    top: 65px;
    right: 51px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 130px;
    width: 130px;
  }
  .roundtxtanimationplus img {
    height: 110px;
    width: 110px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 60px;
    top: 78px;
    z-index: 2;
  }
  .downplus img {
    height: 115px;
    width: 115px;
  }
  .uparrowelementall img {
    height: 80%;
    width: 80%;
  }
  .downanimationelement {
    display: none;
  }
  .leftarrowanimationelement img {
    height: 10px;
    width: 70px;
  }
}
/* Small screens */
@media only screen and (min-width: 431px) and (max-width: 767px) {
  /* Styles for small screens go here */
  .mannaiholidaytextp {
    font-size: 1rem;
  }
  .textglobalp p {
    font-size: 1rem;
  }
  .smlogoglobal img {
    height: 140px;
    width: 140px;
  }
  .downplus img {
    height: 115px;
    width: 115px;
  }
  .uparrowelementall img {
    height: 80%;
    width: 80%;
  }
  .pluselementglobal img {
    height: 115px;
    width: 115px;
  }
  .downanimationelement img {
    height: 80%;
    width: 80%;
  }
  .pluselementglobal {
    left: 28%;
    top: 26%;
  }
  .bntextglobal h3 {
    font-size: 3.5rem;
  }
  .roundtextanimation {
    position: absolute;
    top: 80px;
    right: 75px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 110px;
    width: 110px;
  }
  .roundtxtanimationplus img {
    height: 120px;
    width: 120px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 70px;
    top: 78px;
    z-index: 2;
  }
  .downplus img {
    height: 115px;
    width: 115px;
  }
  .uparrowelementall img {
    height: 80%;
    width: 80%;
  }
  .downanimationelement img {
    height: 80%;
    width: 80%;
  }
  .leftarrowanimationelement img {
    height: 10px;
    width: 70px;
  }
}
/* Medium screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Styles for medium screens go here */
  .textglobalp p {
    font-size: 1rem;
  }
  .mannaiholidaytextp {
    font-size: 1rem;
  }
  .smlogoglobal img {
    height: 140px;
    width: 140px;
  }
  .downplus img {
    height: 115px;
    width: 115px;
  }
  .uparrowelementall img {
    height: 80%;
    width: 80%;
  }
  .pluselementglobal img {
    height: 115px;
    width: 115px;
  }
  .downanimationelement {
    position: absolute;
    right: 44%;
  }
  .downanimationelement img {
    height: 80%;
    width: 80%;
  }
  .pluselementglobal {
    left: 28%;
    top: 26%;
  }
  .bntextglobal h3 {
    /* font-size: 3.5rem; */
    font-size: 20px;
  }
  .roundtextanimation {
    position: absolute;
    top: 61px;
    right: 54px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 150px;
    width: 150px;
  }
  .roundtxtanimationplus img {
    height: 120px;
    width: 120px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 70px;
    top: 78px;
    z-index: 2;
  }
}
/* Large screens */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  /* Styles for large screens go here */
}
/* Extra large screens */
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* Styles for extra large screens go here */
}
/* XXL screens */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  /* Styles for XXL screens go here */
  .bntextglobal {
    margin-top: 19%;
    margin-left: 2%;
  }
  .bntextglobal h3 {
    /* font-size: 4.4rem; */
    font-size: 2rem;
    letter-spacing: 0.14rem;
  }
  .bntextglobal p {
    font-size: 1rem;
  }
  .pluselementglobal {
    left: 22%;
    top: 30%;
  }
  .roundtextanimation {
    position: absolute;
    top: 38px;
    right: 32px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    width: 240px;
    height: 240px;
  }
}

/* XXXL screens */
@media only screen and (min-width: 1920px) and (max-width: 2999px) {
  /* Styles for XXXL screens go here */
  .bntextglobal {
    margin-top: 19%;
    margin-left: -12%;
    width: 100%;
  }
  .bntextglobal h3 {
    font-size: 4.6rem;
    letter-spacing: 0.16rem;
  }
  .bntextglobal p {
    font-size: 1.4rem;
  }
  .smlogoglobal img {
    height: 250px;
    width: 250px;
  }
  .downplus img {
    height: 200px;
    width: 200px;
  }
  .uparrowelementall img {
    height: 120%;
    width: 120%;
  }
  .downanimationelement img {
    height: 130%;
    width: 130%;
  }
  .leftarrowanimationelement img {
    height: 16px;
    width: 110px;
  }
  .pluselementglobal {
    display: none;
  }
  .roundtextanimation {
    position: absolute;
    top: 90px;
    right: 90px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 320px;
    width: 320px;
  }
  .roundtxtanimationplus img {
    height: 200px;
    width: 200px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 143px;
    top: 146px;
    z-index: 2;
  }
}
/* screen more than  xxxl screens */
@media only screen and (min-width: 3000px) {
  .bntextglobal {
    margin-top: 24%;
    margin-left: -40%;
    width: 100%;
  }
  .bntextglobal h3 {
    font-size: 5.8rem;
    letter-spacing: 0.3rem;
  }
  .bntextglobal p {
    font-size: 2rem;
  }
  .smlogoglobal img {
    height: 380px;
    width: 380px;
  }
  .downplus img {
    height: 300px;
    width: 300px;
  }
  .uparrowelementall img {
    height: 175%;
    width: 175%;
  }
  .downanimationelement img {
    height: 200%;
    width: 200%;
  }
  .leftarrowanimationelement img {
    height: 18px;
    width: 155px;
    margin-bottom: 10px;
  }
  .pluselementglobal {
    display: none;
  }
  .roundtextanimation {
    position: absolute;
    top: 200px;
    right: 200px;
    animation: animName 12s linear infinite;
    z-index: 2;
  }
  .roundtextanimation img {
    height: 400px;
    width: 400px;
  }
  .roundtxtanimationplus img {
    height: 310px;
    width: 310px;
  }
  .roundtxtanimationplus {
    position: absolute;
    right: 245px;
    top: 246px;
    z-index: 2;
  }
}
