.logomannaifooter img {
  height: auto;
  padding: 10px;
  padding-left: 38px;
  padding-top: 17px;
  width: 20%;
}

/* Mobile S */
@media only screen and (max-width: 320px) {

  /* Styles for Mobile S go here */
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 10px;
    padding-top: 12px;
    width: 33%;
  }
}

/* Mobile M */
@media only screen and (min-width: 321px) and (max-width: 375px) {

  /* Styles for Mobile M go here */
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 10px;
    padding-top: 12px;
    width: 33%;
  }
}

/* Mobile L */
@media only screen and (min-width: 376px) and (max-width: 430px) {

  /* Styles for Mobile L go here */
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 10px;
    padding-top: 12px;
    width: 33%;
  }
}

/* Small screens */
@media only screen and (min-width: 431px) and (max-width: 767px) {
  /* Styles for small screens go here */

}

/* Medium screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

  /* Styles for medium screens go here */
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 18px;
    padding-top: 15px;
    width: 23%;
  }
}

/* Large screens */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  /* Styles for large screens go here */
}

/* Extra large screens */
@media only screen and (min-width: 1200px) and (max-width: 1439px) {

  /* Styles for extra large screens go here */
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 38px;
    padding-top: 17px;
    width: 15%;
  }

}

/* XXL screens */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  /* Styles for XXL screens go here */

  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 38px;
    padding-top: 17px;
    width: 15%;
  }
}

/* XXXL screens */
@media only screen and (min-width: 1920px) and (max-width: 2999px) {

  /* Styles for XXXL screens go here */
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 38px;
    padding-top: 17px;
    width: 13%;
  }

}

/* screen more than  xxxl screens */
@media only screen and (min-width: 3000px) {
  .logomannaifooter img {
    height: auto;
    padding: 10px;
    padding-left: 38px;
    padding-top: 17px;
    width: 13%;
  }
}