/* Custom CSS for WhatsApp button */
.float {
  position: fixed;
  width: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);
  height: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);
  bottom: 40px;
  right: 40px;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
  width: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);

  height: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);

  animation: effect 4s infinite ease-in;
}

@keyframes effect {
  20%,
  100% {
    width: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);
    height: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);
  }
  0%,
  10% {
    width: clamp(2.9rem, 0.9375rem + 5vw, 3.9rem);
    height: clamp(2.9rem, 0.9375rem + 5vw, 3.9rem);
  }
  5% {
    width: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);
    height: clamp(2.5rem, 0.9375rem + 5vw, 3.75rem);
  }
}

@media screen and (max-width: 800px) {
  .float {
    bottom: 30px;
    right: 10px;
    z-index: 100;
  }
}
