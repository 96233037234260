/* .connectus .wwabpluselement {
  position: absolute;
  left: 15% !important;
  top: 39% !important;
  z-index: 1;
} */
.connectus .techbannertext {
  left: 18%;
  top: 43%;
}

.connectus .visthailandbannerfont {
  font-size: 3rem !important;
  text-align: initial;
  line-height: 3rem !important;
}
.connectus .visthailandnormalfont {
  letter-spacing: 0.5rem;
  font-size: 3.5rem !important;
  text-align: initial;
  line-height: 0rem !important;
}
.connectus .connectusbanner {
  background: linear-gradient(rgba(23, 48, 108, 0.5), rgba(14, 28, 85, 0.753)),
    url("../img/shaking.jpg") top;
  background-size: cover;
}
.connectus .bgsquarex {
  background-image: url("../img/elementsmallx.png");
  background-size: contain;
  object-fit: contain;
}
.connectus .vtpackbtn {
  position: absolute;
  top: 88%;
  left: 15%;
}
.connectus .wwabpluselement {
  position: absolute;
  left: 7% !important;
  top: 41% !important;
  z-index: 1;
}

.connectus .wwabpluselement img {
  height: 210px;
  width: 210px;
}
.connectus .bgthailandblue {
  background-color: #3891da;
}

.connectus .social-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}
.connectus .footersocial-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}

.connectus .serviceelementjwwab {
  position: absolute;
  left: 20%;
  bottom: 12%;
  z-index: 2;
  width: 50%;
  top: 78%;
}

.connectus .linearthailandbgm {
  /* background: rgb(1, 112, 184); */
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
}

.connectus .op8 {
  background-color: #caa323;
  opacity: 0.38;
  width: 350px;
  height: 200px;
}
.connectus .editcardiv {
  top: 35%;
  left: 20%;
}
.connectus .editcardiv {
  letter-spacing: 2rem;
  height: fit-content;
}
.connectus .editcardiv .loose {
  display: flex;
  letter-spacing: 0.1em;
  font-size: 15px;
  color: white;
  top: 3px;
  overflow: hidden;
}
.connectus .editcarplus {
  top: -18%;
  left: -32%;
  height: 100px !important;
  width: 100px !important;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}
.connectus .MesgPicthailand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.connectus .MesgPicthailand img {
  height: 90%;
  width: 90%;
}
.connectus .editplusnew {
  left: -1%;
  top: 10%;
}
.connectus .editplusnewright {
  right: -1%;
  top: 10%;
}
.connectus .h-150 {
  height: 150px;
}
/* ////////CONNECT US////////// */
.beforeform {
  position: relative;
  justify-content: left;
  text-align: left;
  align-items: left;
}
.connectus .mainhead {
  font-size: 1.5rem;
  margin-right: 50%;
  color: white;
  /* right: 2%; */
}
.connectus .form {
  margin-top: 5%;
}
.connectus .content {
  margin-top: 3%;
  margin-bottom: 3%;
}
.connectus .formpart .normaltxt {
  /* width: max-content; */
  position: relative;
  font-size: 20px;
  color: #fff;
  text-align: center;
  left: -6px;
  /* margin-left: 8%; */
}
/* .connectus .container-fluid {
  width: 100%;
  height: 100%;
} */
/* .connectus .mainhead {
  font-size: 1.6rem;
  color: white;
  margin-left: 20%;
} */
.connectus .subheadone {
  font-size: 1.1rem;
  color: white;
  position: relative;
  /* left: 6.7%; */
}
.connectus .subheadtwo {
  font-size: 19px;
  color: white;
  position: relative;
  /* left: 8%; */
}
.connectus .smlogo {
  position: absolute;
  left: 8%;
  top: 8%;
}
/* .connectus .lastone {
  margin-left: 11%;
  margin-top: 5%;
} */
.connectus .closesum {
  margin-left: 45%;
  /* margin-top: 7% !important; */
  /* margin-bottom: 7% !important; */
  background-color: #3fa9f5 !important;
  outline: #3fa9f5;
}
/* Dropdowns  */
.connectus .drop-downarea {
  position: relative;
  top: -56%;
  left: 47%;
}
.connectus .drop-downlocation {
  position: relative;
  top: -56%;
  left: 47%;
}
.connectus .drop-downannual {
  position: relative;
  top: -56%;
  left: 47%;
}
.connectus .form-control {
  font-size: 1rem !important;
}
.connectus .formbg {
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
}
.connectus .container {
  /* max-width: 1324px; */
}
.connectus .btmtext h3 {
  font-size: 2.3rem;
  /* font-weight: 600; */
  color: #fff;
  margin-left: 0rem;
  padding: 0px;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px #c4c4c4;
  letter-spacing: 0.1rem;
  width: 93%;
}
.connectus .btmtext p {
  font-size: 1.1rem;
  color: #fff;
  line-height: 1.77rem;
  margin-top: 20px;
  letter-spacing: 0.1rem;
  margin-left: 0rem;
}
.connectus .formpart h4 {
  /* text-align: left; */
}
@media (max-width: 426px) {
  .termeffectiveDate {
    background-color: green;
  }
  .connectus .techelementsmallx {
    display: none;
  }
  .connectus .techbannertext {
    width: 75%;
  }

  .connectus .techbannertext {
    top: 50%;
  }
  .connectus .wwabpluselement img {
    height: 150px;
    width: 150px;
  }
  .connectus .wwasmlogo {
    display: none;
  }
  .connectus .techpulusbot {
    display: none;
  }
  .connectus .serviceelementjwwab {
    top: 83%;
  }
  .connectus .wwabpluselement img {
    visibility: hidden;
  }
  .connectus .btn {
    padding: -0.25rem 2rem 0.75rem;
  }

  .connectus .vtpackbtn {
    top: 87%;
    left: 3%;
  }
  .connectus .padmob {
    /* padding: 20px; */
  }
  .connectus .mobnone {
    display: none;
  }
  .connectus .mobcenter {
    text-align: center;
    padding-bottom: 10px;
  }
  .connectus .mobborderbottom {
    border-bottom: 1px solid white;
  }
  .connectus .editplusnew {
    left: -3%;
    top: 2%;
  }
  .connectus .editplusnewright {
    right: -3%;
    top: 2%;
  }
  .connectus .mobpad {
    padding-bottom: 15px;
  }
  .connectus .blogelementsmallx {
    display: none;
  }
  .connectus.bl1 .blogpartpluselement {
    margin-top: -18px;
    margin-left: -1rem;
  }
  .connectus .blogimgrbbtn {
    left: 52%;
  }
  .connectus .blogimgarelement {
    left: 57%;
  }
  .connectus .blogimgtpluselement {
    left: 30%;
    top: 37%;
  }
  .connectus .blogimgrbbtn .editbottom a {
    bottom: 13px;
    position: relative;
  }
  .connectus .blogimgarelement {
    bottom: 24%;
  }
  .article-item-photo {
    margin-right: 1rem;
  }
  .connectus .editcarplus {
    top: -28%;
  }
  .connectus .techbannertext {
    left: 15%;
  }
  .connectus .bl1 h5 {
    position: absolute;
    left: 26.5%;
    top: 1%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 26.5%;
    top: 19%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus .share {
    font-size: 14px;
  }
  .connectus .connectus.op8 {
    height: 30vh;
  }
  /* ////////////////// */
  .connectus .smlogo {
    visibility: hidden;
  }
  .connectus .slide-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden !important;
    width: 100%;
    /* padding-top: 0rem;
    padding-bottom: 4rem; */
  }
  .connectus .btmtext h3 {
    font-size: 1.7rem;
    /* font-weight: 600; */
    color: #fff;
    margin-left: 0rem;
    padding: 0px;
    padding-bottom: 1.5rem;
    border-bottom: solid 1px #c4c4c4;
    letter-spacing: 0.1rem;
    width: 93%;
  }
  .connectus .btmtext p {
    font-size: 1.1rem;
    color: #fff;
    line-height: 1.8rem;
    margin-top: 18px;
    letter-spacing: 0.1rem;
    margin-left: 0rem;
    width: 100%;
  }
  .connectus .mainhead {
    margin: 0px;
    font-size: 1.1rem;
    position: relative;
    color: white;
    right: 0%;
  }
  .connectus .formpart h4 {
    text-align: left;
  }
  .connectus .btmpluselement {
    visibility: hidden !important;
  }
  .connectus .techserviceelement1bot img {
    visibility: hidden !important;
  }
  .connectus .btmbanelementtech {
    bottom: 5%;
  }
  .connectus .form {
    padding-inline: 45px;
  }
  .connectus .form-control {
    font-size: 1.1rem !important;
  }
  .connectus .subheadone {
    font-size: 1.1rem;
    position: relative;
    left: 0%;
  }
  .connectus .subheadtwo {
    font-size: 1.1rem;
    left: 0%;
  }
  .connectus .formpart .normaltxt {
    font-weight: 400;
    position: relative;
    font-size: 1.1rem;
    color: #fff;
    text-align: left;
    left: 0px;
    /* margin-left: 7%;  */
  }
  .connectus .closesum {
    margin-left: 30%;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .connectus .mainhead {
    margin: 0px;
    font-size: 1rem;
    position: relative;
    color: white;
    right: 0%;
  }
  .connectus .form {
    padding-inline: 35px;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .connectus .mainhead {
    margin: 0px;
    font-size: 1.1rem;
    position: relative;
    color: white;
    right: 0%;
  }
  .connectus .form {
    padding-inline: 40px;
  }
}

@media screen and (min-width: 768px) {
  .connectus.serviceelementjwwab {
    position: absolute;
    left: 20%;
    bottom: 12%;
    z-index: 2;
    width: 50%;
    top: 83%;
  }
  .connectus.vtpackbtn {
    position: absolute;
    top: 88%;
    left: 14%;
  }
  .connectus.wwasmlogo {
    visibility: hidden;
  }
  .connectus.bl1 h5 {
    position: absolute;
    left: 13.5%;
    top: 1%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus.bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 13.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
  }
  .connectus.bl1 .blogpartpluselement {
    position: absolute;
    margin-top: -1rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .connectus.blogimgrbbtn {
    position: absolute;
    top: 82%;
    left: 68%;
    z-index: 5;
  }
  .connectus.share {
    font-size: 14px;
  }
  .connectus.connectus.op8 {
    height: 30vh;
  }
  /* ////// */
  .connectus .mainhead {
    margin: 0px;
    position: relative;
    /* margin-right: 43%; */
    /* left: 8%; */
  }
  .connectus .formpart .normaltxt {
    position: relative;
    /* text-align: left; */
    /* left: 25px; */
    /* padding-inline: 5%; */
    /* margin-left: 8%; */
  }
  .connectus .techserviceelement1bot img {
    visibility: hidden !important;
  }
  .connectus .btmbanelementtech {
    position: absolute;
    left: 10%;
    bottom: 6%;
    z-index: 2;
  }
  .connectus .subheadone {
    font-size: 1.3rem;
    /* left: 63.3px; */
  }
  .connectus .subheadtwo {
    font-size: 1.3rem;
    /* padding-inline: 38px; */
    /* left: 25px; */
  }
  .connectus .formpart .normaltxt {
    font-size: 1.3rem;
    font-weight: 400;
    text-align: left;
    margin-left: 7px;
  }
  .connectus .btmtext p {
    font-size: 1.1rem;
    color: #fff;
    line-height: 1.77rem;
    margin-top: 20px;
    letter-spacing: 0.1rem;
    margin-left: 2rem;
  }
  .connectus .btmtext h3 {
    font-size: 2.3rem;
    /* font-weight: 600; */
    color: #fff;
    margin-left: 2rem;
    padding: 0px;
    padding-bottom: 1.5rem;
    border-bottom: solid 1px #c4c4c4;
    letter-spacing: 0.1rem;
    width: 93%;
  }
  .connectus .formpart h4 {
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  /* .connectus .formpart h4 {
    font-size: 1.6rem;
    color: white;
    position: relative;
    right: 22.5%;
  } */
  /* .connectus .subheadone {
    font-size: 1.5rem;
    color: white;
    position: relative;
    right: 21.5rem;
  } */
  /* .connectus .subheadtwo {
    font-size: 19px;
    color: white;
    position: relative;
    right: 16px;
  } */
  /* .connectus .formpart .normaltxt {
    width: max-content;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    margin-left: 64.8px;
  } */

  .connectus.wwabpluselement {
    position: absolute;
    left: 6% !important;
    top: 47% !important;
    z-index: 1;
  }
  .connectus .editplusnew {
    left: -1%;
    top: 12%;
  }
  .connectus .editplusnewright {
    right: -1%;
    top: 11%;
  }
  .connectus .bl1 h5 {
    position: absolute;
    left: 19.5%;
    top: 3%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 19.5%;
    top: 14%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus .blogimgrbbtn {
    position: absolute;
    top: 79%;
    left: 67%;
    z-index: 5;
  }
  .connectus .blogimgarelement {
    position: absolute;
    left: 70%;
    bottom: 22%;
    z-index: 2;
  }
  .connectus .share {
    font-size: 14px;
  }
  .connectus .connectus .op8 {
    height: 30vh;
  }
  .connectus .editcarplus {
    top: -18%;
    left: -22%;
  }
  .connectus .abouttext {
    line-height: 25px;
    font-size: 16px;
    word-spacing: 4px;
  }
  .connectus .MesgPicthailand img {
    height: 119%;
    width: 90%;
  }
  .connectus .roundedtop {
    border-top-left-radius: 121px;
    border-top-right-radius: 121px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
  .connectus .author {
    font-size: 14px;
  }
  .connectus .manasi {
    font-size: 14px;
  }
  .connectus .roundedtop {
    height: 300px !important;
    width: 242px !important;
    object-fit: fill;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
    /* padding-left: 14px; */
  }
  .connectus .phiphi1 {
    padding-left: 14px;
  }
  .connectus .phiphi2 {
    margin-left: 14px;
  }
  .connectus .phiphi3 {
    padding-right: 14px;
  }
  .connectus .phiphi4 {
    padding-right: 14px;
  }
  /* ////////////////// */
  .connectus .btmpluselement {
    position: absolute;
    left: 49%;
    top: 12%;
    z-index: 1;
  }
  .connectus .smlogo {
    position: absolute;
    left: 13%;
    top: 5%;
  }
  .connectus .subheadone {
    font-size: 1.3rem;
    /* left: 78.3px; */
  }
  .connectus .subheadtwo {
    font-size: 1.3rem;
    /* padding-inline: 38px; */
    /* left: 39px; */
  }
  .connectus .mainhead {
    /* left: 78px; */
  }
  .connectus .formpart h4 {
    text-align: left;
  }
}
@media screen and (min-width: 1440px) {
  .connectus .editplusnew {
    left: -1%;
    top: 20%;
  }
  .connectus .editplusnewright {
    right: -1%;
    top: 13%;
  }
  .connectus .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 16.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus .bl1 h5 {
    position: absolute;
    left: 16.5%;
    top: 0%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .connectus .share {
    font-size: 14px;
  }
  .connectus .connectus .op8 {
    height: 30vh;
  }
  .connectus .editcarplus {
    top: -18%;
    left: -22%;
  }
  .connectus .abouttext {
    line-height: 25px;
    font-size: 16px;
    word-spacing: 4px;
  }
  /* .connectus.MesgPicthailand img {
    height: 133%;
    width: 90%;
  } */
  .connectus .roundedtop {
    height: 357px !important;
    width: 319px !important;
    object-fit: fill;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
}
@media (min-width: 427px) and (max-width: 821px) {
  .connectus .wwabpluselement {
    left: 2% !important;
    top: 48% !important;
  }
  .connectus .tabnone {
    display: none;
  }
  .connectus .editplusnew {
    top: 6%;
  }
  .connectus .editplusnewright {
    top: 6%;
  }
  .connectus .padmob {
    padding: 20px;
  }
  .connectus .connectus .op8 {
    height: 30vh;
  }
}

/* /////////////// */
