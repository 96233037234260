.blogimgtpluselement {
  position: absolute;
  left: 40%;
  top: 50%;
  z-index: 10;
}
.bnbpluselement {
  position: absolute;
  left: 35.8%;
  top: 27%;
  z-index: 1;
}
.bnpluselement {
  position: absolute;
  left: 4%;
  top: 210%;
  z-index: 1;
}
.bnarelement {
  position: absolute;
  left: 13%;
  bottom: 33%;
  z-index: 2;
}
.bgbnarelement {
  position: absolute;
  left: 4%;
  bottom: 77.3%;
  z-index: 2;
}
.blogimgarelement {
  position: absolute;
  left: 70%;
  bottom: 20%;
  z-index: 2;
}
.newsimgarelement {
  position: absolute;
  left: 58%;
  bottom: 16%;
  z-index: 2;
}
.bntext {
  font-size: 1.2rem;
  color: #c4c4c4;
  line-height: 2.3rem;
  /* margin-top: 35px; */
  letter-spacing: 0.1rem;
  margin-left: 4rem;
  margin-top: 3rem;
  width: 81%;
}
.bntext h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #c9c9c9;
  padding: 0px;
  padding-bottom: 0rem;
  margin-left: 2rem;
}
.bntext h3 {
  font-size: 4rem;
  font-weight: 600;
  color: #fff;
  margin-left: 2rem;
  padding: 0px;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px #c4c4c4;
  letter-spacing: 0.1rem;
  width: 93%;
}
.bntext p {
  font-size: 1.1rem;
  color: #fff;
  line-height: 2.3rem;
  margin-top: 20px;
  letter-spacing: 0.1rem;
  margin-left: 2rem;
}
/* .bnbg {
  background: rgb(2, 112, 183);
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: 100%;
  width: 100%;
} */
.bnbiglogo {
  position: absolute;
  left: -1%;
  top: 9.3%;
  z-index: 2;
}
.smallxbn {
  position: absolute;
  margin-left: 27%;
  margin-top: 3rem;
  z-index: 2;
  opacity: 1;
}
.playicon {
  left: -23rem;
  top: 3rem;
  position: relative;
}
.bncontentpart {
  font-size: 1.2rem;
  color: #fff;
  line-height: 2.3rem;
  z-index: 5;
}
.bncontentpart h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  padding-left: 20px;
  padding-top: 10px;
  letter-spacing: 0.1rem;
  position: relative;
}
.bncontentpart h5 {
  position: absolute;
  left: 35%;
  top: 20px;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bncontentpart h6 {
  position: absolute;
  left: 35%;
  top: 100px;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.blogbigimg {
  background: url(../img/blogbigimg.png);
  height: 1050px;
  margin-top: 14rem;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blogbigimg p {
  font-size: 1rem;
  opacity: 1;
  color: #fff;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  z-index: 15;
  text-align: justify;
  padding: 30px 80px 30px 30px;
}
.blogbigimg h4 {
  opacity: 1;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  color: #fff;
  z-index: 15;
}

.blogpart {
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 1rem;
  opacity: 1;
  color: #fff;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  z-index: 15;
}
.blogimgrbbtn {
  position: absolute;
  top: 80%;
  left: 68%;
  z-index: 5;
}
.bl1 .blogpartpluselement {
  position: absolute;
  margin-top: 0rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
  z-index: 1;
}
.bl1 .blogelementsmallx {
  position: absolute;
  left: 78%;
  top: 0%;
  z-index: 2;
  opacity: 1;
}
.bl1 h5 {
  position: absolute;
  left: 15.5%;
  top: 6%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl1 h6 {
  position: absolute;
  left: 15.5%;
  top: 14%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl2 .blogpartpluselement {
  position: relative;
  margin-top: -2rem;
  margin-left: 0rem;
  z-index: 1;
}
.bl2 .blogelementsmallx {
  position: absolute;
  left: 93%;
  top: -11%;
  z-index: 2;
  opacity: 1;
}
.bl2 h5 {
  position: absolute;
  left: 18.5%;
  top: 8%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl2 h6 {
  position: absolute;
  left: 18.5%;
  top: 43%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bolgimgtitle {
  font-size: 1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding-left: 1rem;
  margin-top: -15px;
}
.article-item {
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.article-item-photo {
  margin-bottom: 2rem;
  margin-top: 11rem;
  margin-left: 0rem;
  height: 0;
  padding-bottom: 62.16216216%;
  position: relative;
  overflow: hidden;
}
.article-item-photo img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-item {
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.news-item-photo {
  margin-top: 4rem;
  margin-left: 0rem;
  height: 0;
  padding-bottom: 80%;
  position: relative;
  overflow: hidden;
}
.news-item-photo img {
  filter: brightness(0.65);
  position: absolute;
  left: 80px;
  top: 0px;
  bottom: 0;
  right: 0;
  height: 100%;
}
.newsimgrbbtn {
  position: absolute;
  top: 85%;
  left: 57%;
  z-index: 5;
}
.newsbg {
  background: #17355d;
  height: 467vh;
  width: 100%;
  border-radius: 0px 250px 0px 0px;
  margin-top: 88px;
}
.newsbiglogo {
  position: absolute;
  right: 5rem;
  top: 3rem;
}
.newscontentpart {
  font-size: 1.2rem;
  color: #fff;
  line-height: 2.3rem;
  position: absolute;
  z-index: 5;
}
.newscontentpart h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  left: -18.5rem;
  top: 7.4rem;
  letter-spacing: 0.1rem;
  position: relative;
}
.newscontentpart h5 {
  position: absolute;
  left: 10.5%;
  top: 235%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.newscontentpart h6 {
  position: absolute;
  left: 10.5%;
  top: 277%;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.newspart {
  /* margin-top: -20rem; */
}
.smallxnews {
  position: absolute;
  margin-left: 30rem;
  margin-top: 1rem;
  z-index: 2;
  opacity: 1;
}
.playiconnews {
  left: -27.5rem;
  top: 3rem;
  position: relative;
}
.newsdotsbgleft {
  background: url(../img/newsdotsbg.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}
.newsdotsbgright {
  background: url(../img/newsdotsbg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
.newssectionpart {
  margin-top: 20rem;
  color: #fff;
}
.bl1 .newsspartpluselement {
  margin-top: 0rem;
  margin-left: -6rem;
  margin-bottom: 1rem;
  z-index: 1;
}
.bl1 h5 {
  position: absolute;
  left: 10.5%;
  top: 6%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.bl1 h6 {
  position: absolute;
  left: 10.5%;
  top: 13%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.newstextright p {
  width: 90%;
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0rem;
  line-height: 1.9rem;
  text-transform: none;
  margin-top: 2rem;
  /* margin-top: 12rem; */
  margin-bottom: 2rem;
}
.newstextleft p {
  /* width: 90%; */
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-top: 2rem;
  /* margin-top: 12rem; */
  margin-bottom: 2rem;
}
.newstextbg {
  width: 100%;
  background: #3fa6f2;
  height: 11vh;
}
.newstextbg p {
  color: #fff;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 5px 10px 0px 30px;
  line-height: 2rem;
}
.newstextbg .icon {
  font-size: 15px;
  margin-right: 5px;
}
.nw1 .newsspartpluselement {
  position: absolute;
  margin-top: 0rem;
  margin-left: -6rem;
  margin-bottom: 1rem;
  z-index: 1;
}
/* .nw1 h5 {
  font-size: 1rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #fff;
  width: max-content;
}
.nw1 h6 {
  font-size: 1rem;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 35px;
  width: max-content;
} */
.mrsection .newstextright p {
  width: 80%;
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
}
.mrsection .newstextleft p {
  /* width: 80%; */
  text-align: justify;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0rem;
  line-height: 1.9rem;
  text-transform: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* margin-left: 4rem; */
}
.nw2 .newsspartpluselement {
  margin-top: 0rem;
  margin-left: 38rem;
  margin-bottom: 1rem;
  z-index: 1;
}
/* .nw2 h5 {
  position: absolute;
  right: 23.5%;
  top: 4%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
}
.nw2 h6 {
  position: absolute;
  right: 23.5%;
  top: 12%;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  padding-bottom: 0rem;
} */
.newstextrightbg {
  /* width: 90%; */
  background: #3fa6f2;
  /* height: 11vh; */
}
.newstextrightbg .icon {
  font-size: 15px;
  /* margin-right: 5px; */
}
.newstextrightbg p {
  /* color: #fff;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 5px 70px 0px 0px;
  line-height: 2rem;
  text-align: right; */
}
.mt40 {
  margin-top: 4rem;
}
.mb30 {
  margin-bottom: 1.875rem;
}
.mb50 {
  margin-bottom: 5rem;
}
.ml15 {
  margin-left: -15px;
}
.zooming {
  overflow: hidden;
}
.zooming img {
  transform: scale(1);
  transition: all 1s linear;
}
.zooming:hover img,
a:hover .zooming img {
  transform: scale(1.08);
}
.zoomingnews {
  overflow: hidden;
}
.zoomingnews:hover img,
a:hover .zoomingnews img {
  transform: scale(1.04);
}
.zoomingnews img {
  transform: scale(1);
  transition: all 1s linear;
}

.section4 .mt-25 {
  margin-top: 200px;
}
.section4 .blogwithplayicon {
  /* position: absolute;
    bottom: 27%;
    left: 13%; */
  position: relative;
  bottom: 23%;
  left: 0%;
  top: -7rem;
}
.section4 .blogposition {
  left: -100%;
  top: 150px;
}
.section4 .leftplusn {
  position: relative;
  margin-top: -2%;
}
.section4 .leftplusnotit {
  position: absolute;
  margin-top: 2%;
  margin-left: 18%;
}
.section4 .rightplusn {
  position: relative;
  margin-top: 0%;
  float: right;
  margin-left: 68%;
  margin-right: 14%;
}
.section4 .rightplusnotit {
  position: absolute;
  margin-top: 2%;
  margin-left: 18%;
}

/* .section4 .explorebtnnews {
} */

.news-item-photo .linearimg {
  background: linear-gradient(
    90deg,
    rgba(2, 112, 183, 1) 0%,
    rgba(85, 81, 140, 1) 100%
  );
  height: 100%;
  width: 100%;
}

/* for mobile small  */
@media (min-width: 320px) and (max-width: 374px) {
  .section4 .bl1 h6 {
    top: 12%;
  }
  .section4 .bl1 h5 {
    top: 4%;
  }
  .section4 .news-item-photo img {
    left: 1px;
    top: 4px;
  }
  .section4 .blogimgtpluselement {
    left: 24% !important;
    top: 23% !important;
  }
  /* .section4 .nw1 h5 {
    left: 38.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section4 .nw1 h6 {
    top: 19%;
    left: 38.5%;
    width: 100%;
    padding-inline: 4%;
  } */
  /* .section4 .nw2 h5 {
    position: relative;
    left: 24.5%;
    margin-top: -46%;
    width: 100%;
    padding-inline: 4%;
  }
  .section4 .nw2 h6 {
    position: relative;
    right: 21.5%;
    margin-top: 16%;
    width: 100%;
    padding-inline: 4%;
  } */
  .section4 .bnbpluselement {
    visibility: hidden !important;
  }
  .section4 .bntext h3 {
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
  .section4 .bntext {
    font-size: 1.1rem;
    margin-left: 1rem;
    margin-top: 7rem;
    width: 81%;
  }
  /* .section4 .bnbg {
    height: 171rem;
    width: 100%;
  } */
  .section4 .newsimgrbbtn {
    position: absolute;
    top: 81% !important;
    left: 50% !important;
    z-index: 5;
  }
  .section4 .newsimgarelement {
    position: absolute;
    left: 43%;
    bottom: 19%;
  }
  .section4 .newstextright p {
    width: 100% !important;
    text-transform: none;
    padding-inline: 2%;
    letter-spacing: 0rem;
    line-height: 1.9rem;
  }
  .section4 .newstextleft p {
    width: 100% !important;
    text-transform: none;
    /* padding-inline: 2%; */
    letter-spacing: 0rem;
    line-height: 1.9rem;
  }
  .section4 .nw1 .newsspartpluselement {
    left: 31% !important;
  }
  .section4 .nw2 .newsspartpluselement {
    margin-left: 10.5rem !important;
    margin-top: 2rem;
  }
  .section4 .mdmob {
    margin-bottom: -50px !important;
    margin-top: 71px !important;
  }
  .section4 .bntext p {
    font-size: 1.1rem;
    color: #fff;
    line-height: 1.5rem;
    margin-top: 12px;
    letter-spacing: 1px;
    margin-left: 2rem;
  }
  .section4 .article-item-photo img {
    left: 11px;
  }
  /* .section4 .nw1 h6 {
    font-size: 1rem;
    position: relative;
  }
  .section4 .nw1 h5 {
    margin-top: 8%;
    font-size: 1rem;
    position: relative;
  } */
  /* .section4 .nw2 h5 {
    font-size: 1rem;
  }
  .section4 .nw2 h6 {
    font-size: 1rem;
  } */
  .section4 .smallxnews {
    margin-top: 9rem;
  }
  .section4 .playiconnews {
    left: 3.5%;
    top: 11rem;
  }
  .section4 .leftplusnotit {
    position: absolute;
    margin-top: 17% !important;
    margin-left: 35% !important;
  }
  .section4 .rightplusnotit {
    position: absolute;
    width: 100%;
    margin-top: 50% !important;
    margin-left: -11% !important;
  }
  /* .section4 .nw1 h5 {
    font-size: 1rem;
  }
  .section4 .nw1 h6 {
    font-size: 1rem;
  } */
  .section4 .smallxnews {
    margin-left: 7% !important;
    margin-top: 6rem !important;
  }
  .section4 .playiconnews {
    left: 0.5rem;
    top: 8rem;
    position: relative;
  }
  .section4 .newscontentpart h3 {
    top: 13rem !important;
    left: 117% !important;
  }
  .section4 .playiconnews {
    left: 2.5rem !important;
    top: 8rem !important;
    position: relative;
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
  .section4 .leftplusn {
    position: relative;
    margin-top: 11%;
  }
  .section4 .rightplusn {
    position: relative;
    margin-top: 11%;
  }
}
/* for mobile medium  */
@media (min-width: 375px) and (max-width: 425px) {
  .section4 .bnbpluselement {
    visibility: hidden !important;
  }
  .section4 .nw1 .newsspartpluselement {
    left: 29% !important;
    margin-top: 0rem;
  }
  /* .section4 .nw2 h6 {
    position: relative;
    right: 4.5%;
    margin-top: 16%;
    left: 30.5%;
    font-size: 1rem;
  }
  .section4 .nw2 h5 {
    position: relative;
    left: 36.5%;
    padding-inline: 0px;
    margin-top: -42%;
    font-size: 1rem;
  } */
  .section4 .nw2 .newsspartpluselement {
    margin-top: 1rem;
    margin-left: 14rem !important;
  }
  .section4 .news-item {
    padding-left: 19px !important;
    padding-right: 19px !important;
  }
  /* .section4 .bnbg {
    height: 179rem;
  } */
  .section4 .smallxnews {
    margin-top: 9rem;
    margin-left: 35% !important;
  }
  .section4 .newscontentpart h3 {
    left: 153% !important;
  }
  .section4 .news-item-photo img {
    left: 0px;
    top: 0px;
  }
  .section4 .mdmob {
    margin-bottom: -50px !important;
    margin-top: 71px !important;
  }
  .section4 .newsimgrbbtn {
    position: absolute;
    top: 84%;
    left: 55%;
    z-index: 5;
  }
  .section4 .newstextright p {
    line-height: 1.9rem;
    letter-spacing: 0rem;
    padding-inline: 1%;
    margin-top: 1rem;
  }
  .section4 .newstextleft p {
    margin-top: 3rem;
    line-height: 1.9rem;
    letter-spacing: 0rem;
    /* padding-inline: 1%; */
  }
  .section4 .nw1 h5 {
    font-size: 1rem;
    margin-top: 6%;
    position: relative;
  }
  .section4 .nw1 h6 {
    font-size: 1rem;
  }
  .section4 .playiconnews {
    left: 3.5rem;
    top: 11rem;
    position: relative;
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
  .section4 .rightplusnotit {
    position: absolute;
    width: 100%;
    margin-top: 48% !important;
    margin-left: -7% !important;
  }
  .section4 .leftplusn {
    position: absolute;
    margin-top: 5%;
  }
  .section4 .rightplusn {
    position: absolute;
    margin-top: 5%;
  }
  .section4 .leftplusnotit {
    position: absolute;
    margin-top: 13%;
    margin-left: 26%;
  }
}
/* for mobile large  */
@media (max-width: 426px) {
  .section4 .techserviceelement1bot {
    display: none !important;
  }
  .section4 .techpulusbot {
    display: none !important;
  }
  .section4 .bnarelement {
    display: none !important;
  }
  .section4 .blogbigimg {
    height: 300px !important;
    /* background: none !important; */
  }
  .section4 .smallxbn {
    margin-left: 4% !important;
    margin-top: 2rem !important;
  }
  .section4 .bnbiglogo img {
    display: none !important;
  }
  .section4 .mt-25 {
    margin-top: 89px !important;
  }
  .section4 .blogbigimg p {
    margin-top: 16px;
    padding: 27px !important;
    font-size: 9px;
    line-height: 1.6rem;
    letter-spacing: 0rem;
  }
  .section4 .mobmt150 {
    margin-top: 150px !important;
  }
  /* .section4 .bl1 h5 {
    left: 22.5% !important;
    top: 4%;
  }
  .section4 .bl1 h6 {
    left: 23.5% !important;
    top: 19% !important;
  } */
  .section4 .bl1 .blogelementsmallx {
    display: none !important;
  }
  .section4 .article-item-photo {
    margin-bottom: 0px !important;
    margin-top: 13rem !important;
    margin-left: 0px !important;
  }
  .section4 .bgbnarelement {
    display: none !important;
  }
  .section4 .newssectionpart {
    margin-top: 25rem !important;
  }
  .section4 .blogimgrbbtn {
    top: 80% !important;
    left: 50% !important;
  }
  .section4 .blogimgtpluselement {
    left: 28%;
    top: 21%;
  }
  .section4 .mobnone {
    /* display: none !important; */
  }
  .section4 .newsbg {
    border-radius: 0px 180px 0px 0px !important;
    margin-top: 100px !important;
  }
  .section4 .bolgimgtitle {
    margin-top: 0px !important;
  }
  .section4 .smallxnews {
    margin-left: 54%;
    margin-top: 4rem;
  }
  .section4 .newsbiglogo {
    display: none !important;
  }
  .section4 .newscontentpart h3 {
    top: 11rem;
    left: 153%;
  }
  .section4 .news-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .section4 .mob-pad-20 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .section4 .nw1 .newsspartpluselement {
    left: 27% !important;
  }
  .section4 .nw2 .newsspartpluselement {
    margin-top: 2rem;
    margin-left: 19rem;
    /* visibility: hidden; */
  }
  .section4 .newstextright p {
    width: 100% !important;
    text-transform: none;
    padding-inline: 4%;
  }
  .section4 .newstextleft p {
    width: 100% !important;
    text-transform: none;
    /* padding-inline: 4%; */
    margin-top: 1rem;
  }
  .section4 .mrsection .newstextright p {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .section4 .mrsection .newstextleft p {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .section4 .newstextrightbg {
    width: 100% !important;
  }
  .section4 .newsbg {
    height: auto !important;
  }
  .section4 .bntext h3 {
    font-size: 28px;
    margin-bottom: 28px;
  }
  .section4 .bnbpluselement {
    left: 63% !important;
    top: 14% !important;
  }
  .section4 .smlogo {
    visibility: hidden;
  }
  .section4 .blogbigimg h4 {
    opacity: 1;
    font-size: 10px;
    letter-spacing: 0.1rem;
    font-weight: 600;
    color: #fff;
    z-index: 15;
  }
  .section4 .bl1 .blogpartpluselement {
    position: absolute;
    margin-top: -12px;
    margin-left: -2rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .section4 .blogimgarelement {
    position: absolute;
    left: 54%;
    bottom: 21%;
    z-index: 2;
  }
  .section4 .newsimgrbbtn {
    position: absolute;
    top: 82%;
    left: 55%;
    z-index: 5;
  }

  .section4 .newsimgarelement {
    position: absolute;
    left: 58%;
    bottom: 17%;
    z-index: 2;
  }
  .section4 .contentpartpartner h3 {
    position: relative;
    margin-top: 141px;
    font-size: 2.5rem;
    text-align: right;
    letter-spacing: 0.035em;
    font-weight: 600;
    font-family: "Saira";
    color: #fff;
    z-index: 2;
  }
  .section4 .mdmob {
    margin-bottom: -50px !important;
    margin-top: 71px !important;
  }

  /* .section4 .nw1 h6 {
    font-size: 1rem;
    left: 31.5%;
    position: relative;
    display: flex;
    margin-top: 60px;
    align-items: start;
    width: 100%;
    padding-inline: 4%;
  } */
  /* .section4 .nw2 h5 {
    font-size: 1rem;
    position: relative;
    margin-top: -33%;
    left: 40.5%;
    width: 100%;
    padding-inline: 4%;
  }
  .section4 .nw2 h6 {
    font-size: 1rem;
    position: relative;
    right: -38.5%;
    margin-top: 10%;
    width: 100%;
    padding-inline: 4%;
  } */
  .section4 .news-item-photo img {
    position: absolute;
    left: 1px;
    top: 0px;

    bottom: 0;
    right: 0;
    height: 100%;
  }
  /* .section4 .nw1 h5 {
    font-size: 1rem;
    position: relative;
    font-size: 1rem;
    margin-top: 6%;
    left: 31.5%;
    width: 100%;
    padding-inline: 4%;
  } */
  .section4 .leftplusnotit {
    position: absolute;
    margin-top: 14%;
    margin-left: 26%;
  }
  .section4 .rightplusnotit {
    position: absolute;
    width: 100%;
    margin-top: 46%;
    margin-left: 1%;
  }
  .section4 .newspart {
    margin-top: -8.4rem;
  }
  .section4 .playiconnews {
    left: 4.5rem;
    top: 6rem;
    position: relative;
  }
  .section4 .rightplusn {
    position: relative;
    margin-right: 6%;
    float: right;
    margin-top: 6%;
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
  .section4 .leftplusn {
    position: relative;
    margin-top: 6%;
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
}
/* @media (min-width: 427px) and (max-width: 769px) { */
@media (min-width: 427px) and (max-width: 659px) {
  .section4 .newsbg {
    height: auto;
    width: 100%;
  }
  .section4 .bnbpluselement {
    left: 54.5% !important;
    top: 14% !important;
  }
  .section4 .bnbiglogo {
    left: -1% !important;
    top: 12% !important;
  }
  .section4 .blogbigimg {
    height: 700px !important;
  }
  .section4 .blogposition {
    left: -67% !important;
    top: 77px !important;
  }
  .section4 .tabnone {
    display: none !important;
  }
  .section4 .smallxnews {
    margin-left: 2rem !important;
    margin-top: 3rem;
  }

  .section4 .tab-pad-20 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .section4 .nw2 .newsspartpluselement {
    margin-top: -1rem;
    margin-left: 55%;
  }
  .section4 .blogimgtpluselement {
    top: 55%;
  }
  .section4 .bl1 .blogpartpluselement {
    margin-top: -1rem;
  }
  /* .section4 .bl1 h5 {
    top: 3%;
  }
  .section4 .bl1 h6 {
    top: 11%;
  } */
  .section4 .blogimgarelement {
    bottom: 21%;
  }
  .section4 .newstextright p {
    width: 91%;
    text-align: justify;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: none;
    margin-top: 1.1rem;
    margin-bottom: 2rem;
    padding-inline: 2%;
    /* margin-left: 4.5%; */
  }
  .section4 .nw1 .newsspartpluselement {
    position: absolute;
    margin-top: 0rem;
    margin-left: 2rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .section4 .nw1 h6 {
    width: 100%;
    position: relative;
    margin-top: 42%;
    margin-left: 29%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
  }
  .section4 .nw1 h5 {
    left: 0%;
    top: 0%;
    width: 100%;
    position: relative;
    margin-top: -8%;
    margin-left: 29%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
  }
  /* .section4 .nw2 h5 {
    right: 0%;
    top: 0%;
    position: relative;
    margin-top: -29%;
    margin-left: 48%;
    width: 100%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
  }
  .section4 .nw2 h6 {
    position: relative;
    margin-left: 48%;
    margin-top: 11%;
    width: 100%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    right: 0%;
    top: 0%;
  } */
  .section4 .mrsection .newstextleft p {
    /* width: 91%; */
    text-align: justify;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: none;
    margin-top: 1.1rem;
    margin-bottom: 2rem;
    padding-inline: 2%;
    /* margin-left: 1.5rem; */
  }
  .section4 .newspart {
    margin-top: -8rem;
  }
  .section4 .newsbiglogo {
    visibility: hidden;
  }
  .section4 .playiconnews {
    left: 2.5rem;
    top: 5rem;
    position: relative;
  }
  .section4 .leftplusnotit {
    position: absolute;
    margin-left: 26%;
    margin-top: 10%;
  }
  .section4 .leftplusn {
    position: relative;
    margin-left: 10%;
    margin-top: 1%;
  }
  .section4 .rightplusn {
    position: relative;
    margin-left: 10%;
    margin-top: 1%;
  }
  .section4 .rightplusnotit {
    width: 100%;
    position: absolute;
    margin-left: -2%;
    margin-top: 32%;
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
}
@media (min-width: 660px) and (max-width: 767px) {
  .section4 .newsbg {
    height: auto;
    width: 100%;
  }

  .section4 .newssectionpart {
    margin-top: 32rem;
  }
  .section4 .nw1 .newsspartpluselement {
    position: absolute;
    margin-top: 0rem;
    margin-left: 6rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  /* .section4 .nw1 h5 {
    position: relative;
    margin-top: 3%;
    left: 26.5%;
    font-size: 1rem !important;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .section4 .nw1 h6 {
    position: relative;
    left: 26.5%;
    font-size: 1rem;
    margin-top: 9%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  } */
  .section4 .newstextright p {
    margin-top: 3rem;
    /* margin-left: 10%; */
    width: 100%;
    text-transform: none;
  }
  .section4 .nw2 .newsspartpluselement {
    margin-top: 0rem;
    margin-left: 39rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .section4 .nw2 h6 {
    /* position: absolute; */
    /* right: 26.5%; */
    /* top: 25%; */
    position: relative;
    width: 100%;
    margin-left: 89%;
    margin-top: 6%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    /* padding: 0px; */
    /* padding-bottom: 0rem; */
  }
  /* .section4 .nw2 h5 {
    position: relative;
    width: 100%;
    margin-left: 90%;
    margin-top: -20%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
  }
  .section4 .newscontentpart h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #fff;
    left: -4.5rem;
    top: 20rem;
    letter-spacing: 0.1rem;
    position: relative;
  } */
  .section4 .smallxnews {
    position: absolute;
    margin-left: 10rem;
    margin-top: 13rem;
    z-index: 2;
    opacity: 1;
  }
  .section4 .playiconnews {
    left: -14.5rem;
    top: 15rem;
    position: relative;
  }
  .section4 .mrsection .newstextleft p {
    /* width: 82%; */
    text-align: justify;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: none;
    margin-top: 3rem;
    margin-bottom: 2rem;
    /* margin-left: 6rem; */
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .section4 .newsbg {
    height: auto;
    width: 100%;
  }
  .section4 .newssectionpart {
    margin-top: 25rem;
  }
  .section4 .nw1 .newsspartpluselement {
    position: absolute;
    margin-top: 0rem;
    margin-left: 6rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  /* .section4 .nw1 h5 {
    position: relative;
    margin-top: 3%;
    left: 22.5%;
    font-size: 1rem !important;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  } */
  /* .section4 .nw1 h6 {
    position: relative;
    left: 22.5%;
    font-size: 1rem;
    margin-top: 6%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  } */
  .section4 .newstextright p {
    margin-top: 1rem;
    /* margin-left: 10%; */
    width: 100%;
    text-transform: none;
  }
  .section4 .nw2 .newsspartpluselement {
    margin-top: 0rem;
    margin-left: 39rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .section4 .nw2 h6 {
    /* position: absolute; */
    right: 26.5%;
    margin-top: 52px;
    font-size: 1rem;
    /* letter-spacing: 0.2rem; */
    /* line-height: 2rem; */
    /* font-weight: 400; */
    color: #fff;
    /* padding: 0px; */
    /* padding-bottom: 0rem; */
  }
  /* .section4 .nw2 h5 { */
    /* position: absolute; */
    /* right: 26.5%; */
    /* margin-top: -2%; */
    /* font-size: 1rem; */
    /* letter-spacing: 0.2rem; */
    /* font-weight: 400; */
    /* color: #fff; */
    /* padding: 0px; */
    /* padding-bottom: 0rem; */
  /* } */
  .section4 .newscontentpart h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #fff;
    left: -6.5rem;
    top: 12rem;
    letter-spacing: 0.1rem;
    position: relative;
  }
  .section4 .smallxnews {
    position: absolute;
    margin-left: 8rem;
    margin-top: 5rem;
    z-index: 2;
    opacity: 1;
  }
  .section4 .playiconnews {
    left: -15.5rem;
    top: 7rem;
    position: relative;
  }
  .section4 .newspart {
    margin-top: -8rem;
  }
  .section4 .leftplusn {
    position: relative;
    margin-left: 10%;
    margin-top: 4%;
  }
  .section4 .rightplusn {
    position: relative;
    margin-left: 10%;
    margin-top: 4%;
  }
  .section4 .leftplusnotit {
    position: absolute;
    margin-left: 24%;
    margin-top: 8%;
  }
  .section4 .rightplusnotit {
    width: 100%;
    position: absolute;
    margin-left: -2%;
    margin-top: 9%;
  }
  .section4 .newstextbg {
    margin-left: 10%;
    width: 84%;
    background: #3fa6f2;
    height: 11vh;
  }
  .section4 .news-item-photo {
    margin: 30px;
    margin-top: 4rem;
    height: 0;
    position: relative;
    overflow: hidden;
  }
  .section4 .news-item-photo img {
    position: absolute;
    margin-top: 57px;
    height: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }

  .section4 .newstextrightbg {
    /* margin-left: 7%;
    width: 80%; */
    background: #3fa6f2;
    /* height: 11vh; */
  }
  .section4 .newsimgrbbtn {
    position: absolute;
    top: 89%;
    left: 75%;
    z-index: 5;
  }
  .section4 .newsimgarelement {
    position: absolute;
    left: 75%;
    bottom: 16%;
    z-index: 2;
  }
  .section4 .mrsection {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .section4 .bntext h3 {
    font-size: 3rem;
  }
  .section4 .bnbpluselement {
    position: absolute;
    left: 59.8%;
    top: 3%;
  }
  .section4 .bntext {
    margin-left: 6rem;
    margin-top: 20rem;
  }
  .section4 .blogbigimg h4 {
    font-size: 22px;
    padding-right: 37px;
  }
  .section4 .bnarelement {
    left: 14%;
    bottom: 1%;
  }
  .section4 .smlogo {
    left: 1%;
    top: 27%;
  }
  /* .section4 .bl1 h5 {
    left: 17.5%;
    top: 5%;
  }
  .section4 .bl1 h6 {
    left: 17.5%;
    top: 17%;
  } */
  .section4 .blogimgrbbtn {
    top: 80%;
    left: 65%;
    z-index: 5;
  }
  .section4 .smallxnews {
    margin-left: 12rem;
  }
  .section4 .container-fluid {
    padding-left: 0px;
  }
  .section4 .techserviceelement1bot {
    position: absolute;
    right: 13%;
    bottom: 4%;
    z-index: 2;
    opacity: 1;
  }
  .section4 .bgbnarelement {
    position: absolute;
    left: 4%;
    bottom: 79%;
  }
  .section4 .nw1 .newsspartpluselement {
    position: absolute;
    margin-top: -1rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .section4 .newstextright p {
    width: 100%;
    margin-top: 0.4rem;
    /* margin-left: 10%; */
    padding-inline: 5%;
  }
  .section4 .newstextleft p {
    /* width: 80%; */
    margin-top: 2.5rem;
    /* margin-left: 2rem; */
    /* padding-inline: 5%; */
  }
  /* .section4 .nw1 h5 {
    margin-top: 3%;
    position: relative;
    left: 19.5%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .section4 .nw1 h6 {
    position: relative;
    display: flex;
    margin-top: 39px;
    left: 19.5%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  } */
  .section4 .newstextbg {
    left: ato;
    width: 85%;
    background: #3fa6f2;
    height: 10vh;
    margin-left: auto;
  }
  .section4 .nw2 .newsspartpluselement {
    margin-top: 0.2rem;
    margin-left: 23rem;
    margin-bottom: -2rem;
    z-index: 1;
  }
  /* .section4 .nw2 h5 {
    font-size: 1rem;
    position: relative;
    margin-top: -18%;
    margin-left: 75%;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
  }
  .section4 .nw2 h6 {
    position: relative;
    margin-left: 73%;
    margin-top: 6%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
  } */
  .section4 .newstextrightbg {
    /* width: 81%; */
    background: #3fa6f2;
    /* height: 11vh; */
  }
  .section4 .newsdotsbgleft {
    height: fit-content;
  }
  .section4 .newsdotsbgright {
    height: fit-content;
  }
  .section4 .newsbg {
    height: auto;
    width: 100%;
  }
  .section4 .newspart {
    margin-top: -7rem;
  }
  .section4 .newstextbg p {
    margin-top: -1.5%;
  }
  /* .section4 .newstextrightbg p {
    margin-top: -1%;
  } */
  .section4 .news-item-photo {
    margin: 9px;
    margin-top: 54px;
    height: 0;
    /* padding-bottom: 80%; */
    position: relative;
  }
  .section4 .news-item-photo img {
    position: absolute;
    height: 100%;
  }
  .section4 .leftplusnotit {
    position: absolute;
    margin-top: 6.3%;
    margin-left: 18%;
  }
  .section4 .rightplusnotit {
    width: 100%;
    position: absolute;
    margin-top: 25%;
    margin-left: -3%;
  }
  .section4 .leftplusn {
    position: relative;
    margin-top: 0%;
  }
  .section4 .rightplusn {
    position: relative;
    margin-top: 0%;
  }
  .section4 .playiconnews {
    left: -20.5rem;
    top: 3rem;
    position: relative;
  }
  .section4 .newscontentpart h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #fff;
    left: -11.5rem;
    top: 7.4rem;
    letter-spacing: 0.1rem;
    position: relative;
  }
  .section4 .news-item-photo img {
    top: 14px;
  }
  .section4 .blogimgtpluselement {
    position: absolute;
    left: 40%;
    top: 39%;
    z-index: 10;
  }
}
@media (min-width: 1440px) {
  .section4 .newspart {
    margin-top: -6rem;
  }
  .section4 .bnbpluselement {
    position: absolute;
    left: 56.8%;
    top: 4%;
    z-index: 1;
  }
  .section4 .smlogo {
    position: absolute;
    left: 62%;
    top: 22%;
  }
  .section4 .bntext {
    margin-left: 3rem;
    margin-top: 16rem;
  }
  .section4 .bnbiglogo {
    position: absolute;
    left: 2%;
    top: 10.3%;
  }
  /* .section4 .bl1 h5 {
    position: absolute;
    left: 15.5%;
    top: 4%;
  }
  .section4 .bl1 h6 {
    position: absolute;
    left: 15.5%;
    top: 14%;
  } */
  .section4 .nw2 .newsspartpluselement {
    margin-top: 3.4rem;
    margin-left: 38rem;
  }
  .section4 .container-fluid {
    width: 100%;
    padding-right: 0px;
  }
  .section4 .news-item-photo {
    margin-top: 8rem;
    margin-left: -4rem;
  }
  .section4 .newsimgrbbtn {
    position: absolute;
    left: 53%;
  }
  .section4 .newsimgarelement {
    top: 83%;
    left: 52%;
  }
  .section4 .blogimgtpluselement {
    left: 31%;
    top: 58%;
  }
  .section4 .blogbigimg h4 {
    font-size: 22px;
  }
  .section4 .bnarelement {
    left: 15%;
    bottom: 22%;
  }
  /* .section4 .explorelap {
    text-align: center !important;
    top: 40%;
    left: 44%;
  } */
  .section4 .bgbnarelement {
    position: absolute;
    left: 4%;
    bottom: 79%;
  }
  .section4 .leftplusnotit {
    position: absolute;
    margin-top: 11.5%;
    margin-left: 15%;
  }
  .section4 .rightplusnotit {
    position: absolute;
    width: 100%;
    margin-top: 15% !important;
    margin-left: 1% !important;
  }
  .section4 .newscontentpart h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #fff;
    left: -5.5rem;
    top: 7.4rem;
    letter-spacing: 0.1rem;
    position: relative;
  }
  .section4 .playiconnews {
    left: -13.5rem;
    top: 3rem;
    position: relative;
  }
  /* .section4 .nw2 h5 {
    position: absolute;
    right: 23.5%;
    margin-top: -5%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .section4 .nw2 h6 {
    position: absolute;
    right: 23.5%;
    margin-top: 5%;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  } */
  .section4 .newstextbg {
    margin-left: 11%;
    width: 89%;
    background: #3fa6f2;
    height: 13vh;
  }
  .section4 .newstextbg p {
    color: #fff;
    font-size: 0.7rem;
    text-transform: uppercase;
    padding: 4px 10px 0px 30px;
    line-height: 2.3rem;
  }
  /* .section4 .newstextrightbg {
    height: 12vh;
  } */
  .section4 .newstextright p {
    margin-top: 10rem;
  }
  .section4 .leftplusn {
    position: absolute;
    margin-top: 5%;
  }
  .section4 .rightplusn {
    position: absolute;
    margin-top: 5%;
  }
  .section4 .newstextright p {
    /* margin-left: 11%; */
    /* width: 77%; */
    text-align: justify;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .section4 .mrsection .newstextleft p {
    /* width: 77%; */
    text-align: justify;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* margin-left: 7rem; */
  }
  .section4 .newsbg {
    height: auto;
  }
  .section4 .leftplusn {
    position: relative;
    margin-top: 7%;
  }
  .section4 .rightplusn {
    position: relative;
    margin-right: 8%;
    margin-top: 7%;
  }
}

/* for i phone XR  */
/* @media only screen and (min-device-width: 414px) and (max-device-width: 828px) and (-webkit-device-pixel-ratio: 2) {
  .section4 .leftplusnotit {
    margin-top: 12.5%;
  }
  .section4 .rightplusnotit {
    position: absolute;
    width: 100%;
    margin-top: 47% !important;
    margin-left: -9% !important;
  }
  .section4 .newstextbg {
    width: 100%;
    background: #3fa6f2;
    height: 7vh;
  }
  .section4 .newsbg {
    height: auto !important;
  }
} */

/* ipad mini  */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .section4 .newstextrightbg {
    margin-left: 7%;
    width: 80%;
    background: #3fa6f2;
    height: 7vh;
  }
  .section4 .newstextbg {
    margin-left: 10%;
    width: 84%;
    background: #3fa6f2;
    height: 7vh;
  }
} */
