
 
  .bnbg11 {
 
    background: linear-gradient(
      90deg,
      rgba(2, 112, 183, 1) 0%,
      rgba(85, 81, 140, 1) 100%
    );

    width: 100%;
  }

  .bnbiglogoo {
    position: absolute;
    left: -1%;
    top: 1%;
    z-index: 2;
  }
  .smallxbnn {
    position: absolute;
    margin-left: 25%;
    margin-top: 3rem;
    z-index: 2;
    opacity: 1;
  }
  .blogplay{
    position: absolute;
    display: flex;
    top:22%;
    left:17%;
  }
  .blogplay h3{
    font-size: 3rem;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.1rem;
    position: relative;
  }
  .blogpositionn{
    
    /* left:4%; */
    top:19rem;
    z-index: 1;
  }
  .bnpluselement1{
    position: absolute;
  }
  .roww{

    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .row1{
    margin-left:-60px;
  }
 
  .blogbigimg1{
       /* margin-top: 5rem; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
  }
  .blogbigimg1 p {
    display: flex;   
    font-size: 1rem;
    opacity: 1;
    color: #fff;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    z-index: 15;    
    padding:0 2rem;
  }
  .blogbigimg1 h4 {
    justify-content: center;
    text-align: center;
    display: flex;
    text-transform: uppercase;
    opacity: 1;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    color: #fff;
    z-index: 15;
  }
  .featcontt{
    position:absolute;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: rgb(2, 112, 183,0.3); 
    border-radius: 5%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 18%;
    
  }
  
  @media (max-width: 768px) {

    /* .row1{
      margin-left:65px;
    } */
   
    .bnbiglogoo img{
    
      height: 75%;
      width: 75%;
    }
    .blogpositionn{
    
      /* left:2.5%; */
      top:17rem;
      
    }
    /* .blogpositionn img{
    height:75%;
    width:75%;
    } */
    
    .blogbigimg1 h4 {
    
      font-size: 1.5rem;
  
    }
    .smallxbnn {
      position: absolute;
      margin-left: 25%;
      margin-top: 3rem;
      z-index: 2;
      opacity: 1;
    }
    .blogplay{
      position: absolute;
      display: flex;
      top:22%;
      left:17%;
    }
    .blogplay h3{
      font-size: 3rem;
      font-weight: 600;
      color: #fff;
      letter-spacing: 0.1rem;
      position: relative;
    }
  }
  @media (max-width: 430px){
    .bnbiglogoo img{
    
      height: 40%;
      width: 40%;
    }
    .blogpositionn img{
      height:50%;
      width:50%;
      }
      .blogbigimg1 h4 {    
        font-size: 1.1rem;    
      }
      .blogpositionn{    
        /* left:2.5%; */
        top:10rem;
       
      }
      .blogbigimg1{
        margin-top: 9rem;
      }

      /* .row1{       
        margin-left:45px;        
      } */
      .featcontt{
       
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 10%;
        
      }
      .blogbigimg1 p {
        font-size: 0.9rem;
        opacity: 1;
        color: #fff;
        line-height: 1.4rem;       
        padding:0 1rem;
      }
      .smallxbnn img{
       height:50%;
       width:50%;
      }
      .blogplay  img{
        height:30px;
       width:30px;
      }
      .blogplay{
        position: absolute;
        display: flex;
        top:22%;
        left:9%;
      }
   
      .blogplay h3{
        font-size: 1.8rem;
      }
  }
