/* .cookiepolicy .wwabpluselement {
  position: absolute;
  left: 15% !important;
  top: 39% !important;
  z-index: 1;
} */
.cookiepolicy .techbannertext {
  left: 18%;
  top: 43%;
}

.cookiepolicy .visthailandbannerfont {
  font-size: 3rem !important;
  text-align: initial;
  line-height: 3rem !important;
}
.cookiepolicy .visthailandnormalfont {
  letter-spacing: 0.5rem;
  font-size: 2.5rem !important;
  text-align: initial;
  line-height: 0rem !important;
}
.cookiepolicy .cookiebanner {
  background: linear-gradient(rgba(23, 49, 108, 0.626), rgba(14, 28, 85, 0.753)),
    url("../img/COOKIES.jpg") top;
  background-size: cover;
}
.cookiepolicy .bgsquarex {
  background-image: url("../img/elementsmallx.png");
  background-size: contain;
  object-fit: contain;
}
.cookiepolicy .vtpackbtn {
  position: absolute;
  top: 88%;
  left: 15%;
}
.cookiepolicy .wwabpluselement {
  position: absolute;
  left: 7% !important;
  top: 41% !important;
  z-index: 1;
}

.cookiepolicy .wwabpluselement img {
  height: 210px;
  width: 210px;
}
.cookiepolicy .bgthailandblue {
  background-color: #3891da;
}

.cookiepolicy .social-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}
.cookiepolicy .footersocial-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}

.cookiepolicy .serviceelementjwwab {
  position: absolute;
  left: 20%;
  bottom: 12%;
  z-index: 2;
  width: 50%;
  top: 78%;
}

.cookiepolicy .linearthailandbgm {
  background: rgb(1, 112, 184);
  background: linear-gradient(
    90deg,
    rgba(1, 112, 184, 1) 0%,
    rgba(59, 92, 155, 1) 50%,
    rgba(94, 90, 147, 1) 100%
  );
}

.cookiepolicy .op8 {
  background-color: #caa323;
  opacity: 0.38;
  width: 350px;
  height: 200px;
}
.cookiepolicy .editcardiv {
  top: 35%;
  left: 20%;
}
.cookiepolicy .editcardiv {
  letter-spacing: 2rem;
  height: fit-content;
}
.cookiepolicy .editcardiv .loose {
  display: flex;
  letter-spacing: 0.1em;
  font-size: 15px;
  color: white;
  top: 3px;
  overflow: hidden;
}
.cookiepolicy .editcarplus {
  top: -18%;
  left: -32%;
  height: 100px !important;
  width: 100px !important;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}
.cookiepolicy .MesgPicthailand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cookiepolicy .MesgPicthailand img {
  height: 90%;
  width: 90%;
}
.cookiepolicy .editplusnew {
  left: -1%;
  top: 10%;
}
.cookiepolicy .editplusnewright {
  right: -1%;
  top: 10%;
}
.cookiepolicy .h-150 {
  height: 150px;
}
/* ////////TERMS & CONDITION ////////// */

.cookiepolicy .termeffectiveDate {
  font-size: 2.2rem;
  position: relative;
  left: 0.5rem;
}
.cookiepolicy .termhead {
  position: relative;
  /* margin-top: 3rem; */
  top: 30px;
  font-size: 1.1rem;
  word-spacing: 6px;
  /* background-color: #caa323; */
}
.cookiepolicy .termpara {
  position: relative;
  top: 30px;
  line-height: 2rem;
  font-size: 1.1rem;
  word-spacing: 3px;
  letter-spacing: 1.5px;
}
.cookiepolicy .termpart {
  /* font-size: 2rem; */
  letter-spacing: 3.1px;
  font-style: bold;
}
.cookiepolicy .termupdate {
  margin-top: 20px;
  font-size: 1.5rem;
}
.cookiepolicy .wwasmlogo {
  position: absolute;
  left: 0%;
  top: 24%;
}
.cookiepolicy .head2 {
  font-size: 15.5px;
}
.cookiepolicy .abouttext {
  font-size: 15.5px;
}
@media (max-width: 426px) {
  .termeffectiveDate {
    background-color: green;
  }
  .cookiepolicy .techelementsmallx {
    display: none;
  }
  .cookiepolicy .techbannertext {
    width: 75%;
  }

  .cookiepolicy .techbannertext {
    top: 50%;
  }
  .cookiepolicy .wwabpluselement img {
    height: 150px;
    width: 150px;
  }
  .cookiepolicy .wwasmlogo {
    display: none;
  }
  .cookiepolicy .techpulusbot {
    display: none;
  }
  .cookiepolicy .serviceelementjwwab {
    top: 83%;
  }
  .cookiepolicy .wwabpluselement img {
    visibility: hidden;
  }
  .cookiepolicy .btn {
    padding: -0.25rem 2rem 0.75rem;
  }

  .cookiepolicy .vtpackbtn {
    top: 87%;
    left: 3%;
  }
  .cookiepolicy .padmob {
    /* padding: 20px; */
  }
  .cookiepolicy .mobnone {
    display: none;
  }
  .cookiepolicy .mobcenter {
    text-align: center;
    padding-bottom: 10px;
  }
  .cookiepolicy .mobborderbottom {
    border-bottom: 1px solid white;
  }
  .cookiepolicy .editplusnew {
    left: -3%;
    top: 2%;
  }
  .cookiepolicy .editplusnewright {
    right: -3%;
    top: 2%;
  }
  .cookiepolicy .mobpad {
    padding-bottom: 15px;
  }
  .cookiepolicy .blogelementsmallx {
    display: none;
  }
  .cookiepolicy.bl1 .blogpartpluselement {
    margin-top: -18px;
    margin-left: -1rem;
  }
  .cookiepolicy .blogimgrbbtn {
    left: 52%;
  }
  .cookiepolicy .blogimgarelement {
    left: 57%;
  }
  .cookiepolicy .blogimgtpluselement {
    left: 30%;
    top: 37%;
  }
  .cookiepolicy .blogimgrbbtn .editbottom a {
    bottom: 13px;
    position: relative;
  }
  .cookiepolicy .blogimgarelement {
    bottom: 24%;
  }
  .article-item-photo {
    margin-right: 1rem;
  }
  .cookiepolicy .editcarplus {
    top: -28%;
  }
  .cookiepolicy .techbannertext {
    left: 15%;
  }
  .cookiepolicy .bl1 h5 {
    position: absolute;
    left: 26.5%;
    top: 1%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 26.5%;
    top: 19%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy .share {
    font-size: 14px;
  }
  .cookiepolicy .cookiepolicy.op8 {
    height: 30vh;
  }
  .cookiepolicy .visthailandnormalfont {
    letter-spacing: 0.5rem;
    font-size: 1.6rem !important;
    text-align: initial;
    line-height: 0rem !important;
  }
  .cookiepolicy .termupdate {
    margin-left: -29%;
    font-size: 1.5rem;
  }
  .cookiepolicy .termpart {
    font-size: 1.5rem;
    letter-spacing: 0px;
    font-style: bold;
  }
  .cookiepolicy .head2 {
    font-size: 12.5px;
  }
  .cookiepolicy .abouttext {
    font-size: 12.5px;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .cookiepolicy .visthailandnormalfont {
    letter-spacing: 0.1rem;
    font-size: 1.5rem !important;
    text-align: initial;
  }
  .cookiepolicy .head2 {
    font-size: 12.5px;
  }
  .cookiepolicy .abouttext {
    font-size: 12.5px;
  }
  .cookiepolicy .termupdate {
    margin-left: -2%;
    font-size: 1.5rem;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .cookiepolicy .visthailandnormalfont {
    letter-spacing: 0.1rem;
    font-size: 1.5rem !important;
    text-align: initial;
  }
  .cookiepolicy .head2 {
    font-size: 12px;
  }
  .cookiepolicy .abouttext {
    font-size: 12px;
  }
  .cookiepolicy .termupdate {
    margin-left: -18%;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .cookiepolicy.serviceelementjwwab {
    position: absolute;
    left: 20%;
    bottom: 12%;
    z-index: 2;
    width: 50%;
    top: 83%;
  }
  .cookiepolicy.vtpackbtn {
    position: absolute;
    top: 88%;
    left: 14%;
  }
  .cookiepolicy.wwasmlogo {
    visibility: hidden;
  }
  .cookiepolicy.bl1 h5 {
    position: absolute;
    left: 13.5%;
    top: 1%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy.bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 13.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
  }
  .cookiepolicy.bl1 .blogpartpluselement {
    position: absolute;
    margin-top: -1rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .cookiepolicy.blogimgrbbtn {
    position: absolute;
    top: 82%;
    left: 68%;
    z-index: 5;
  }
  .cookiepolicy.share {
    font-size: 14px;
  }
  .cookiepolicy.cookiepolicy.op8 {
    height: 30vh;
  }
}
@media screen and (min-width: 1024px) {
  .cookiepolicy.wwabpluselement {
    position: absolute;
    left: 6% !important;
    top: 47% !important;
    z-index: 1;
  }
  .cookiepolicy .editplusnew {
    left: -1%;
    top: 12%;
  }
  .cookiepolicy .editplusnewright {
    right: -1%;
    top: 11%;
  }
  .cookiepolicy .bl1 h5 {
    position: absolute;
    left: 19.5%;
    top: 3%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 19.5%;
    top: 14%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy .blogimgrbbtn {
    position: absolute;
    top: 79%;
    left: 67%;
    z-index: 5;
  }
  .cookiepolicy .blogimgarelement {
    position: absolute;
    left: 70%;
    bottom: 22%;
    z-index: 2;
  }
  .cookiepolicy .share {
    font-size: 14px;
  }
  .cookiepolicy .cookiepolicy .op8 {
    height: 30vh;
  }
  .cookiepolicy .editcarplus {
    top: -18%;
    left: -22%;
  }
  .cookiepolicy .abouttext {
    line-height: 25px;
    font-size: 16px;
    word-spacing: 4px;
  }
  .cookiepolicy .MesgPicthailand img {
    height: 119%;
    width: 90%;
  }
  .cookiepolicy .roundedtop {
    border-top-left-radius: 121px;
    border-top-right-radius: 121px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
  .cookiepolicy .author {
    font-size: 14px;
  }
  .cookiepolicy .manasi {
    font-size: 14px;
  }
  .cookiepolicy .roundedtop {
    height: 300px !important;
    width: 242px !important;
    object-fit: fill;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
    /* padding-left: 14px; */
  }
  .cookiepolicy .phiphi1 {
    padding-left: 14px;
  }
  .cookiepolicy .phiphi2 {
    margin-left: 14px;
  }
  .cookiepolicy .phiphi3 {
    padding-right: 14px;
  }
  .cookiepolicy .phiphi4 {
    padding-right: 14px;
  }
}
@media screen and (min-width: 1440px) {
  .cookiepolicy .editplusnew {
    left: -1%;
    top: 20%;
  }
  .cookiepolicy .editplusnewright {
    right: -1%;
    top: 13%;
  }
  .cookiepolicy .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 16.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy .bl1 h5 {
    position: absolute;
    left: 16.5%;
    top: 0%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .cookiepolicy .share {
    font-size: 14px;
  }
  .cookiepolicy .cookiepolicy .op8 {
    height: 30vh;
  }
  .cookiepolicy .editcarplus {
    top: -18%;
    left: -22%;
  }
  .cookiepolicy .abouttext {
    line-height: 25px;
    font-size: 16px;
    word-spacing: 4px;
  }
  /* .cookiepolicy.MesgPicthailand img {
    height: 133%;
    width: 90%;
  } */
  .cookiepolicy .roundedtop {
    height: 357px !important;
    width: 319px !important;
    object-fit: fill;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
}
@media (min-width: 427px) and (max-width: 821px) {
  .cookiepolicy .wwabpluselement {
    left: 2% !important;
    top: 48% !important;
  }
  .cookiepolicy .tabnone {
    display: none;
  }
  .cookiepolicy .editplusnew {
    top: 6%;
  }
  .cookiepolicy .editplusnewright {
    top: 6%;
  }
  .cookiepolicy .padmob {
    padding: 20px;
  }
  .cookiepolicy .cookiepolicy .op8 {
    height: 30vh;
  }
  .cookiepolicy .wwasmlogo {
    visibility: hidden;
  }
  .cookiepolicy .visthailandnormalfont {
    letter-spacing: 0.5rem;
    font-size: 2.5rem !important;
    text-align: initial;
    line-height: 8rem !important;
  }
}
