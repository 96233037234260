/* @font-face {
    font-family: 'Brandon';
    src:local("Brandon"), url('../fonts/brandon-text-font-family-1734407372-0/HvDTrial_Brandon_Text_Regular-BF6514e9eb617a0.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Brandon';
    src: local("Brandon"), url('../fonts/brandon-text-font-family-1734407372-0/HvDTrial_Brandon_Text_Medium-BF6514e9eb272a3.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Brandon';
    src: local("Brandon"), url('../fonts/brandon-text-font-family-1734407372-0/HvDTrial_Brandon_Text_Bold-BF6514e9eb720dc.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  } */

  .servicehead{
    /* font-size:3rem;       */
    font-size:20px;      
    letter-spacing: 0.1rem;
    font-weight: 600;
    font-family: "Brandon";
    color: #FFF;      
}
.NormalHeading{
/* font-size:1.8rem; */
font-size:20px;
margin-bottom: 1.5rem;
font-weight: 600;
font-family: "Brandon";
/* color: #b3b3b3; */
}
.SubHeading{
   /* font-size:1.4rem; */
   font-size:16px;
   margin-bottom: 1.5rem;
   font-weight: 500;
   font-family: "Brandon";
   color:#fff;
   line-height: 1.25;
}
.Pyara{
/* font-size:1.1rem;  */
font-size:14px; 
font-family: "Brandon";
color:#fff;
 font-weight:normal;
display: flex;  
margin-bottom: 2rem;
}
.plusrotate{
margin-left:-74px;
}
.plushead{
  position: relative;
  top:92px;  
}
.plushead .NormalHeading{
margin-top: 25px;
margin-left: -50px;
}
.travelmanagementpart .SubHeading{
margin-top: 20px;
margin-left: 50px;
}
.travelmanagementpart .Pyara{
margin-top:-10px;
margin-left: 50px;
text-align: justify;
}
.accmanagementpartb .NormalHeading{
margin-top: 25px;
margin-left: -50px;
}
.accmanagementpartb .Pyara{
margin-top: -65px;
margin-left: 50px;
text-align: justify;
}
.leitravelmanagementpart .NormalHeading{
margin-top: -50px;
margin-left: 40px;
}
.leitravelmanagementpart .SubHeading{
margin-left: 40px;
}
.leitravelmanagementpart .Pyara{
    margin-left: 40px;
    margin-top:-20px;
    text-align: justify;
    width:70%;
    
}


/* / Mobile S / */
@media only screen and (max-width: 320px) {
/* / Styles for Mobile S go here / */
    
.NormalHeading{
    /* font-size:1.5rem; */
    font-size:20px;
    margin-bottom: 1rem;
   
   }
   .SubHeading{
       /* font-size:1.2rem; */
       font-size:16px;
       margin-bottom: 1rem;
      
   
   }
   .Pyara{
    font-size:1rem; 
   
    margin-bottom: 1rem;
   }
   .servicehead{
    /* font-size:2.3rem;  */
    font-size:20px; 
}
.servciebiglogoha-fixed img{
    height: 105px;
width: 105px;
}
.plusrotate{
    margin-left:-20px;
   }
   .plushead{
      position: relative;
      top:50px;  
   }
   .plushead .NormalHeading{
    margin-top: -10px;
    margin-left: -40px;
   }
   .plushead .SubHeading{
    margin-top: -2px;
    margin-left: -40px;
   }
   .plusrotate img{
    width:110px;
    height:110px;
   }
    
   .travelmanagementpart .SubHeading{          
  
    margin-top: -2px;
   }
   .accmanagementpartb .NormalHeading{
    margin-top: 8px;
   }
   .accmanagementpartb .plusrotate {
    margin-left:-38px;
   }
   .accmanagementpartb .Pyara{
    margin-top: -45px;
    margin-left: 35px;
    width:80%;
   }
   .thumblike{
    width:75%;
    height:75%;
   } 
   .pulseimagesmallx{
    width:110px;
    height:110px;
   }
   .plusimg{
    width:110px;
    height:110px;
    top:73px !important;
   }
   .leitravelmanagementpart .plusrotate{
    
    width:110px;
    height:110px;
   }
   .leitravelmanagementpart .plushead{
    top:75px;
   }
   .leitravelmanagementpart{
    margin-left:0 !important;
    margin-top: -3rem !important;
    width:100%;
   }
   .leitravelmanagementpart .NormalHeading{
    margin-top: -40px;
    width:100%;
   }
   .leitravelmanagementpart .SubHeading{
    width:95%;
    margin-bottom: 2rem;
   }
   .leitravelmanagementpart .Pyara{
    width:100%;
   }
   .litrlogo{
    display: none;
   }
   .leitr .pulseingimg{
    left:20px;
    position: relative;
   }
   .leisuretravel .leitr h5{
    left:50px !important;
   }
}

/* / Mobile M / */
@media only screen and (min-width: 321px) and (max-width: 375px) {
/* / Styles for Mobile M go here / */

  
.NormalHeading{
    font-size:1.5rem;
    margin-bottom: 1rem;
   
   }
   .SubHeading{
       font-size:1.2rem;
       margin-bottom: 1rem;
      
   
   }
   .Pyara{
    font-size:1rem; 
   
    margin-bottom: 1rem;
   }

   .servicehead{
    font-size:2.3rem; 
}
.servciebiglogoha-fixed img{
    height: 110px;
width: 110px;
}
.plusrotate{
    margin-left:-20px;
   }
   .plushead{
      position: relative;
      top:50px;  
   }
   .plushead .NormalHeading{
    margin-top: 8px;
    margin-left: -40px;
   }
   .plusrotate img{
    width:110px;
    height:110px;
   }
   
   .travelmanagementpart .SubHeading{          
  
    margin-top: -2px;
   }
   .accmanagementpartb .NormalHeading{
    margin-top: 8px;
    margin-left: -40px;
   }
   .accmanagementpartb .plusrotate {
    margin-left:-38px;
   }
   .accmanagementpartb .Pyara{
    margin-top: -45px;
    margin-left: 35px;
    width:80%;
   }
   .thumblike{
    width:75%;
    height:75%;
   } 
   .plusimg{
    width:110px;
    height:110px;
    top:73px !important;
   }
   .leitravelmanagementpart .plusrotate{
    
    width:110px;
    height:110px;
   }
   .leitravelmanagementpart .plushead{
    top:75px;
   }
   .leitravelmanagementpart{
    margin-left:0 !important;
    margin-top: -3rem !important;
    width:100%;
   }
   .leitravelmanagementpart .NormalHeading{
    margin-top: -18px;
    width:100%;
   }
   .leitravelmanagementpart .SubHeading{
    width:85%;
    margin-bottom: 2rem;
   }
   .litrlogo{
    display: none;
   }
   .leitr .pulseingimg{
    left:50px;
    position: relative;
   }
   .leitravelmanagementpart .Pyara{
    width:100%;
   }
}
/* / Mobile L / */
@media only screen and (min-width: 376px) and (max-width: 430px) {
/* / Styles for Mobile L go here / */

.NormalHeading{
    /* font-size:1.6rem; */
    font-size: 20px;
    margin-bottom: 1rem;
   
   }
   .SubHeading{
       /* font-size:1.3rem; */
       font-size:16px;
       margin-bottom: 1rem;
      
   }
   .Pyara{
    font-size:14px;
    margin-bottom: 1rem;
   
   }
   .servicehead{
    /* font-size:2.5rem;  */
    font-size:20px; 
}
    .servciebiglogoha-fixed img{
        height: 110px;
        width: 110px;
    }
    .plusrotate{
        margin-left:-20px;
       }
       .plushead{
          position: relative;
          top:50px;  
       }
       .plushead .NormalHeading{
        margin-top: 18px;
        margin-left: -40px;
       }
       .plusrotate img{
        width:110px;
        height:110px;
       }
       .travelmanagementpart .SubHeading{          
      
        margin-top: -2px;
       }
       .accmanagementpartb .NormalHeading{
        margin-top: 8px;
        margin-left: -40px;
       }
       .accmanagementpartb .plusrotate {
        margin-left:-38px;
       }
       .accmanagementpartb .Pyara{
        margin-top: -45px;
        margin-left: 35px;
        width:80%;
       }
       .thumblike{
        width:80%;
        height:80%;
       } 
       .plusimg{
        width:110px;
        height:110px;
        top:73px !important;
       }
       .leitravelmanagementpart .plusrotate{
    
        width:110px;
        height:110px;
       }
       .leitravelmanagementpart .plushead{
        top:75px;
       }
       .leitravelmanagementpart{
        margin-left:0 !important;
        margin-top: -3rem !important;
        width:100%;
       }
       .leitravelmanagementpart .NormalHeading{
        margin-top: -18px;
       }
       .leitravelmanagementpart .SubHeading{
        width:85%;
        margin-bottom: 2rem;
       }
       .leitravelmanagementpart .Pyara{
        width:100%;
       }
       .litrlogo{
        display: none;
       }
       .leitr .pulseingimg{
        left:50px;
        position: relative;
       }
       .leitr h5{
        left:80px;
       }
}
/* / Small screens / */
@media only screen and (min-width: 431px) and (max-width: 767px) {
/* / Styles for small screens go here / */

.NormalHeading{
    font-size:1.6rem;
    margin-bottom: 2rem;
    
   }
   .SubHeading{
       font-size:1.3rem;
       margin-bottom: 2rem;
             
   }
   .Pyara{
    font-size:1rem; 
  
   }
   .servicehead{
    font-size:2.8rem; }
    .servciebiglogoha-fixed img{
        height: 110px;
        width: 110px;
    }
    .plusrotate{
        margin-left:-20px;
       }
       .plushead{
          position: relative;
          top:50px;  
       }
       .plushead .NormalHeading{
        margin-top: 18px;
        margin-left: -40px;
       }
       .plusrotate img{
        width:110px;
        height:110px;
       }
       .travelmanagementpart .SubHeading{          
        margin-left: 60px;
        margin-top: -10px;
       }
        .accmanagementpartb .NormalHeading{
    margin-top: 8px;
    margin-left: -40px;
   }
   .accmanagementpartb .plusrotate {
    margin-left:-38px;
   }
   .accmanagementpartb .Pyara{
    margin-top: -45px;
    margin-left: 35px;
    width:80%;
   }
   .pulseimagesmallx{
    width:110px;
    height:110px;
   }
   .thumblike{
    width:80%;
    height:80%;
   } 
   .plusimg{
    width:110px;
    height:110px;
    top:73px !important;
   }
   .litrlogo{
    display: none;
   }

}
/* / Medium screens / */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
/* / Styles for medium screens go here / */


.NormalHeading{
    /* font-size:1.7rem;        */
    font-size:20px;       
   }
   .SubHeading{
       /* font-size:1.4rem;           */
       font-size:16px;          
   }
   .Pyara{
    /* font-size:1rem;  */
    font-size:14px; 
   
   }
   .servicehead{
    /* font-size:2.8rem;  */
    font-size:20px; 
}
    .servciebiglogoha-fixed img{
        height: 140px;
        width: 140px;
    }
        .plusrotate{
        margin-left:-20px;
       }
       .plushead{
          position: relative;
          top:50px;  
       }
       .plushead .NormalHeading{
        margin-top: 18px;
        margin-left: -40px;
       }
       .plusrotate img{
        width:120px;
        height:120px;
       }
       .travelmanagementpart .SubHeading{          
        margin-left: 60px;
        margin-top: -10px;
       }
       .travelmanagementpart .Pyara{
        width:80%;
       }
       .accmanagementpartb .NormalHeading{
        margin-top: 8px;
        margin-left: -40px;
       }
       .accmanagementpartb .Pyara{
        margin-top: -45px;
        margin-left: 60px;
        width:76%;
       }
       .leitravelmanagementpart .plusrotate{
        /* margin-left:-71px; */
        width:120px;
        height:120px;
       }
       .leitravelmanagementpart .plushead{
        margin-top:50px;
        position: relative;
       }
       .leitravelmanagementpart .NormalHeading{
        margin-top:-59px;
        margin-left:66px;
       }
       .leitravelmanagementpart .Pyara{
        width:100%;
        margin-left: 66px;
       }
       .leitravelmanagementpart .SubHeading{
        margin-left: 66px;
       }
       .leitravelmanagementpart .itmbtn{
        margin-left:6rem;
       }
       .leitravelmanagementpart .ltmelementbtm {
        margin-left:6rem;
       }
       .litrlogo{
        width:80%;
       }
       .pulseimagesmallx{
        width:100px;
        height:110px;
       }
       .thumblike{
        width:80%;
        height:80%;
       } 
       .plusimg{
        width:120px;
        height:120px;
        top:73px;
       }
       .businesstravel .counterwhitebg{
        height:350px !important;
       }
       .leitr h5{
        left:275px !important;
       }
}
/* / Large screens / */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
/* / Styles for large screens go here / */
}
/* / Extra large screens / */
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
/* / Styles for extra large screens go here / */
.NormalHeading{
    /* font-size:2rem; */
    font-size:20px;
   }
   .SubHeading{
       /* font-size:1.5rem;       */
       font-size:16px;      
   }
   .Pyara{
    /* font-size:1.3rem;  */
    font-size:14px; 
   }
   .servicehead{
    /* font-size:3.2rem;  */
    font-size:20px; 
    }
    /* .logomannai img{
        height: 29%;
        height: 29%;
    } */

}
/* / XXL screens / */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
/* / Styles for XXL screens go here / */
.NormalHeading{
    /* font-size:1.9rem; */
    font-size: 28px;
    margin-bottom: 2rem;
   }
   .SubHeading{
       /* font-size:1.6rem;        */
       font-size: 16px;
   }
   /* .logomannai img{
       height: 32%;
       height: 32%;
    } */
   .Pyara{
        /* font-size: 1.2rem; */
        font-size:16px; 
    }
   .servicehead{
    /* font-size:3.1rem;  */
    font-size: 28px; 
}
.plusrotate{
    margin-left:-74px;
   }
   .plushead{
      position: relative;
      top:92px;  
   }
   .plushead .NormalHeading{
    margin-top: 15px;
    margin-left: -50px;
   }
   .travelmanagementpart .SubHeading{
    margin-top: 20px;
    margin-left: 50px;
   }
   .accmanagementpartb .NormalHeading{
    margin-top: 10px;
    margin-left: -50px;
   }

}

/* / XXXL screens / */
@media only screen and (min-width: 1920px) and (max-width: 2999px) {
/* / Styles for XXXL screens go here / */
.NormalHeading{
    font-size:2.2rem;
    margin-bottom: 2rem;
   }
   .SubHeading{
       font-size:1.8rem;       
   }
   .Pyara{
    font-size: 1.4rem;
    
   
   }

   .servicehead{
    font-size:3.5rem; }

}
/* / screen more than  xxxl screens / */
@media only screen and (min-width: 3000px)  {
.NormalHeading{
    font-size:2.8rem;
    margin-bottom: 2.8rem;

   }
   .SubHeading{
       font-size:2.3rem;
       margin-bottom: 2.8rem;
   }
   .Pyara{
    font-size: 1.8rem;
  
   }
   .servicehead{
    font-size:3.8rem; }
}