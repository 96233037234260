.logomannaiImg{
  /* height: 11.3vw; */
  width: 20vw;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.logoSpaceTravelImg{
  height: 6.3vw;
  width: 30vw;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.logoSpaceCargoImg{
  height: 6.3vw;
  width: 27vw;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

/* Mobile S */
@media only screen and (max-width: 320px) {
    /* Styles for Mobile S go here */

  }
  
  /* Mobile M */
  @media only screen and (min-width: 321px) and (max-width: 375px) {
    /* Styles for Mobile M go here */
    
  }
  /* Mobile L */
  @media only screen and (min-width: 376px) and (max-width: 430px) {
    /* Styles for Mobile L go here */
  
  }
  /* Small screens */
  @media only screen and (min-width: 431px) and (max-width: 767px) {
    /* Styles for small screens go here */
 
  }
  /* Medium screens */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Styles for medium screens go here */
 
  }
  /* Large screens */
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    /* Styles for large screens go here */
  }
  /* Extra large screens */
  @media only screen and (min-width: 1200px) and (max-width: 1439px) {
    /* Styles for extra large screens go here */
  }
  /* XXL screens */
  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    /* Styles for XXL screens go here */
  
    
  }
  
  /* XXXL screens */
  @media only screen and (min-width: 1920px) and (max-width: 2999px) {
    /* Styles for XXXL screens go here */
   
  }
  /* screen more than  xxxl screens */
  @media only screen and (min-width: 3000px)  {
    
  }