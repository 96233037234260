.visithailand .wwabpluselement {
  position: absolute;
  left: 15% !important;
  top: 39% !important;
  z-index: 1;
}
.visithailand .techbannertext {
  left: 19%;
  top: 54%;
}
.visithailand .visthailandbannerfont {
  font-size: 3rem !important;
  text-align: initial;
  line-height: 3rem !important;
}
.visithailand .visthailandnormalfont {
  font-size: 2rem !important;
  text-align: initial;
  line-height: 3rem !important;
}
.visithailand .visitthaillandbanner {
  background: linear-gradient(rgba(23, 48, 108, 0.5), rgba(14, 28, 85, 0.753)),
    url("../img/sceneory.webp") top;
  background-size: cover;
}
.visithailand .bgsquarex {
  background-image: url("../img/elementsmallx.png");
  background-size: contain;
  object-fit: contain;
}
.visithailand .vtpackbtn {
  position: absolute;
  top: 88%;
  left: 15%;
}
.visithailand .wwabpluselement {
  position: absolute;
  left: 8% !important;
  top: 48% !important;
  z-index: 1;
}

.visithailand .wwabpluselement img {
  height: 210px;
  width: 210px;
}
.visithailand .bgthailandblue {
  background-color: #3891da;
}

.visithailand .social-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}
.visithailand .footersocial-rounded a:hover {
  background: #02a6fd;
  border-color: #fff;
  color: #ffffff !important;
}

.visithailand .serviceelementjwwab {
  position: absolute;
  left: 20%;
  bottom: 12%;
  z-index: 2;
  width: 50%;
  top: 78%;
}

.visithailand .linearthailandbgm {
  background: rgb(1, 112, 184);
  background: linear-gradient(
    90deg,
    rgba(1, 112, 184, 1) 0%,
    rgba(59, 92, 155, 1) 50%,
    rgba(94, 90, 147, 1) 100%
  );
}
.visithailand .abouttext {
  line-height: 30px;
  font-size: 19px;
  word-spacing: 4px;
}
.visithailand .op8 {
  background-color: #caa323;
  opacity: 0.38;
  width: 350px;
  height: 200px;
}
.visithailand .editcardiv {
  top: 35%;
  left: 20%;
}
.visithailand .editcardiv {
  letter-spacing: 2rem;
  height: fit-content;
}
.visithailand .editcardiv .loose {
  display: flex;
  letter-spacing: 0.1em;
  font-size: 15px;
  color: white;
  top: 3px;
  overflow: hidden;
}
.visithailand .editcarplus {
  top: -18%;
  left: -32%;
  height: 100px !important;
  width: 100px !important;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}
.visithailand .MesgPicthailand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.visithailand .MesgPicthailand img {
  height: 90%;
  width: 90%;
}
.visithailand .editplusnew {
  left: -1%;
  top: 10%;
}
.visithailand .editplusnewright {
  right: -1%;
  top: 10%;
}
.visithailand .h-150 {
  height: 150px;
}
/* ////////////////// */
.roundedtop {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 426px) {
  .visithailand .MesgPicthailand{
    margin-top:10px;
  }
  .visithailand .techelementsmallx {
    display: none;
  }
  .visithailand .techbannertext {
    width: 75%;
    margin-top: -36%;
    left: 15%;
  }
  /* 
  .visithailand .techbannertext {
    top: 50%;
  } */
  .visithailand .wwabpluselement img {
    height: 150px;
    width: 150px;
  }
  .visithailand .wwasmlogo {
    display: none;
  }
  .visithailand .techpulusbot {
    display: none;
  }
  .visithailand .serviceelementjwwab {
    top: 83%;
  }
  .visithailand .wwabpluselement img {
    visibility: hidden;
  }
  .visithailand .btn {
    padding: -0.25rem 2rem 0.75rem;
  }

  .visithailand .vtpackbtn {
    top: 87%;
    left: 7%;
  }
  .visithailand .padmob {
    /* padding: 20px; */
  }
  .visithailand .mobnone {
    display: none;
  }
  .visithailand .mobcenter {
    text-align: center;
    padding-bottom: 10px;
  }
  .visithailand .mobborderbottom {
    border-bottom: 1px solid white;
  }
  .visithailand .editplusnew {
    left: -3%;
    top: 9%;
  }
  .visithailand .editplusnewright {
    right: -3%;
    top: 8%;
  }

  .visithailand .blogelementsmallx {
    display: none;
  }
  .visithailand .bl1 .blogpartpluselement {
    margin-top: -18px;
    margin-left: -1rem;
  }
  .visithailand .blogimgrbbtn {
    left: 58%;
    top: 76%;
  }
  .visithailand .blogimgarelement {
    left: 57%;
  }
  .visithailand .blogimgtpluselement {
    left: 30%;
    top: 37%;
  }
  .visithailand .blogimgrbbtn.editbottom a {
    bottom: 13px;
    position: relative;
  }
  .visithailand .blogimgarelement {
    bottom: 28%;
  }
  .article-item-photo {
    margin-right: 1rem;
  }
  .visithailand .editcarplus {
    top: -28%;
  }

  .visithailand .bl1 h5 {
    position: absolute;
    left: 26.5%;
    top: 1%;
    font-size: 14px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .bl1 h6 {
    font-size: 14px;
    position: absolute;
    left: 26.5%;
    top: 19%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .share {
    font-size: 14px;
  }
  .visithailand .visithailand .op8 {
    height: 30vh;
  }
  .visithailand .visthailandbannerfont {
    font-size: 2.2rem !important;
    text-align: initial;
    line-height: 3rem !important;
  }
  .visithailand .visthailandnormalfont {
    font-size: 2.1rem !important;
  }
  .visithailand .mobcenterhead {
    /* text-align: center; */
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  .visithailand .abouttext {
    line-height: 30px;
    font-size: 15px;
    word-spacing: 4px;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .visithailand .techbannertext {
    width: 75%;
    margin-top: -43%;
    left: 15%;
  }
  .visithailand .uparrowelement {
    position: absolute;
    right: 3%;
    bottom: 22%;
    z-index: 1;
  }
  .visithailand .mobcenterhead {
    /* text-align: center; */
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  .visithailand .abouttext {
    line-height: 30px;
    font-size: 15px;
    word-spacing: 1px;
  }
  .visithailand .visthailandbannerfont {
    font-size: 2.2rem !important;
    text-align: initial;
    line-height: 3rem !important;
  }
  .visithailand .visthailandnormalfont {
    font-size: 1.39rem !important;
    text-align: initial;
    line-height: 3rem !important;
  }
  .visithailand .bl1 h5 {
    font-size: 11px;
    top: 3%;
  }
  .visithailand .bl1 h6 {
    font-size: 11px;
    top: 16%;
  }
  .visithailand .blogimgtpluselement {
    left: 21%;
    top: 37%;
  }
  .visithailand .blogimgarelement {
    bottom: 36%;
    left: 57%;
  }
  .visithailand .blogimgrbbtn {
    left: 47%;
    top: 69%;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .visithailand .techbannertext {
    width: 75%;
    margin-top: -43%;
    left: 15%;
  }
  .visithailand .visthailandbannerfont {
    font-size: 2.2rem !important;
    text-align: initial;
    line-height: 3rem !important;
  }
  .visithailand .visthailandnormalfont {
    font-size: 1.69rem !important;
    text-align: initial;
    line-height: 3rem !important;
  }
  .visithailand .uparrowelement {
    position: absolute;
    right: 3%;
    bottom: 22%;
    z-index: 1;
  }
  .visithailand .mobcenterhead {
    /* text-align: center; */
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  .visithailand .abouttext {
    line-height: 30px;
    font-size: 15px;
    word-spacing: 1px;
  }
  .visithailand .bl1 h5 {
    font-size: 13px;
    top: 3%;
  }
  .visithailand .bl1 h6 {
    font-size: 13px;
    top: 16%;
  }
  .visithailand .blogimgtpluselement {
    left: 21%;
    top: 37%;
  }
  .visithailand .blogimgarelement {
    bottom: 31%;
    left: 57%;
  }
  .visithailand .blogimgrbbtn {
    left: 53%;
    top: 73%;
  }
}
@media (min-width: 768px) {
  .visithailand .serviceelementjwwab {
    position: absolute;
    left: 20%;
    bottom: 12%;
    z-index: 2;
    width: 50%;
    top: 83%;
  }
  .visithailand .vtpackbtn {
    position: absolute;
    top: 88%;
    left: 14%;
  }
  .visithailand .wwasmlogo {
    visibility: hidden;
  }
  .visithailand .bl1 h5 {
    position: absolute;
    left: 13.5%;
    top: 1%;
    font-size: 15px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .bl1 h6 {
    font-size: 15px;
    position: absolute;
    left: 13.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
  }
  .visithailand .bl1 .blogpartpluselement {
    position: absolute;
    margin-top: -1rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .visithailand .blogimgrbbtn {
    position: absolute;
    top: 82%;
    left: 68%;
    z-index: 5;
  }
  .visithailand .share {
    font-size: 14px;
  }
  .visithailand .op8 {
    height: 25vh;
  }
  .visithailand .mobcenterhead {
    text-transform: uppercase;
  }
  .visithailand .abouttext {
    line-height: 28px;
    font-size: 15px;
    word-spacing: 2px;
  }
}

@media screen and (min-width: 1024px) {
  .visithailand .wwabpluselement {
    position: absolute;
    left: 6% !important;
    top: 47% !important;
    z-index: 1;
  }
  .visithailand .editplusnew {
    left: -1%;
    top: 12%;
  }
  .visithailand .editplusnewright {
    right: -1%;
    top: 11%;
  }
  .visithailand .bl1 h5 {
    position: absolute;
    left: 19.5%;
    top: 3%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 19.5%;
    top: 14%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .blogimgrbbtn {
    position: absolute;
    top: 73%;
    left: 65%;
    z-index: 5;
  }
  .visithailand .blogimgarelement {
    position: absolute;
    left: 70%;
    bottom: 29%;
    z-index: 2;
  }
  .visithailand .share {
    font-size: 14px;
  }
  .visithailand .visithailand .op8 {
    height: 30vh;
  }
  .visithailand .editcarplus {
    top: -18%;
    left: -22%;
  }
  .visithailand .abouttext {
    /* line-height: 25px;
    font-size: 16px;
    word-spacing: 4px; */
  }
  .visithailand .MesgPicthailand img {
    height: 119%;
    width: 90%;
  }
  .visithailand .roundedtop {
    border-top-left-radius: 121px;
    border-top-right-radius: 121px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
  .visithailand .author {
    font-size: 14px;
  }
  .visithailand .manasi {
    font-size: 14px;
  }
  .visithailand .roundedtop {
    height: 300px !important;
    width: 242px !important;
    object-fit: cover;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
    /* padding-left: 14px; */
  }
  .visithailand .phiphi1 {
    padding-left: 14px;
  }
  .visithailand .phiphi2 {
    margin-left: 14px;
  }
  .visithailand .phiphi3 {
    padding-right: 14px;
  }
  .visithailand .phiphi4 {
    padding-right: 14px;
  }
  .visithailand .bl1 .blogelementsmallx img {
    visibility: hidden !important;
  }
}
@media screen and (min-width: 1440px) {
  .visithailand .editplusnew {
    left: -1%;
    top: 11%;
  }
  .visithailand .editplusnewright {
    right: -1%;
    top: 22%;
  }
  .visithailand .bl1 h6 {
    font-size: 20px;
    position: absolute;
    left: 16.5%;
    top: 13%;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .bl1 h5 {
    position: absolute;
    left: 16.5%;
    top: 0%;
    font-size: 20px;
    letter-spacing: 0.2rem;
    font-weight: 400;
    color: #fff;
    padding: 0px;
    padding-bottom: 0rem;
  }
  .visithailand .share {
    font-size: 14px;
  }
  .visithailand .visithailand .op8 {
    height: 30vh;
  }
  .visithailand .editcarplus {
    top: -18%;
    left: -22%;
  }
  .visithailand .abouttext {
    /* line-height: 25px;
    font-size: 16px;
    word-spacing: 4px; */
  }
  /* .visithailand .MesgPicthailand img {
    height: 133%;
    width: 90%;
  } */
  .visithailand .roundedtop {
    height: 357px !important;
    width: 319px !important;
    object-fit: cover;
    border-top-left-radius: 184px;
    border-top-right-radius: 184px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 54px;
  }
}
@media (min-width: 427px) and (max-width: 821px) {
  .visithailand .wwabpluselement {
    left: 2% !important;
    top: 48% !important;
  }
  .visithailand .tabnone {
    display: none;
  }
  .visithailand .editplusnew {
    top: 9%;
  }
  .visithailand .editplusnewright {
    top: 9%;
  }
  .visithailand .padmob {
    padding: 20px;
  }
  .visithailand .visithailand .op8 {
    height: 30vh;
  }
}
