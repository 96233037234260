.mannaibluedarkbg{
    background-color: #17355d;
    height: auto;
}
.mannaiholidaytextp{
    font-size: 1.1rem;
    font-weight: normal;
    font-family: "Poppins";
    color: #fff;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: justify;
     
}
.bgheight{
    /* height: clamp(30rem, 50rem, 80rem) !important;
    position: relative; */
}
.mannaiholidaypluselement {
   
    
    }

    .mannaiholidaysmlogo {
        left: 300px;
        padding-top: 1rem;
        top: 10vmin;
        z-index: 300;
      }
      .mannaiholidayheader{
      margin-left:-60px;
      }
      .mannaiholidayheader h4{
       margin-bottom: 14px;
      line-height: 1.625;
      letter-spacing: 0.035em;
      }
    
      .customimg img{
        width:100%;
        height: 100%;
        object-fit: cover;
      }
/* / Mobile S / */
@media only screen and (max-width: 320px) {
    /* / Styles for Mobile S go here / */
    .customimg img{
        display: none;
    }
    .mannaiholidaytextp{
      font-size: 1rem;
    }
  }
  
  /* / Mobile M / */
  @media only screen and (min-width: 321px) and (max-width: 375px) {
    /* / Styles for Mobile M go here / */
    .customimg img{
        display: none;
    }
    .mannaiholidaytextp{
      font-size: 1rem;
    }
  }
  /* / Mobile L / */
  @media only screen and (min-width: 376px) and (max-width: 430px) {
    /* / Styles for Mobile L go here / */
    .customimg img{
        display: none;
    }
    .mannaiholidaytextp{
      font-size: 1rem;
    }
  }
  /* / Small screens / */
  @media only screen and (min-width: 431px) and (max-width: 767px) {
    /* / Styles for small screens go here / */
    .customimg img{
        display: none;
    }
    .mannaiholidaytextp{
      font-size: 1rem;
    }
  }
  /* / Medium screens / */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* / Styles for medium screens go here / */
    .customimg img{
        display: none;
    }
    .mannaiholidaytextp{
      font-size: 1rem;
    }
  }
  /* / Large screens / */
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    /* / Styles for large screens go here / */
  }
  /* / Extra large screens / */
  @media only screen and (min-width: 1200px) and (max-width: 1439px) {
    /* / Styles for extra large screens go here / */
    .customheight{
        height:auto;
}
.customimg img{
    width:100%;
    height:fit-content;
    
  }
  }
  /* / XXL screens / */
  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    /* / Styles for XXL screens go here / */
  
    .customheight{
        height: 800px;;
}
.customimg img{
    width:100%;
    height:fit-content;
    
  }

  }
  
  /* / XXXL screens / */
  @media only screen and (min-width: 1920px) and (max-width: 2999px) {
    /* / Styles for XXXL screens go here / */
    .customheight{
        height: 800px;;
}
.customimg img{
    width:100%;
    height:fit-content;
    
  }
  }
  /* / screen more than  xxxl screens / */
  @media only screen and (min-width: 3000px)  {
    .customheight{
        height: 800px;;
}
.customimg img{
    width:100%;
    height:fit-content;
    
  }

  }