
.cd{
 margin-top:30%;
 margin-left:100px;
  
}
.cdetextcd {
  font-size:1.2rem; 
  color:#c4c4c4; 
  line-height:1.3rem; 
  letter-spacing:0.1rem; 
  margin-left:-70px;
  width:100%;
  }
.cdetextcd h4 {
  font-size:1.2rem; 
  font-weight:300; 
  color:#C9C9C9; 
  padding:0px; 
  padding-bottom:0rem; 
  margin-left:2rem;
}
.cdetextcd h3 {
  font-size:2rem; 
  font-weight:600; 
  color:#fff; 
  margin-left:2rem; 
  padding:0px; 
  padding-bottom:0.5rem; 
  letter-spacing:0.1rem; 
  width:93%;
}
.cdetextcd p {
  font-size:2.2rem; 
  color:#fff; 
  font-weight:600;
   line-height:2.3rem;
    margin-top:20px; 
    letter-spacing:0.1rem; 
    margin-left:2rem;
  }
  .article-itemcd {
    width: 100%;
    margin-bottom: 2.5rem;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  .car1cd .blogpartpluselementcd {  
    position: absolute;
     margin-top:0rem; 
     margin-left:0rem; 
     margin-bottom:1rem;  
     z-index: 1;
    }
    .car1cd .blogelementsmallx {position:absolute; left:78%; top:2%; z-index:2; opacity:1;}
.car1cd  h5 { position: absolute;  left: 18%;  top: 8%;  font-size: 1.1rem;  letter-spacing: 0.2rem;   font-weight: 300;  color: #fff;  padding: 0px;  padding-bottom: 0rem;}
.car1cd  h6 { position: absolute;    left: 18%;  top: 16%;  font-size: 1.1rem;  letter-spacing: 0.2rem;  font-weight: 300; color: #fff;    padding: 0px;  padding-bottom: 0rem;}
.cdedestextcd {
  font-size:1.2rem; 
  color:#ffff; 
  line-height:2.5rem; 
 
   
  letter-spacing:0.1rem;
   text-align:left;
  }
.cdedestextcd h2{
  font-size:3rem; 
  color:#ffff; 
  line-height:3.5rem; 
  letter-spacing:0.5rem;
   text-align:left; 
   
  }
.cdedestextcdsmallx {  
     position:relative;
     z-index: 0;   
      opacity: 1;
      margin-right: 5px;
    }
.cdedestextcd h4{
    font-size:1.8rem; 
    color:#ffff; 
    line-height:2.5rem; 
    letter-spacing:0.1rem; 
    text-align:left; 
   
 }
.cdedestextcd h5 { 
    font-size: 1.8rem;  
    font-weight:700;   
    color:#ffff; 
    line-height:2.5rem; 
    letter-spacing:0.1rem; 
    text-align:left;
    
   }
   .capimgg {
    position: absolute; 
    z-index: 1; 
    margin-left:15px;
  }
  .socontentpartcd {
    font-size:1.3rem; 
    color:#fff; 
    line-height:2.3rem; 
    margin-left:120px;  
    margin-bottom:5rem; 
    margin-top:8rem;  
    z-index:5
  }
.socontentpartcd h3 {
  font-size:1.8rem; 
  font-weight:700; 
  color:#fff; 
   z-index:1;
  letter-spacing:0.12rem; 
  position: relative;
  margin-left:40px;
}
.sosmallxbncd {   
  position: absolute;    
   
  z-index: 0;   
   opacity: 1;
  }
  .carblunbgcd{
    
    background: linear-gradient(90deg, rgba(2,112,183,1) 0%, rgba(85,81,140,1) 100%); 
    
  }
 .cdcontentpluselementcd { 
  position: absolute;    
  left:-1.5%;
   z-index: 1;
   margin-top:-1.5%;
}
.cdcontentpluselement1cd {
   position: absolute;    
   left:-1.5%;
   z-index: 1;
   margin-top:-1.5%;
  }
.cdcontentpluselement2cd { 
  position: absolute;   
   left:-1.5%;
   z-index: 1;
   margin-top:-1.5%;
  }
  .cdeformcd {
    float:left; 
    position:relative;   
    margin-right:50px; 
    width:40% ;
  }
.cdeformcd h5{ 
  font-size: 1.2rem; 
  text-align:left;   
  font-weight: 400; 
  margin-top:20px;  
  margin-bottom:10px;
   padding-left:20px;   
   color: #fff;   
  }
.cdeformpartcd{ 
  margin:3% 0 0 13%;
}
.cdecontent1cd {
  /* font-size:1.1rem;  */
  color:#ffff; 
  line-height:1.8rem; 
  letter-spacing:0.1rem; 
  text-align:justify;
  padding:4% 5.5%;
}
.cdecontentcd {
  /* font-size:1.1rem;  */
  color:#ffff; 
  line-height:1.8rem; 
  letter-spacing:0.1rem; 
  text-align:justify;
  padding-left:7%;
  padding-right:7%;
}
.cdecontentcd h5 {
   font-size: 1.3rem;  
   font-weight:700;   
   color:#ffff; 
   line-height:2.5rem; 
   letter-spacing:0.1rem; 
   text-align:left; 
   margin-bottom:3rem;
    
  }
.cdecontent2cd h5 { 
  font-size: 1.3rem;  
  font-weight:700;   
  color:#ffff; 
  line-height:2.5rem; 
  letter-spacing:0.1rem; 
  text-align:left;
   margin-bottom:3rem; 
   }
   .buttonapplyposition{
    margin-top: 10px;
    margin-left:170px;
   }
   
   @media (max-width: 768px) {
    .cdcontentpluselementcd img{
      height:110px;
      width:110px;
    }
    .buttonapplyposition{
      margin-top: 10px;
      margin-left:100px;
    }
    .cdpluselementcd img{
      height:110px;
      width:110px;
    }
    .cdcontentpluselement1cd img{
      height:110px;
      width:110px;
    }
    .cdcontentpluselement2cd img{
      height:110px;
      width:110px;
    }
    
    .cd{
      margin-top:40%;
      margin-left:8%;
       
     }
    .cdeformcd {
      float:left; 
      position:relative;   
      margin-right:50px; 
      width:80% ;
    }
    .cdcontentpluselement1cd {
      position: absolute;    
      left:-2%;
      z-index: 1;
      margin-top:-0.5%;
     }
   .cdcontentpluselement2cd { 
    position: absolute;    
      left:-2%;
      z-index: 1;
      margin-top:-0.5%;
     }
     .cdcontentpluselementcd { 
      position: absolute;    
      left:-2%;
      z-index: 1;
      margin-top:-0.5%;
    }
   
     
   }
   @media (max-width: 480px){
    .socontentpartcd{
      margin-left: 30px;
    }
    .sosmallxbncd{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .socontentpartcd h3{
 
      margin-left: 60px;
    }
   
    .cdcontentpluselement1cd {
      position: absolute;    
      left:-9%;
      z-index: 1;
      margin-top:-2.5%;
     }
   .cdcontentpluselement2cd { 
    position: absolute;    
      left:-9%;
      z-index: 1;
      margin-top:-2.5%;
     }
     .cdcontentpluselementcd { 
      position: absolute;    
      left:-9%;
      z-index: 1;
      margin-top:-2.5%;
    }
   
    .cdedestextcd h4 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .cdedestextcd h5{
      font-size: 1.4rem;
      line-height: 2rem;
    }
    .cdedestextcd h2 {
      font-size: 2.5rem;
      letter-spacing: 0.01rem;
    }

    .cdedestextcdsmallx img{
      height:80%;
      width:80%;
    }
    .capimgg{
      margin-left: 1px;
    }
    .capimgg img{
        height:75%;
        width:75%;
    }
    .buttonapplyposition{
      margin-top: 10px;
      margin-left:60px;
    }
   
    .cd{
      margin-top:65%;
      margin-left:6%;
       
     }
   }
   @media (max-width: 375px){
    .socontentpartcd{
      margin-left: 10px;
    }
   
  
    .cdedestextcd h2 {
      font-size: 2.2rem;
      letter-spacing: 0.01rem;
    }
    .buttonapplyposition{
      margin-top: 10px;
      margin-left:65px;
    }
   
    .cd{
      margin-top:73%;
      margin-left:5%;
       
     }
   }
   @media (max-width: 320px){
    .socontentpartcd{
      margin-left: 1px;
    }
     .socontentpartcd h3{
 
      margin-left: 50px;
    }
    .cdcontentpluselement1cd {
      position: absolute;    
      left:-12%;
      z-index: 1;
      margin-top:-2.5%;
     }
   .cdcontentpluselement2cd { 
    position: absolute;    
      left:-12%;
      z-index: 1;
      margin-top:-2.5%;
     }
     .cdcontentpluselementcd { 
      position: absolute;    
      left:-12%;
      z-index: 1;
      margin-top:-2.5%;
    }
    .capimgg{
      margin-left: -3px;
    }
    .cdedestextcd h2 {
      font-size: 2.1rem;
      letter-spacing: 0.01rem;
    }
    .buttonapplyposition{
      margin-top: 10px;
      margin-left:65px;
    }
   
    .cd{
      margin-top:80%;
      margin-left:5%;
       
     }
   }